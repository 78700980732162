/**
 * Transaction Reducer
 *
 * @author EverardOnggon
 */

import {
	AUTH_LOGOUT_SUCCESS,
	AUTH_LOGOUT_FAILED,
	GET_TRANSACTIONS_LIST_SUCCESS,
    GET_TRANSACTIONS_LIST_FAILED,
    GET_MONEY_TRANSACTION_TYPES_SUCCESS,
    GET_MONEY_TRANSACTION_TYPES_FAILED,
} from "../actions/types";

const INITIAL_STATE = {
    totalBalance: 0,
    totalDeposits: 0,
    totalWithdrawals: 0,
    totalHolds: 0,
    totalPlayerBets: 0,
    totalShareRebates: 0,
    transactions: [],
    totalCount: 0,
    transactionError: "",
    isTransactionError: false,
    moneyTransactionTypes: [],
    moneyTransactionTypesError: "",
    isMoneyTransactionTypesError: false,
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case AUTH_LOGOUT_SUCCESS:
		case AUTH_LOGOUT_FAILED:
            return {
                state: undefined,
            };
		case GET_TRANSACTIONS_LIST_SUCCESS:
			return {
                ...state,
                totalBalance: action.data.totalBalance,
                totalDeposits: action.data.totalDeposits,
                totalWithdrawals: action.data.totalWithdrawals,
                totalHolds: action.data.totalHolds,
                totalPlayerBets: action.data.totalPlayerBets,
                totalShareRebates: action.data.totalShareRebates,
                transactions: action.data.transactions.content,
                totalCount: action.data.transactions.totalElements,
                transactionError: "",
                isTransactionError: false,
			};
		case GET_TRANSACTIONS_LIST_FAILED:
			return {
                ...state,
                totalBalance: 0,
                totalDeposits: 0,
                totalWithdrawals: 0,
                totalHolds: 0,
                totalPlayerBets: 0,
                totalShareRebates: 0,
                transactions: [],
                totalCount: 0,
                transactionError: action.error,
                isTransactionError: true,
            };
        case GET_MONEY_TRANSACTION_TYPES_SUCCESS:
            return {
                ...state,
                moneyTransactionTypes: action.data,
                moneyTransactionTypesError: "",
                isMoneyTransactionTypesError: false,
            }
        case GET_MONEY_TRANSACTION_TYPES_FAILED:
            return {
                ...state,
                moneyTransactionTypes: [],
                moneyTransactionTypesError: action.error,
                isMoneyTransactionTypesError: true,
            }
		default:
			return state;
	}
}