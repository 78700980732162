/**
 * Events List for Users List Component
 * 
 * @author EverardOnggon
 */

import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, Toolbar, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import HistoryIcon from '@material-ui/icons/History';

const StyledTableCell = withStyles((theme) => ({
    root: {
        border: "1px solid #000000",
    },
    head: {
        backgroundColor: "#424242",
        color: "#ffffff",
        fontSize: 14,
        fontWeight: 800,
        textAlign: "center",
        padding: 5,
    },
    body: {
        fontSize: 16,
        fontWeight: 800,
        padding: 5,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        backgroundColor: "#ffffff",
        '&:nth-of-type(odd)': {
            backgroundColor: "#f1f1f1",
        }
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    toolbar: {
        backgroundColor: "#424242",
        color: "#ffffff",
        fontSize: theme.typography.h6.fontSize,
        fontWeight: theme.typography.h6.fontWeight,
        minHeight: "auto",
        padding: 10,
    },
	blackPaperBackground: {
		backgroundColor: "#000000",
		color: "#ffffff",
        padding: "20px",
        borderRadius: 0,
    },
    table: {
        width: "100%",
    },
    tableContainer: {
        height: 200,
    }
}));

const EventsListForUsersList = ( { events, error, eventsListMessage, playerId } ) => {
    const styles = useStyles();

    return (
        <div>
            <Toolbar className={styles.toolbar}>
                <HistoryIcon />&nbsp;Events History
            </Toolbar>
            <Paper className={styles.blackPaperBackground}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {events.length === 0 ?
                        (
                            <Alert severity={error ? "error" : "warning"}>{eventsListMessage}</Alert>
                        ) :
                        (
                            <TableContainer>
                                <Table className={styles.table}>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell style={{width: "80%"}}>Event Details</StyledTableCell>
                                            <StyledTableCell style={{width: "20%"}}>Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {events.map((row, index) => (
                                            <StyledTableRow key={row.id}>
                                                <StyledTableCell>
                                                    {row.title}<br />
                                                    <Moment format="YYYY-MM-DD hh:mm A" style={{fontSize: "0.8rem", fontWeight: "normal"}}>
                                                        {row.createDate}
                                                    </Moment>
                                                </StyledTableCell>
                                                <StyledTableCell style={{textAlign: "center",}}>
                                                    <Link to={`/history/details/${row.id}/${playerId}`}>
                                                        <Button
                                                            variant="contained"
                                                            className="button btn-primary"
                                                        >
                                                            View Bet Logs
                                                        </Button>
                                                    </Link>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )
                        }
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
};

export { EventsListForUsersList };