/**
 * Broadcast Announcement Component
 *
 * @author EverardOnggon
 */

import React from "react";
import { Button, TextField, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const ValidationTextField = withStyles({
	root: {
		width: "100%",
		backgroundColor: "#FFFFFF",
		borderRadius: 5,
		"& input:focus:valid + fieldset": {
			borderColor: "green",
			borderWidth: 2,
		},
		"& input:focus:invalid + fieldset": {
			borderColor: "red",
			borderWidth: 2,
		},
		"& input:valid:focus + fieldset": {
			padding: "4px !important",
		},
	},
})(TextField);

const BroadcastAnnouncement = ({
	message,
	handleAnnouncement,
	handleChange,
}) => {
	return (
		<div>
			<form onSubmit={handleAnnouncement}>
				<Grid
					container
					spacing={2}
					className="d-flex justify-content-center align-items-center"
				>
					<Grid item xs={12} sm={12} md={9}>
						<ValidationTextField
							variant="outlined"
							id="announcement"
							label="Announcement"
							type="text"
							name="announcement"
							value={message}
							onChange={handleChange}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={3}>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							size="medium"
							className="btn-success"
							style={{
								padding: 15,
							}}
						>
							Announce
						</Button>
					</Grid>
				</Grid>
			</form>
		</div>
	);
};

export { BroadcastAnnouncement };
