/**
 * Login Component
 *
 * @author EverardOnggon
 */

import React from "react";
import {
	Button,
	// FormGroup,
	// FormControlLabel,
	// Checkbox,
	TextField,
	CircularProgress,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Link } from "react-router-dom";

const Login = ({ onLogin, handleChange, error, toggleDialog, isLoading }) => {
	return (
		<div>
			{error && (
				<Alert severity="error">{error}</Alert>
			)}
			<br></br>
			<form onSubmit={onLogin}>
				<div>
					<TextField
						required
						id="standard-username"
						label="Nombre de Usario (Username)"
						className="form-input"
						type="text"
						name="username"
						onChange={handleChange}
					/>
				</div>
				<div className="mb-15">
					<TextField
						required
						id="standard-password-input"
						label="Contraseña (Password)"
						className="form-input"
						type="password"
						name="password"
						onChange={handleChange}
					/>
				</div>
				{/* <div className="d-sm-flex justify-content-between align-items-center mb-sm-10 mb-20">
					<FormGroup>
						<FormControlLabel
							control={
								<Checkbox value="checkedA" color="primary" />
							}
							label="Remember Me"
						/>
					</FormGroup>
					<span className="d-inline-block">
						<Link to="/forget-password" className="active-color">
							Forgot Password?
						</Link>
					</span>
				</div> */}
				<Button
					type="submit"
					className="button btn-red btn-lg mb-25"
					disabled={isLoading}
				>
					{isLoading ? (<CircularProgress color="inherit" size="0.875rem" />) : "Entrar (Log in)"}
				</Button>
				{/* <p className="mb-0 text-14" style={{color:"#fff"}}>
					Don't have an account?
					<Link to="#" onClick={toggleDialog(true)} style={{color:"#fff"}}>
						{" "}
						Contact us
					</Link>
				</p> */}
			</form>
		</div>
	);
};

export { Login };
