/**
 * Register Actions
 *
 * @author EverardOnggon
 */

import axios from "axios";
import {
	API,
	API_URL,
	API_JSON_FORM_HEADER,
	REGISTER_SUCCESS,
    REGISTER_FAILED,
	REGISTER_USER_EXISTS,
	CREATE_USER_SUCCESS,
	CREATE_USER_FAILED,
	CREATE_USER_EXISTS,
	UPDATE_USER_SUCCESS,
	UPDATE_USER_FAILED,
} from "./types";
import { getFormHeaders, getHeaders } from "./AuthActions";

export const register = (payload) => async (dispatch) => {
    try {
		const formData = new FormData();
		formData.append('firstName', payload.firstName);
		formData.append('middleName', payload.middleName);
		formData.append('lastName', payload.lastName);
		formData.append('birthDate', payload.birthDate);
		formData.append('address', payload.address);
		formData.append('emailAddress', payload.emailAddress);
		formData.append('mobileNumber', payload.mobileNumber);
		formData.append('file', payload.file);
		formData.append('agentReferralCode', payload.agentReferralCode);
		formData.append('username', payload.username);
		formData.append('password', payload.password);
		
		const response = await axios.post(`${API}/registration`, formData, {
			headers: API_JSON_FORM_HEADER,
		});
		if (response.status === 200 || response.status === 201) {
			dispatch({
				type: REGISTER_SUCCESS,
			});
		}
    } catch (error) {
        switch (error.response.data.status) {
			case 400:
				dispatch({
					type: REGISTER_FAILED,
					error: error.response.data.errors ? error.response.data.errors[0].defaultMessage : error.response.data.message,
				});
				break;
			case 409:
				dispatch({
					type: REGISTER_USER_EXISTS,
					error: error.response.data.message,
				});
				break;
			default:
				dispatch({
					type: REGISTER_FAILED,
					error: error.response.data.message,
				});
		}
	}
};

export const createUser = (payload) => async (dispatch) => {
	try {
		const formData = new FormData();
		formData.append('firstName', payload.firstName);
		formData.append('middleName', payload.middleName);
		formData.append('lastName', payload.lastName);
		formData.append('birthDate', payload.birthDate);
		formData.append('address', payload.address);
		formData.append('emailAddress', payload.emailAddress);
		formData.append('mobileNumber', payload.mobileNumber);
		formData.append('file', payload.file);
		formData.append('shareRebatePercentage', payload.shareRebatePercentage);
		formData.append('roleId', payload.roleId);
		formData.append('username', payload.username);
		formData.append('password', payload.password);
		
		const response = await axios.post(`${API_URL}/user`, formData, await getFormHeaders());
		if (response.status === 200 || response.status === 201) {
			dispatch({
				type: CREATE_USER_SUCCESS,
			});
		}
	} catch (error) {
		switch (error.response.data.status) {
			case 400:
				dispatch({
					type: CREATE_USER_FAILED,
					error: error.response.data.errors ? error.response.data.errors[0].defaultMessage : error.response.data.message,
				});
				break;
			case 401:
				dispatch({
					type: CREATE_USER_FAILED,
					error: error.response.data.status.toString(),
				});
				break;
			case 409:
				dispatch({
					type: CREATE_USER_EXISTS,
					error: error.response.data.message,
				});
				break;
			default:
				dispatch({
					type: CREATE_USER_FAILED,
					error: error.response.data.message,
				});
		}
	}
};

export const updateUser = (payload, id) => async (dispatch) => {
    try {
		const response = await axios.put(`${API_URL}/user/${id}`, payload, await getHeaders());
		if (response.status === 200 || response.status === 201) {
			dispatch({
				type: UPDATE_USER_SUCCESS,
			});
		}
    } catch (error) {
		switch (error.response.status) {
			case 401:
				dispatch({
					type: UPDATE_USER_FAILED,
					error: error.response.status.toString(),
				});
				break;
			default:
				dispatch({
					type: UPDATE_USER_FAILED,
					error: error.response.data.message,
				});
		}
    }
};