/**
 * View Users Page
 *
 * @author EverardOnggon
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { UsersList, LoadingForm } from "../../components";
import { Notification } from "../../components/Common";
import { getUsersList } from "../../actions/PlayerActions";
import {
	getOtherPlayerById,
	getTransactionTypes,
	moneyTransaction,
} from "../../actions/OtherPlayerActions";
import { role } from "../../constants/User";

class ViewUsersPage extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			playersList: [],
			playersListMessage: "",
			page: 0,
			size: 10,
			totalCount: 0,
			searchNameKey: "",
			tempSearchNameKey: "",
			// Loading
			transactionTypes: [],
			openLoadingForm: false,
			userIdToLoad: null,
			loadAmount: 0,
			loadingPassword: "",
			loadingRemarks: "",
			transactionTypeId: 0,
			loadingMessage: "",
			loadingError: false,
			isTransactionLoading: false,
			// Snackbar
			openSnackbar: false,
			snackbarMessage: "",
			snackbarSeverity: "error",
		};
	}

	componentDidMount() {
		this._isMounted = true;

		this.getUsersList();

		this.props.getTransactionTypes().then(() => {
			// nothing to do here.
		});
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	getUsersList = () => {
		this.props
			.getUsersList(
				this.props.userId,
				this.state.page,
				this.state.size,
				this.state.searchNameKey
			)
			.then(() => {
				if (!this.props.isPlayerError && this.props.players) {
					this.setState({
						playersList: this.props.players,
						playersListMessage: "",
						totalCount: this.props.totalCount,
					});
				} else {
					if (this.props.playerError === "401") {
						this.setState(
							{
								openSnackbar: true,
								snackbarMessage:
									"Session expired. Logging out.",
								snackbarSeverity: "error",
								totalCount: 0,
							},
							() => {
								setTimeout(() => {
									this.props.handleLogout();
								}, 1000);
							}
						);
					} else {
						this.setState({
							playersListMessage: this.props.playerError
								? this.props.playerError
								: "Server error. Please try again.",
							totalCount: 0,
						});
					}
				}
			});
	};

	handleChangePage = (event, newPage) => {
		this.setState(
			{
				page: newPage,
			},
			() => {
				this.getUsersList();
			}
		);
	};

	handleChangeRowsPerPage = (event) => {
		this.setState(
			{
				size: event.target.value ? parseInt(event.target.value) : 10,
				page: 0,
			},
			() => {
				this.getUsersList();
			}
		);
	};

	openLoadingForm = (userId) => () => {
		this.props.getOtherPlayerById(userId).then(() => {
			if (!this.props.isOtherPlayerError) {
				this.setState({
					userIdToLoad: userId,
					openLoadingForm: true,
				});
			} else {
				if (this.props.otherPlayerError === "401") {
					this.setState(
						{
							openSnackbar: true,
							snackbarMessage: "Session expired. Logging out.",
							snackbarSeverity: "error",
						},
						() => {
							setTimeout(() => {
								this.props.handleLogout();
							}, 1000);
						}
					);
				} else {
					this.setState({
						openSnackbar: true,
						snackbarMessage: this.props.otherPlayerError
							? this.props.otherPlayerError
							: "Server error. Please try again.",
						snackbarSeverity: "error",
					});
				}
			}
		});
	};

	toggleLoadingFormDialog = (isOpen) => () => {
		this.setState({
			openLoadingForm: isOpen,
		});
	};

	handleLodingFormChange = (event) => {
		const { name, value } = event.target;
		this.setState({
			[name]: value,
		});
	};

	handleLoading = (e) => {
		e.preventDefault();

		this.setState(
			{
				isTransactionLoading: true,
			},
			() => {
				if (this.state.userIdToLoad !== null) {
					const payload = {
						amount: this.state.loadAmount.replace(/,/g, ""),
						password: this.state.loadingPassword,
						playerId: this.state.userIdToLoad,
						remarks: this.state.loadingRemarks,
						typeId: this.state.transactionTypeId,
					};

					this.props.moneyTransaction(payload).then(() => {
						if (!this.props.isMoneyTransactionError) {
							this.setState(
								{
									openSnackbar: true,
									snackbarMessage: "Transaction successful",
									snackbarSeverity: "success",
									openLoadingForm: false,
									loadingMessage: "",
									loadingError: false,
									isTransactionLoading: false,
								},
								() => {
									this.getUsersList();
								}
							);
						} else {
							if (this.props.moneyTransactionError === "401") {
								this.setState(
									{
										openSnackbar: true,
										snackbarMessage:
											"Session expired. Logging out.",
										snackbarSeverity: "error",
										isTransactionLoading: false,
									},
									() => {
										setTimeout(() => {
											this.props.handleLogout();
										}, 1000);
									}
								);
							} else {
								this.setState({
									loadingMessage: this.props
										.moneyTransactionError
										? this.props.moneyTransactionError
										: "Server error. Please try again.",
									loadingError: true,
									isTransactionLoading: false,
								});
							}
						}
					});
				} else {
					this.setState({
						openSnackbar: true,
						snackbarMessage:
							"Something went wrong. Please try again.",
						isTransactionLoading: false,
					});
				}
			}
		);
	};

	handleSearchChange = (event) => {
		this.setState({
			tempSearchNameKey: event.target.value,
		});
	};

	handleSearch = () => {
		this.setState(
			{
				searchNameKey: this.state.tempSearchNameKey,
				page: 0,
			},
			() => {
				this.getUsersList();
			}
		);
	};

	handleCloseSnackbar = () => {
		this.setState({
			openSnackbar: false,
		});
	};

	render() {
		return (
			<div className="container">
				<Notification
					open={this.state.openSnackbar}
					onClose={this.handleCloseSnackbar}
					message={this.state.snackbarMessage}
					severity={this.state.snackbarSeverity}
				/>
				<Dialog
					fullWidth={true}
					maxWidth="sm"
					open={this.state.openLoadingForm}
					aria-labelledby="loading-form-dialog-title"
				>
					<DialogTitle id="loading-form-dialog-title">
						Loading Station
					</DialogTitle>
					<DialogContent>
						<LoadingForm
							transactionTypes={
								this.props.transactionTypes
									? this.props.transactionTypes
									: []
							}
							name={
								this.props.otherUserProfile
									? `${this.props.otherUserProfile.firstName} ${this.props.otherUserProfile.middleName} ${this.props.otherUserProfile.lastName}`
									: ""
							}
							message={this.state.loadingMessage}
							error={this.state.loadingError}
							handleLoading={this.handleLoading}
							handleChange={this.handleLodingFormChange}
							onClose={this.toggleLoadingFormDialog}
							isLoading={this.state.isTransactionLoading}
						/>
					</DialogContent>
				</Dialog>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<UsersList
							players={this.state.playersList}
							totalCount={this.state.totalCount}
							page={this.state.page}
							size={this.state.size}
							handleChangePage={this.handleChangePage}
							handleChangeRowsPerPage={
								this.handleChangeRowsPerPage
							}
							openLoadingForm={this.openLoadingForm}
							handleSearchChange={this.handleSearchChange}
                            handleSearch={this.handleSearch}
                            showLoad={this.props.roleId === role.ADMIN ? true : false }
						/>
					</Grid>
				</Grid>
			</div>
		);
	}
}

const mapStatetoProps = ({ user, player, otherPlayer }) => {
	const { userId, roleId, code, shareRebatePercentage } = user;
	const { players, totalCount, playerError, isPlayerError } = player;
	const {
		otherUserProfile,
		otherPlayerError,
		isOtherPlayerError,
		transactionTypes,
		transactionTypesError,
		moneyTransactionError,
		isMoneyTransactionError,
	} = otherPlayer;

	return {
		userId,
		roleId,
		code,
		shareRebatePercentage,
		players,
		totalCount,
		playerError,
		isPlayerError,
		otherUserProfile,
		otherPlayerError,
		isOtherPlayerError,
		transactionTypes,
		transactionTypesError,
		moneyTransactionError,
		isMoneyTransactionError,
	};
};

export default connect(mapStatetoProps, {
	getUsersList,
	getOtherPlayerById,
	getTransactionTypes,
	moneyTransaction,
})(ViewUsersPage);
