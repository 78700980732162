/**
 * System Config Reducer
 *
 * @author EverardOnggon
 */

import {
    AUTH_LOGOUT_SUCCESS,
    AUTH_LOGOUT_FAILED,
	GET_SYSTEM_CONFIG_SUCCESS,
    GET_SYSTEM_CONFIG_FAILED,
    UPDATE_SYSTEM_CONFIG_SUCCESS,
    UPDATE_SYSTEM_CONFIG_FAILED,
} from "../actions/types";

const INITIAL_STATE = {
    systemConfigs: [],
    systemConfigError: "",
    isSystemConfigError: false,
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case AUTH_LOGOUT_SUCCESS:
        case AUTH_LOGOUT_FAILED:
            return {
                state: undefined,
            };
		case GET_SYSTEM_CONFIG_SUCCESS:
			return {
                ...state,
                systemConfigs: action.data,
                systemConfigError: "",
                isSystemConfigError: false,
			};
		case GET_SYSTEM_CONFIG_FAILED:
			return {
				...state,
                systemConfigError: action.error,
                isSystemConfigError: true,
                systemConfigs: [],
            };
        case UPDATE_SYSTEM_CONFIG_SUCCESS:
            return {
                ...state,
                systemConfigError: "",
                isSystemConfigError: false,
            }
        case UPDATE_SYSTEM_CONFIG_FAILED:
            return {
                ...state,
                systemConfigError: action.error,
                isSystemConfigError: true,
            }
		default:
			return state;
	}
}