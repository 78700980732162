/**
 * Event Actions
 *
 * @author EverardOnggon
 */

import axios from "axios";
import {
	API_URL,
	GET_EVENT_DETAILS_BY_IDS_SUCCESS,
	GET_EVENT_DETAILS_BY_IDS_FAILED,
} from "./types";
import { getHeaders } from "./AuthActions";

export const getEventDetailsByEventIdAndPlayerId = (eventId, playerId, page, size) => async (dispatch) => {
	try {
		const response = await axios.get(`${API_URL}/txn/bet/summary/${eventId}/${playerId}?page=${page}&size=${size}`, await getHeaders());
		if (response.status === 200 || response.status === 201) {
			dispatch({
				type: GET_EVENT_DETAILS_BY_IDS_SUCCESS,
				data: response.data,
			});
		}
	} catch (error) {
		switch (error.response.status) {
			case 401:
				dispatch({
					type: GET_EVENT_DETAILS_BY_IDS_FAILED,
					error: error.response.status.toString(),
				});
				break;
			default:
				dispatch({
					type: GET_EVENT_DETAILS_BY_IDS_FAILED,
					error: error.response.data.message,
				});
		}
	}
};
