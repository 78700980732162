/**
 * App Constants
 */
const currentYear = new Date().getFullYear()
export default {
	AppLogo: require("../assets/images/SabongLegends.png"),
	CopyrightText: "",
	locale: {
		locale: "en",
		name: "English",
		icon: "en",
	},
};
