/**
 * Auto Equalize Component
 *
 * @author EverardOnggon
 */

import React from "react";
import { Switch, Grid, Paper, FormControlLabel, FormGroup, InputBase, Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    paperBackground: {
		// backgroundColor: "#000000",
		// color: "#ffffff",
        padding: "20px",
        borderRadius: 0,
    },
}));

const AutoEqualize = ({autoEqualizeEnabled, handleChange, autoEqualizeAmount, handleAutoEqualizeAmountChange,
	handleAutoEqualizeAmountConfirmation, jackUpTreshold, handleJackUpThresholdChange, handleJackUpThresholdConfirmation}) => {
    const styles = useStyles();

	return (
		<div>
            <Paper className={styles.paperBackground}>
				<Grid
					container
					spacing={2}
					className="d-flex justify-content-center align-items-center"
				>
					<Grid item xs={12}>
						<h4>Auto Equalize</h4>
                        <p>When enabled, the system will automatically balance the bets of meron and wala.</p>
					</Grid>
					<Grid item xs={12}>
                        <FormGroup>
							<Grid item xs={12}>
								<Grid container spacing={0}>
									<Grid item xs={3}>
										<FormControlLabel
											control={
												<Switch checked={autoEqualizeEnabled} onChange={handleChange} name="autoEqualizeEnable" />
											}
											label="Auto Equalize"
										/>
									</Grid>
									{ autoEqualizeEnabled ? 
									<Grid container spacing={0}>
										<Grid item xs={3}>
											<TextField
												id="standard-number"
												label="Auto Equalize Amount"
												type="number"
												value={autoEqualizeAmount}
												InputLabelProps={{
													shrink: true,
												}}
												onChange={handleAutoEqualizeAmountChange}
											/>
											<Button
											variant="contained"
											className={`button btn-primary ${styles.sideButton}`}
											classes={{ disabled: styles.disabledButton }}
											onClick={handleAutoEqualizeAmountConfirmation}
											>
												OK
											</Button>
										</Grid>
										<Grid item xs={3}>
											<TextField
												id="standard-number"
												label="Jack Up Threshold"
												type="number"
												value={jackUpTreshold}
												InputLabelProps={{
													shrink: true,
												}}
												onChange={handleJackUpThresholdChange}
											/>
											<Button
											variant="contained"
											className={`button btn-primary ${styles.sideButton}`}
											classes={{ disabled: styles.disabledButton }}
											onClick={handleJackUpThresholdConfirmation}
											>
												OK
											</Button>
										</Grid>
									</Grid>
									 : "" }
								</Grid>
							</Grid>
                        </FormGroup>
					</Grid>
				</Grid>
            </Paper>
		</div>
	);
};

export { AutoEqualize };
