/**
 * Cancelled Pop Up Dialog Component
 * 
 * @author EverardOnggon
 */

import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { 
    Dialog,
    DialogContent,
    DialogContentText,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    content: {
        textAlign: "center",
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
        [theme.breakpoints.down("sm")]: {
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(5),
        },
        [theme.breakpoints.down("xs")]: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
        }
    },
    text: {
        fontSize: "2.875rem",
        fontWeight: 800,
        [theme.breakpoints.down("xs")]: {
            fontSize: "1.875rem",
            fontWeight: 400,
		},
    }
}));

const CancelledPopUp = ({isOpen}) => {
    const styles = useStyles();

    return (
        <Dialog
            open={isOpen}
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogContent className="bg-warning">
				<DialogContentText className={styles.content}>
					<span className={`base-color ${styles.text}`}>CANCELLED</span>
				</DialogContentText>
			</DialogContent>
        </Dialog>
    )
}

export { CancelledPopUp };