/**
 * Register Component
 *
 * @author EverardOnggon
 */

import React from "react";
import { Button, TextField, Select, MenuItem, FormControl, InputLabel, CircularProgress, Grid, } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { role } from "../../constants/User";

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(0.25),
      },
    },
}));

const ValidationTextField = withStyles({
    root: {
        width: "100%",
        marginBottom: "0.75rem !important",
        '& input:focus:valid + fieldset': {
            borderColor: 'green',
            borderWidth: 2,
        },
        '& input:focus:invalid + fieldset': {
            borderColor: 'red',
            borderWidth: 2,
        },
        '& input:valid:focus + fieldset': {
            padding: '4px !important',
        },
    },
})(TextField);

const Register = ({uploadId, handleRegister, handleChange, handleFileUpload, message, error, isFromLink, onClose, roleId, isLoading, roleIdToCreate}) => {
    const classes = useStyles();

    let roleSelectionStr = '{"roles": []}';
    let roleObj = JSON.parse(roleSelectionStr);

    if (roleId === role.SUB_AGENT) {
        roleObj['roles'].push({"id" : `${role.PLAYER}`, "text" : "Player"});
    } else if (roleId === role.AGENT) {
        roleObj['roles'].push({"id" : `${role.PLAYER}`, "text" : "Player"});
        roleObj['roles'].push({"id" : `${role.SUB_AGENT}`, "text" : "Sub Agent"});
    } else if (roleId === role.SUPER_SUB_AGENT) {
        roleObj['roles'].push({"id" : `${role.PLAYER}`, "text" : "Player"});
        roleObj['roles'].push({"id" : `${role.AGENT}`, "text" : "Agent"});
    } else if (roleId === role.SUPER_AGENT) {
        roleObj['roles'].push({"id" : `${role.SUPER_SUB_AGENT}`, "text" : "Master-Sub Agent"});
    } else if (roleId === role.ADMIN) {
        roleObj['roles'].push({"id" : `${role.SUPER_AGENT}`, "text" : "Master Agent"});
        roleObj['roles'].push({"id" : `${role.OPERATOR}`, "text" : "Operator"});
        roleObj['roles'].push({"id" : `${role.ADMIN}`, "text" : "Admin"});
        roleObj['roles'].push({"id" : `${role.TECHNICAL_SUPPORT}`, "text" : "Technical Support"});
        roleObj['roles'].push({"id" : `${role.EQUALIZER}`, "text" : "Equalizer"});
    }

    return (
        <div>
            {isFromLink ? (<h4>User Registration / Registro de Usuario</h4>) : null }
            {error && <Alert severity="error">{message}</Alert>}
            {!error && message && <Alert severity="success">{message}</Alert>}
            <br></br>
            <form onSubmit={handleRegister}>
                <Grid container spacing={isFromLink ? 0 : 2}>
                    <Grid item xs={12} sm={12} md={isFromLink ? 12 : 6}>
                        <div>
                            <ValidationTextField
                                variant="outlined"
                                required
                                id="firstName"
                                label="First Name / Nombre"
                                className="iron-form-input-wrap"
                                type="text"
                                name="firstName"
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <ValidationTextField
                                variant="outlined"
                                required
                                id="lastName"
                                label="Last Name / Apellido"
                                className="iron-form-input-wrap"
                                type="text"
                                name="lastName"
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <ValidationTextField
                                variant="outlined"
                                required
                                id="birthDate"
                                label="Date of Birth / Fecha de nacimiento"
                                className="iron-form-input-wrap"
                                type="date"
                                name="birthDate"
                                onChange={handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                        <div>
                            <ValidationTextField
                                variant="outlined"
                                required
                                id="address"
                                label="Home Address / Direccion de casa"
                                className="iron-form-input-wrap"
                                type="text"
                                name="address"
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <ValidationTextField
                                variant="outlined"
                                id="emailAddress"
                                label="Email Address / Correo electrónico"
                                className="iron-form-input-wrap"
                                type="email"
                                name="emailAddress"
                                onChange={handleChange}
                            />
                        </div>              
                        <div>
                            <small>Notice: The mobile number should include the country code for the US which is 1 (e.g. 1815XXXX123) or any 11 digit will do.</small>
                            <br />
                            <small>Aviso: El número de móvil debe incluir el código de país de Estados Unidos, que es 1 (por ejemplo, 1815XXXX123) o cualquier número de 11 dígitos será suficiente.</small>
                            <br /><br />
                            <ValidationTextField
                                variant="outlined"
                                id="mobileNumber"
                                label="Mobile Number / Teléfono móvil"
                                className="iron-form-input-wrap"
                                type="text"
                                name="mobileNumber"
                                onChange={handleChange}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={isFromLink ? 12 : 6}>
                        {!isFromLink && (parseInt(roleIdToCreate) !== role.ADMIN || parseInt(roleIdToCreate) !== role.OPERATOR) ? (
                        <div>
                            <ValidationTextField
                                variant="outlined"
                                id="shareRebatePercentage"
                                label="Share Rebate / Reembolso de acciones %"
                                className="iron-form-input-wrap"
                                type="text"
                                name="shareRebatePercentage"
                                defaultValue="0"
                                onChange={handleChange}
                            />
                        </div>
                        ) : null }
                        {isFromLink ? (
                            null
                        // <div>
                        //     <ValidationTextField
                        //         variant="outlined"
                        //         id="agentCode"
                        //         label="Agent's Code (if applicable)"
                        //         className="iron-form-input-wrap"
                        //         type="text"
                        //         name="agentCode"
                        //         onChange={handleChange}
                        //     />
                        // </div>
                        ) : (
                        <div>
                            <FormControl style={{width: "100%", marginBottom: "0.75rem",}}>
                                <InputLabel id="label-role-id" style={{marginLeft: 14,}}>Role*</InputLabel>
                                <Select
                                    variant="outlined"
                                    labelId="label-role-id"
                                    id="roleId"
                                    name="roleId"
                                    required
                                    defaultValue={roleObj['roles'][0].id}
                                    onChange={handleChange}
                                >
                                    {roleObj['roles'].map((role, index) =>
                                        <MenuItem value={role.id} key={index}>{role.text}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                        )}
                        <div>
                            <ValidationTextField
                                variant="outlined"
                                required
                                id="username"
                                label="Username / Nombre de usuario"
                                className="iron-form-input-wrap"
                                type="text"
                                name="username"
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <ValidationTextField
                                variant="outlined"
                                required
                                id="password"
                                label="Password / Contraseña"
                                className="iron-form-input-wrap"
                                type="password"
                                name="password"
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <ValidationTextField
                                variant="outlined"
                                required
                                id="confirmPassword"
                                label="Confirm Password / Confirmar Contraseña"
                                className="iron-form-input-wrap"
                                type="password"
                                name="confirmPassword"
                                onChange={handleChange}
                            />
                        </div>
                    </Grid>
                </Grid>
                <div className={classes.root}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="medium"
                        disabled={isLoading}
                        className="button btn-primary"
                        > 
                        {isLoading ? (<CircularProgress color="inherit" size="1.5rem" />) : "Submit"}
                    </Button>  
                    {isFromLink ? (
                    <Button
                        variant="contained"
                        color="secondary"
                        size="medium"
                        href="/"
                        className="button btn-base"
                        > 
                        Cancel
                    </Button>
                    ) : (
                        <Button
                            variant="contained"
                            color="secondary"
                            size="medium"
                            onClick={onClose(false)}
                            className="button btn-base"
                            > 
                            Cancel
                        </Button>
                    )}  
                </div>            
            </form>
        </div>
    );
};

export { Register };