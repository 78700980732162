/**
 * Reports Reducer
 *
 * @author EverardOnggon
 */

import {
    AUTH_LOGOUT_SUCCESS,
    AUTH_LOGOUT_FAILED,
	GET_REBATES_REPORT_SUCCESS,
    GET_REBATES_REPORT_FAILED,
    GET_SYSTEM_PROFIT_REPORT_SUCCESS,
    GET_SYSTEM_PROFIT_REPORT_FAILED,
    GET_BETS_REPORT_SUCCESS,
    GET_BETS_REPORT_FAILED,
} from "../actions/types";

const INITIAL_STATE = {
    // rebate
    rebatesReport: [],
    rebateReportsError: "",
    isRebateReportsError: false,
    // system profit
    systemProfitReport: [],
    systemProfitReportsError: "",
    isSystemProfitReportsError: false,
    // bets
    betsReport: [],
    betsReportsError: "",
    isBetsReportsError: false,
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case AUTH_LOGOUT_SUCCESS:
        case AUTH_LOGOUT_FAILED:
            return {
                state: undefined,
            };
		case GET_REBATES_REPORT_SUCCESS:
			return {
                ...state,
                rebatesReport: action.data.dataList,
                rebateReportsError: "",
                isRebateReportsError: false,
			};
		case GET_REBATES_REPORT_FAILED:
			return {
				...state,
                rebateReportsError: action.error,
                isReportsError: true,
                isRebateReportsError: [],
            };
        case GET_SYSTEM_PROFIT_REPORT_SUCCESS:
            return {
                ...state,
                systemProfitReport: action.data.dataList,
                systemProfitReportsError: "",
                isSystemProfitReportsError: false,
            };
        case GET_SYSTEM_PROFIT_REPORT_FAILED:
            return {
                ...state,
                systemProfitReportsError: action.error,
                isSystemProfitReportsError: true,
                systemProfitReport: [],
            };
        case GET_BETS_REPORT_SUCCESS:
            return {
                ...state,
                betsReport: action.data.dataList,
                betsReportsError: "",
                isBetsReportsError: false,
            };
        case GET_BETS_REPORT_FAILED:
            return {
                ...state,
                betsReportsError: action.error,
                isBetsReportsError: true,
                betsReport: [],
            };
		default:
			return state;
	}
}