/**
 * System Profit Reducer
 *
 * @author EverardOnggon
 */

 import {
    AUTH_LOGOUT_SUCCESS,
    AUTH_LOGOUT_FAILED,
	GET_SYSTEM_PROFIT_SUCCESS,
    GET_SYSTEM_PROFIT_FAILED,
} from "../actions/types";

const INITIAL_STATE = {
    systemTotalProfit: 0,
    systemTotalFundsForOddsExcemptedBets: 0,
    systemProfitFights: {},
    systemProfitOddsAmountToCancel: 0,
    systemProfitError: "",
    isSystemProfitError: false,
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case AUTH_LOGOUT_SUCCESS:
        case AUTH_LOGOUT_FAILED:
            return {
                state: undefined,
            };
		case GET_SYSTEM_PROFIT_SUCCESS:
			return {
                ...state,
                systemTotalProfit: action.data.totalProfit,
                systemTotalFundsForOddsExcemptedBets: action.data.totalFundsForOddsExcemptedBets,
                systemProfitFights: action.data.fights,
                systemProfitOddsAmountToCancel: action.data.oddsAmountToCancel,
                systemProfitError: "",
                isSystemProfitError: false,
			};
		case GET_SYSTEM_PROFIT_FAILED:
			return {
				...state,
                systemProfitError: action.error,
                isSystemProfitError: true,
                systemTotalProfit: 0,
                systemTotalFundsForOddsExcemptedBets: 0,
                systemProfitFights: {},
                systemProfitOddsAmountToCancel: 0,
            };
		default:
			return state;
	}
}