/**
 * Agent Details Component
 *
 * @author EverardOnggon
 */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, Typography, Toolbar } from "@material-ui/core";
import PersonIcon from '@material-ui/icons/Person';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import EmailIcon from '@material-ui/icons/Email';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const useStyles = makeStyles((theme) => ({
    toolbar: {
		backgroundColor: "#424242",
		color: "#ffffff",
		fontSize: "1rem",
		fontWeight: theme.typography.h6.fontWeight,
		minHeight: "auto",
		padding: 10,
	},
	blackPaperBackground: {
		backgroundColor: "#000000",
		color: "#ffffff",
        padding: 20,
        borderRadius: 0,
		[theme.breakpoints.down("xs")]: {
			padding: "20px 5px",
		},
	},
}));

const AgentDetails = ({name, mobileNumber, emailAddress}) => {
	const styles = useStyles();

	return ('');
};

export { AgentDetails };
