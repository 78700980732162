/**
 * User Actions
 *
 * @author EverardOnggon
 */

import axios from "axios";
import {
	API_URL,
	GET_USER_DETAILS_SUCCESS,
	GET_USER_DETAILS_FAILED,
	CHANGE_PASSWORD_SUCCESS,
	CHANGE_PASSWORD_FAILED,
	TOKEN_VALID,
	TOKEN_INVALID,
} from "./types";
import { getHeaders } from "./AuthActions";

export const getUserDetails = () => async (dispatch) => {
	try {
		const response = await axios.get(
			`${API_URL}/user/login/detail`,
			await getHeaders()
		);
		if (response.status === 200 || response.status === 201) {
			dispatch({
				type: GET_USER_DETAILS_SUCCESS,
				data: response.data,
			});
		}
	} catch (error) {
		switch (error.response.status) {
			case 401:
				dispatch({
					type: GET_USER_DETAILS_FAILED,
					error: error.response.data.error,
				});
				break;
			default:
				dispatch({
					type: GET_USER_DETAILS_FAILED,
					error: error.response.data.message,
				});
		}
	}
};

export const changePassword = (payload, id) => async (dispatch) => {
	try {
		const response = await axios.post(
			`${API_URL}/user/change/password/${id}`,
			payload,
			await getHeaders()
		);
		if (response.status === 200 || response.status === 201) {
			dispatch({
				type: CHANGE_PASSWORD_SUCCESS,
			});
		}
	} catch (error) {
		dispatch({
			type: CHANGE_PASSWORD_FAILED,
			error: error.response.data.error,
		});
	}
};

export const checkToken = () => async (dispatch) => {
	try {
		const response = await axios.get(`${API_URL}/user/token/check/validity`, await getHeaders());
		if (response.status === 200 || response.status === 201) {
			dispatch({
				type: TOKEN_VALID,
			});
		}
	} catch (error) {
		switch (error.response.status) {
			case 401:
				dispatch({
					type: TOKEN_INVALID,
					error: error.response.status.toString(),
				});
				break;
			default:
				dispatch({
					type: TOKEN_INVALID,
					error: error.response.data.message,
				});
		}
	}
};
