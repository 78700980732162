/**
 * Game Constants
 */

export const game = {
	// BETTING STATUS
	STANDBY: 1,
	OPEN: 2,
	CLOSED: 3,
	LAST_CALL: 4,
	FINALIZED: 5,
	// FIGHT RESULT
	MERON: 1,
	WALA: 2,
	DRAW: 3,
	CANCELLED: 4,
	FIGHT_RESULT: ["", "MERON", "WALA", "DRAW", "CANCELLED"],
	RESULT_WIN: 1,
	RESULT_LOSS: 2,
	RESULT_DRAW: 3,
	RESULT_CANCEL: 4,
	// VIDEO STATUS
	UP: 2,
	DOWN: 3,
};

export const gameId = {
	COCK_FIGHT: 1,
};

export const videoSource = [
	{
		// src: "https://d1jb73ah6d9t8g.cloudfront.net/video/stream.m3u8",
		src: "https://alpharlive.s.llnwi.net/cs/hls/live.m3u8",
		type: "application/x-mpegURL",
	},
];
