/**
 * My Players List Component
 *
 * @author EverardOnggon
 */

import React from "react";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import {
	Paper,
	Grid,
	Toolbar,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableFooter,
	TablePagination,
	Button,
	TextField,
	IconButton,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { role } from "../../constants/User";

const StyledTableCell = withStyles((theme) => ({
	root: {
		border: "1px solid #000000",
	},
	head: {
		backgroundColor: "#424242",
		color: "#fff",
		fontSize: 14,
		fontWeight: 800,
		textAlign: "center",
		padding: 5,
	},
	body: {
		fontSize: 16,
		fontWeight: 800,
		textAlign: "center",
		padding: 5,
		color: "#fff",
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		backgroundColor: "#424242",
	},
}))(TableRow);

const StyledTablePagination = withStyles((theme) => ({
	root: {
		color: "#ffffff",
	},
	selectIcon: {
		color: "#ffffff",
	},
	actions: {
		color: "#ffffff",
	},
}))(TablePagination);

const SearchTextField = withStyles({
	root: {
		width: 200,
		backgroundColor: "#ffffff",
		"& input:focus:valid + fieldset": {
			borderColor: "#424242",
			borderWidth: 1,
		},
		"& fieldset": {
			borderRadius: 0,
		},
	},
})(TextField);

const StyledButton = withStyles((theme) => ({
	disabled: {
		opacity: "0.5",
	},
}))(Button);

const useStyles = makeStyles((theme) => ({
	toolbar: {
		backgroundColor: "#424242",
		color: "#ffffff",
		fontSize: theme.typography.h6.fontSize,
		fontWeight: theme.typography.h6.fontWeight,
		minHeight: "auto",
		padding: 10,
	},
	blackPaperBackground: {
		backgroundColor: "#000000",
		color: "#ffffff",
		padding: "20px",
		borderRadius: 0,
	},
	table: {
		width: "100%",
	},
	tableContainer: {
		height: 200,
	},
}));

const usePaginationStyles = makeStyles((theme) => ({
	root: {
		flexShrink: 0,
		marginLeft: theme.spacing(2.5),
	},
	iconButton: {
		color: "#FFFFFF",
	},
	disabledButton: {
		opacity: "0.6",
	},
}));

function TablePaginationActions(props) {
	const classes = usePaginationStyles();
	const theme = useTheme();
	const { count, page, rowsPerPage, onChangePage } = props;

	const handleFirstPageButtonClick = (event) => {
		onChangePage(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onChangePage(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onChangePage(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<div className={classes.root}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				classes={{
					label: classes.iconButton,
					disabled: classes.disabledButton,
				}}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? (
					<LastPageIcon />
				) : (
					<FirstPageIcon />
				)}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				classes={{
					label: classes.iconButton,
					disabled: classes.disabledButton,
				}}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowRight />
				) : (
					<KeyboardArrowLeft />
				)}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				classes={{
					label: classes.iconButton,
					disabled: classes.disabledButton,
				}}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowLeft />
				) : (
					<KeyboardArrowRight />
				)}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				classes={{
					label: classes.iconButton,
					disabled: classes.disabledButton,
				}}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? (
					<FirstPageIcon />
				) : (
					<LastPageIcon />
				)}
			</IconButton>
		</div>
	);
}

const MyPlayersList = ({
	players,
	totalCount,
	page,
	size,
	handleChangePage,
	handleChangeRowsPerPage,
	openCreateUser,
	openLoadingForm,
	handleSearchChange,
	handleSearch,
}) => {
	const styles = useStyles();

	return (
		<div>
			<Toolbar className={styles.toolbar}>
				<FormatListBulletedIcon />
				&nbsp;My Players List
				<Button
					variant="contained"
					className="button btn-base"
					onClick={openCreateUser(true)}
					style={{
						position: "absolute",
						right: 10,
					}}
				>
					Create Account
				</Button>
			</Toolbar>
			<Paper className={styles.blackPaperBackground}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Grid container spacing={0}>
							<Grid item xs={2} sm={4} md={6}>
								&nbsp;
							</Grid>
							<Grid
								item
								xs={10}
								sm={8}
								md={6}
								style={{ textAlign: "right" }}
							>
								<SearchTextField
									variant="outlined"
									id="search"
									placeholder="Enter name"
									type="text"
									name="search"
									onChange={handleSearchChange}
								/>
								<Button
									variant="contained"
									className="button btn-primary"
									style={{
										height: "100%",
										borderRadius: 0,
									}}
									onClick={handleSearch}
								>
									Search
								</Button>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						{players.length === 0 ? (
							<Alert severity="warning">No players found</Alert>
						) : (
							<TableContainer>
								<Table className={styles.table}>
									<TableHead>
										<TableRow>
											<StyledTableCell
												style={{ width: "2%" }}
											>
												#
											</StyledTableCell>
											<StyledTableCell
												style={{ width: "25%" }}
											>
												Fullname
											</StyledTableCell>
											<StyledTableCell
												style={{ width: "10%" }}
											>
												Username
											</StyledTableCell>
											<StyledTableCell
												style={{ width: "10%" }}
											>
												Phone
											</StyledTableCell>
											<StyledTableCell
												style={{ width: "11%" }}
											>
												Balance
											</StyledTableCell>
											<StyledTableCell
												style={{ width: "11%" }}
											>
												Total Bet
											</StyledTableCell>
											<StyledTableCell
												style={{ width: "8%" }}
											>
												Share Rebate
											</StyledTableCell>
											<StyledTableCell
												style={{ width: "8%" }}
											>
												Status
											</StyledTableCell>
											<StyledTableCell
												style={{ width: "15%" }}
											>
												Action
											</StyledTableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{players.map((row, index) => (
											<StyledTableRow key={row.id}>
												<StyledTableCell>
													{page * size + index + 1}
												</StyledTableCell>
												<StyledTableCell>
													{row.userProfile.firstName}{" "}
													{row.userProfile.middleName}{" "}
													{row.userProfile.lastName}
													<br />
													<span
														style={{
															color: "#424242",
															fontWeight:
																"normal",
														}}
													>
														(
														{
															role.ROLE[
																row.userProfile
																	.roles
																	? row
																			.userProfile
																			.roles[0]
																			.id
																	: 0
															]
														}
														)
													</span>
												</StyledTableCell>
												<StyledTableCell>
													{row.userProfile.username}
												</StyledTableCell>
												<StyledTableCell>
													{
														row.userProfile
															.mobileNumber
													}
												</StyledTableCell>
												<StyledTableCell>
													<NumberFormat
														value={
															row.balanceSummary
																.totalBalance
														}
														displayType={"text"}
														thousandSeparator={true}
													/>
												</StyledTableCell>
												<StyledTableCell>
													<NumberFormat
														value={
															row.balanceSummary
																.totalBets
														}
														displayType={"text"}
														thousandSeparator={true}
													/>
												</StyledTableCell>
												<StyledTableCell>
													{
														row.userProfile
															.shareRebatePercentage
													}
													%
												</StyledTableCell>
												<StyledTableCell>
													{row.userProfile.enabled ? (
														<span className="success-color">
															Active
														</span>
													) : (
														<span className="danger-color">
															Inactive
														</span>
													)}
												</StyledTableCell>
												<StyledTableCell>
													<StyledButton
														variant="contained"
														className="button btn-blue"
														onClick={openLoadingForm(
															row.id
														)}
														disabled={
															row.userProfile
																.roles[0].id ===
																role.ADMIN ||
															row.userProfile
																.roles[0].id ===
																role.OPERATOR
																? true
																: false
														}
													>
														Load
													</StyledButton>
													&nbsp;
													<Link
														to={`/my-players/details/${row.id}`}
													>
														<Button
															variant="contained"
															className="button btn-success"
														>
															View/Edit
														</Button>
													</Link>
												</StyledTableCell>
											</StyledTableRow>
										))}
									</TableBody>
									<TableFooter>
										<TableRow>
											<StyledTablePagination
												rowsPerPageOptions={[5, 10, 25]}
												colSpan={10}
												count={totalCount}
												rowsPerPage={size}
												page={page}
												onChangePage={handleChangePage}
												onChangeRowsPerPage={
													handleChangeRowsPerPage
												}
												ActionsComponent={
													TablePaginationActions
												}
											/>
										</TableRow>
									</TableFooter>
								</Table>
							</TableContainer>
						)}
					</Grid>
				</Grid>
			</Paper>
		</div>
	);
};

export { MyPlayersList };
