/**
 * Video Streaming Component
 *
 * @author EverardOnggon
 */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import VideoPlayer from "./VideoPlayer";
import {
	Grid
} from "@material-ui/core";
import VideoImg from "../../../assets/images/sabungan_logo.png";

const useStyles = makeStyles((theme) => ({
	video: {
		backgroundColor: "#000000",
		width: "100%",
		height: "auto",
	},
}));

const VideoStream = (props) => {
	const styles = useStyles();

	return (
		<div>
			{	
				props.showFightVideo ? 
				<div className={styles.video} style={{display: props.showFightVideo ? "" : "none"}}>
					<VideoPlayer id={props.id} source={props.source} />
				</div>
				 : 
				<Grid item
					container
					direction="column"
					alignItems="center"
					justify="center">
					<img src={VideoImg} alt="VideoImg" />
				</Grid> 
			}
			
		</div>
	);
};

export { VideoStream };
