/**
 * Result Confirmation Dialog Component
 * 
 * @author EverardOnggon
 */

import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { 
    Dialog,
    DialogContent,
    DialogActions,
    IconButton,
    Typography,
    Button,
} from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { game } from "../../../constants/Game";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
		background: "#232323"
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6" style={{color: "#ffffff"}}>{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose(false)}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const ResultConfirmationDialog = ({isOpen, onOk, onClose, title, result, resultText, error, errorMessage}) => {
    let color = "#000000";
    if (result === game.MERON) {
        color = "#da1c1c";
    } else if (result === game.WALA) {
        color = "#1231ea";
    } else if (result === game.DRAW) {
        color = "#28a745";
    } else if (result === game.CANCELLED) {
        color = "#ffc107";
    }

    if(resultText == "MERON")
        resultText = "RED";
    else if(resultText == "WALA")
        resultText = "BLUE";
    return (
        <Dialog
            open={isOpen}
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle onClose={onClose}>{title}</DialogTitle>
            <DialogContent
            style={{background: "#424242"}}>
                {error && <Alert severity="error">{errorMessage}</Alert>}
                <Alert severity="warning" style={{fontSize: '1.2rem', color: "#ffffff", background: "#111111"}}>
                    The selected result is <span style={{color: color, fontWeight: "800", textDecoration: "underline"}}>{resultText}</span>. Are you sure?
                </Alert>
            </DialogContent>
            <DialogActions
            style={{background: "#424242"}}>
                <Button className="button btn-primary" onClick={onClose(false)}>Cancel</Button>
                <Button type="submit" className="button btn-primary" onClick={onOk}>Yes</Button>
            </DialogActions>
        </Dialog>
    )
}

export { ResultConfirmationDialog };