/**
 * Bet Logs Component
 *
 * @author EverardOnggon
 */

import React from "react";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import {
	Paper,
	Grid,
	Toolbar,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableFooter,
	TablePagination,
	IconButton,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import HistoryIcon from "@material-ui/icons/History";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { game } from "../../constants/Game";

const StyledTableCell = withStyles((theme) => ({
	root: {
		border: "1px solid #000000",
	},
	head: {
		backgroundColor: "#424242",
		color: "#ffffff",
		fontSize: 14,
		fontWeight: 800,
		textAlign: "center",
		padding: 5,
	},
	body: {
		fontSize: "0.875rem",
		padding: 5,
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		backgroundColor: "#ffffff",
		"&:nth-of-type(odd)": {
			backgroundColor: "#f1f1f1",
		},
	},
}))(TableRow);

const StyledTablePagination = withStyles((theme) => ({
	root: {
		color: "#ffffff",
	},
	selectIcon: {
		color: "#ffffff",
	},
	actions: {
		color: "#ffffff",
	},
}))(TablePagination);

const useStyles = makeStyles((theme) => ({
	toolbar: {
		backgroundColor: "#424242",
		color: "#ffffff",
		fontSize: theme.typography.h6.fontSize,
		fontWeight: theme.typography.h6.fontWeight,
		minHeight: "auto",
		padding: 10,
	},
	blackPaperBackground: {
		backgroundColor: "#000000",
		color: "#ffffff",
		padding: "20px",
		borderRadius: 0,
	},
	table: {
		width: "100%",
	},
	tableContainer: {
		height: 200,
	},
}));

const usePaginationStyles = makeStyles((theme) => ({
	root: {
		flexShrink: 0,
		marginLeft: theme.spacing(2.5),
	},
	iconButton: {
		color: "#FFFFFF",
	},
	disabledButton: {
		opacity: "0.6",
	},
}));

function TablePaginationActions(props) {
	const classes = usePaginationStyles();
	const theme = useTheme();
	const { count, page, rowsPerPage, onChangePage } = props;

	const handleFirstPageButtonClick = (event) => {
		onChangePage(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onChangePage(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onChangePage(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<div className={classes.root}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				classes={{
					label: classes.iconButton,
					disabled: classes.disabledButton,
				}}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? (
					<LastPageIcon />
				) : (
					<FirstPageIcon />
				)}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				classes={{
					label: classes.iconButton,
					disabled: classes.disabledButton,
				}}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowRight />
				) : (
					<KeyboardArrowLeft />
				)}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				classes={{
					label: classes.iconButton,
					disabled: classes.disabledButton,
				}}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowLeft />
				) : (
					<KeyboardArrowRight />
				)}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				classes={{
					label: classes.iconButton,
					disabled: classes.disabledButton,
				}}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? (
					<FirstPageIcon />
				) : (
					<LastPageIcon />
				)}
			</IconButton>
		</div>
	);
}

const BetLogs = ({
	betLogs,
	totalCount,
	page,
	size,
	handleChangePage,
	handleChangeRowsPerPage,
}) => {
	const styles = useStyles();

	return (
		<div>
			<Toolbar className={styles.toolbar}>
				<HistoryIcon />
				&nbsp;Bet Logs
			</Toolbar>
			<Paper className={styles.blackPaperBackground}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						{!(betLogs && betLogs.length > 0) ? (
							<Alert severity="warning">
								No bet transactions found
							</Alert>
						) : (
							<TableContainer>
								<Table className={styles.table}>
									<TableHead>
										<TableRow>
											<StyledTableCell
												style={{ width: "34%" }}
											>
												Fight No.
											</StyledTableCell>
											<StyledTableCell
												style={{ width: "33%" }}
											>
												Bet
											</StyledTableCell>
											<StyledTableCell
												style={{ width: "33%" }}
											>
												Result
											</StyledTableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{betLogs.map((row, index) => {
											let result = "";
											let resultColor = "#000000";
											let resultEarning = "";
											let selectedBetColor = "";

											if (row.fight.result) {
												if ( row.fight.result.cancel ) {
													result = "Cancelled";
													resultColor = "#ffc107";
												} else if ( row.fight.result.draw ) {
													result = "Draw";
													resultColor = "#28a745";
												} else if ( row.fight.result.winningBetPayout ) {
													result = row.fight.result.winningBetPayout.betOption.value;
													if ( row.fight.result.winningBetPayout.betOption.id === game.MERON ) {
														resultColor = "#da1c1c";
													} else if ( row.fight.result.winningBetPayout.betOption.id === game.WALA ) {
														resultColor = "#1231ea";
													} else if ( row.fight.result.winningBetPayout.betOption.id === game.DRAW ) {
														resultColor = "#28a745";
													}
												}
	
												if ( row.resultType.id === game.RESULT_CANCEL || row.resultType.id === game.RESULT_DRAW ) {
													resultEarning = "Returned";
												} else if ( row.resultType.id === game.RESULT_WIN ) {
													resultEarning = "You won";
												} else {
													resultEarning = "You lost";
												}
	
												if ( row.selectedBet.value == "Cancelled" ) {
													selectedBetColor = "#ffc107";
												} else if ( row.selectedBet.id === game.MERON ) {
													selectedBetColor = "#da1c1c";
												} else if ( row.selectedBet.id === game.WALA ) {
													selectedBetColor = "#1231ea";
												} else if ( row.selectedBet.id === game.DRAW ) {
													selectedBetColor = "#28a745";
												}	
											} else {
												result = "On-going";
											}

											return (
												<StyledTableRow key={index}>
													<StyledTableCell>
														<span
															style={{
																fontSize:
																	"0.95rem",
																fontWeight: 800,
															}}
														>
															#{" "}
															{row.fight.fightNo}
														</span>
														<br />
														<Moment format="YYYY-MM-DD hh:mm A">
															{
																row.fight
																	.createDate
															}
														</Moment>
													</StyledTableCell>
													<StyledTableCell>
														You bet&nbsp;
														<NumberFormat
															value={
																row.betAmount
															}
															displayType={"text"}
															thousandSeparator={
																true
															}
															style={{
																fontWeight: 800,
															}}
														/>
														<br />
														on&nbsp;
														<span
															style={{
																color: selectedBetColor,
																fontWeight: 800,
															}}
														>
															{
																row.selectedBet.value
															}
														</span>
													</StyledTableCell>
													<StyledTableCell>
														{result !== "On-going" ? "Result is " : null}
														<span
															style={{
																color: `${resultColor}`,
																fontWeight: 800,
															}}
														>
															{result}
														</span>
														<br />
														{resultEarning}&nbsp;
														<span
															style={{
																fontWeight: 800,
															}}
														>
															{row.resultType ? row.resultType
																.id ===
															game.RESULT_LOSS
																? row.resultAmount
																: row.betAmount +
																  row.resultAmount : null
															}
														</span>
													</StyledTableCell>
												</StyledTableRow>
											);
										})}
									</TableBody>
									<TableFooter>
										<TableRow>
											<StyledTablePagination
												rowsPerPageOptions={[5, 10, 25]}
												colSpan={3}
												count={totalCount}
												rowsPerPage={size}
												page={page}
												onChangePage={handleChangePage}
												onChangeRowsPerPage={
													handleChangeRowsPerPage
												}
												ActionsComponent={
													TablePaginationActions
												}
											/>
										</TableRow>
									</TableFooter>
								</Table>
							</TableContainer>
						)}
					</Grid>
				</Grid>
			</Paper>
		</div>
	);
};

export { BetLogs };
