/**
 * Balance Summary Component
 * 
 * @author EverardOnggon
 */

import React from "react";
import NumberFormat from "react-number-format";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, Toolbar, TableContainer, Table, TableBody, TableRow, TableCell } from "@material-ui/core";
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

const StyledTableCell = withStyles((theme) => ({
    root: {
        border: "1px solid #000000",
    },
    head: {
        backgroundColor: "#424242",
        color: "#ffffff",
        fontSize: 14,
        fontWeight: 800,
        textAlign: "center",
        padding: 5,
    },
    body: {
        fontSize: 16,
        fontWeight: 800,
        padding: 5,
        backgroundColor: "#424242",
		color: "#fff",
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        backgroundColor: "#ffffff",
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    toolbar: {
        backgroundColor: "#424242",
		color: "#fff",
        fontSize: theme.typography.h6.fontSize,
        fontWeight: theme.typography.h6.fontWeight,
        minHeight: "auto",
        padding: 10,
    },
	blackPaperBackground: {
        backgroundColor: "#424242",
		color: "#fff",
        padding: "20px",
        borderRadius: 0,
    },
    table: {
        width: "100%",
    },
    tableContainer: {
        height: 200,
    }
}));

const BalanceSummary = ( { totalBalance, totalDeposits, totalWithdrawals, totalHolds, totalPlayerBets, totalShareRebates, shareRebatePercentage } ) => {
    const styles = useStyles();

    return (
        <div>
            <Toolbar className={styles.toolbar}>
                <FormatListBulletedIcon />&nbsp;Overview
            </Toolbar>
            <Paper className={styles.blackPaperBackground}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table className={styles.table}>
                                <TableBody>
                                    <StyledTableRow>
                                        <StyledTableCell style={{width: "50%"}}>Total Balance</StyledTableCell>
                                        <StyledTableCell style={{width: "50%"}}>
                                            $&nbsp;
                                            <NumberFormat
                                                value={totalBalance}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                            />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell style={{width: "50%"}}>Total Holds</StyledTableCell>
                                        <StyledTableCell style={{width: "50%"}}>
                                            $&nbsp;
                                            <NumberFormat
                                                value={totalHolds}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                            />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell style={{width: "50%"}}>Total Deposits</StyledTableCell>
                                        <StyledTableCell style={{width: "50%"}}>
                                            $&nbsp;
                                            <NumberFormat
                                                value={totalDeposits}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                            />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell style={{width: "50%"}}>Total Withdraw</StyledTableCell>
                                        <StyledTableCell style={{width: "50%"}}>
                                            $&nbsp;
                                            <NumberFormat
                                                value={totalWithdrawals}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                            />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell style={{width: "50%"}}>Total Player Bets</StyledTableCell>
                                        <StyledTableCell style={{width: "50%"}}>
                                            <NumberFormat
                                                value={totalPlayerBets}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                            />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell style={{width: "50%"}}>Total Share Rebates ({shareRebatePercentage}%)</StyledTableCell>
                                        <StyledTableCell style={{width: "50%"}}>
                                            <NumberFormat
                                                value={totalShareRebates}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                            />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
};

export { BalanceSummary };