/**
 * Betting Control Component
 *
 * @author EverardOnggon
 */

import React from "react";
import NumberFormat from "react-number-format";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, Typography, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { game } from "../../../constants/Game";

const useStyles = makeStyles((theme) => ({
	blackPaperBackground: {
		backgroundColor: "#000000",
		color: "#ffffff",
		padding: "20px",
		[theme.breakpoints.down("xs")]: {
			padding: "20px 5px",
		},
	},
	input: {
		fontSize: theme.typography.h4.fontSize,
		fontWeight: theme.typography.h4.fontWeight,
		backgroundColor: "#424242",
		width: "100%",
	},
	sideButton: {
		width: "100%",
		height: "100%",
		borderRadius: 0,
	},
	bettingButton: {
		color: "#ffffff",
		border: "1px solid #ffffff",
		margin: 0,
		padding: "8px 0",
		width: "100%",
	},
	buttonIcon: {
		fontSize: "large",
		color: "#fae369",
	},
	choiceButton: {
		margin: "10px 5px",
		width: "95%",
		fontSize: theme.typography.h6.fontSize,
		fontWeight: theme.typography.h3.fontWeight,
		color: "#ffffff",
		[theme.breakpoints.down("sm")]: {
			fontSize: theme.typography.body1.fontSize,
			fontWeight: theme.typography.h5.fontWeight,
		},
	},
	fightNumber: {
		fontWeight: "bold",
		color: "#da1c1c",
	},
	bettingStatus: {
		fontWeight: "bold",
		color: "#da1c1c",
	},
}));

const BettingControl = ({
	amount,
	isDisabled,
	fightDetails,
	handleBet,
	handleBetAmountChange,
	setPredefinedAmount,
	clear,
	meronBetAmount,
	walaBetAmount,
	drawBetAmount,
	meronTotalPayout,
	walaTotalPayout,
	drawTotalPayout,
}) => {
	const styles = useStyles();
	let status = "---";
	let meronTotalWin = "";
	let walaTotalWin = "";
	let drawTotalWin = "";

	if (fightDetails && fightDetails.status) {
		if (fightDetails.status.id === game.FINALIZED) {
			status = "Closed";
		} 
		if (fightDetails.status.id === game.CLOSED || fightDetails.status.id === game.FINALIZED) {
			meronTotalWin = meronTotalPayout !== null ? ((meronBetAmount / 100) * (meronTotalPayout - 100)) + meronBetAmount : 0;
			walaTotalWin = walaTotalPayout !== null ? ((walaBetAmount / 100) * (walaTotalPayout - 100)) + walaBetAmount : 0;
			drawTotalWin = drawBetAmount !== null && drawTotalPayout !== null ? drawBetAmount * drawTotalPayout : 0;
		} 
		status = fightDetails.status.value;
	}

	return (
		<Paper className={styles.blackPaperBackground}>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={12} md={9}>
					<Typography
						variant="h5"
						component="h3"
						className="text-center"
						style={{
							color: "#ffffff",
							marginBottom: 15,
						}}
					>
						Pelea #{" "}
						<span id="fightNumber" className={styles.fightNumber}>
							{fightDetails !== undefined
								? fightDetails.fightNo
								: "---"}
						</span>{" "}
						Apuesta:{" "}
						<span
							id="bettingStatus"
							className={styles.bettingStatus}
							style={{
								textTransform: "uppercase",
							}}
						>
							{status}
						</span>
					</Typography>
					<form noValidate autoComplete="off">
						<Grid container spacing={0}>
							<Grid item xs={9} sm={9}>
								<CurrencyTextField
									className={styles.input}
									value={amount}
									onChange={handleBetAmountChange}
									outputFormat="number"
									decimalCharacter="."
									decimalPlaces={0}
									digitGroupSeparator=","
									currencySymbol=""
									textAlign="center"
									minimumValue="0"
									inputProps={{
										inputMode: 'numeric',
										style: {
											fontSize: "1.5rem",
											padding: "15px 5px",
											color: "#ffffff",
										},
									}}
								/>
							</Grid>
							<Grid item xs={3} sm={3}>
								<Button
									variant="contained"
									className={`button btn-primary ${styles.sideButton}`}
									onClick={clear}
								>
									LIMPIAR
								</Button>
							</Grid>
						</Grid>
						<Grid container spacing={0}>
							<Grid
								item
								xs={6}
								style={{
									textAlign: "center",
								}}
							>
								<Button
									variant="contained"
									className={styles.choiceButton}
									style={{
										backgroundColor: "#da1c1c",
										fontSize: "15px",
									}}
									disabled={isDisabled}
									onClick={handleBet(game.MERON)}
								>
									APOSTAR&nbsp;ROJO
								</Button>
							</Grid>
							<Grid
								item
								xs={6}
								style={{
									textAlign: "center",
								}}
							>
								<Button
									variant="contained"
									className={styles.choiceButton}
									style={{
										backgroundColor: "#1231ea",
										fontSize: "15px",
									}}
									disabled={isDisabled}
									onClick={handleBet(game.WALA)}
								>
									APOSTAR&nbsp;AZUL
								</Button>
							</Grid>
							<Grid
								item
								xs={6}
								style={{
									textAlign: "center",
								}}
							>
								<span
									className="yellow-color"
									style={{
										fontWeight: 800,
									}}
								>$&nbsp;</span>
								<NumberFormat
									value={meronBetAmount}
									displayType={"text"}
									thousandSeparator={true}
									className="yellow-color"
									style={{
										fontWeight: 800,
									}}
								/>
							</Grid>
							<Grid
								item
								xs={6}
								style={{
									textAlign: "center",
								}}
							>
								<span
									className="yellow-color"
									style={{
										fontWeight: 800,
									}}
								>$&nbsp;</span>
								<NumberFormat
									value={walaBetAmount}
									displayType={"text"}
									thousandSeparator={true}
									className="yellow-color"
									style={{
										fontWeight: 800,
									}}
								/>
							</Grid>
							<Grid
								item
								xs={6}
								style={{
									textAlign: "center",
								}}
							>
								{meronTotalWin !== "" ? (
									<span>
										Payout:&nbsp;
										<NumberFormat
											value={meronTotalWin}
											displayType={"text"}
											thousandSeparator={true}
											decimalScale={2}
											fixedDecimalScale={true}
											className="red-color"
											style={{
												fontWeight: 800,
											}}
										/>
									</span>
								) : null}
							</Grid>
							<Grid
								item
								xs={6}
								style={{
									textAlign: "center",
								}}
							>
								{meronTotalWin !== "" ? (
									<span>
										Payout:&nbsp;
										<NumberFormat
											value={walaTotalWin}
											displayType={"text"}
											thousandSeparator={true}
											decimalScale={2}
											fixedDecimalScale={true}
											className="blue-color"
											style={{
												fontWeight: 800,
											}}
										/>
									</span>
								) : null}
							</Grid>
						</Grid>
						<Grid container spacing={0}>
							<Grid
								item
								xs={12}
								style={{
									textAlign: "center",
								}}
							>
								<Button
									variant="contained"
									className={styles.choiceButton}
									style={{
										backgroundColor: "#28a745",
									}}
									disabled={isDisabled}
									onClick={handleBet(game.DRAW)}
								>
									APOSTAR&nbsp;EMPATE
								</Button>
							</Grid>
							<Grid
								item
								xs={12}
								style={{
									textAlign: "center",
								}}
							>
								<span
									className="yellow-color"
									style={{
										fontWeight: 800,
									}}
								>$&nbsp;</span>
								<NumberFormat
									value={drawBetAmount}
									displayType={"text"}
									thousandSeparator={true}
									className="yellow-color"
									style={{
										fontWeight: 800,
									}}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								style={{
									textAlign: "center",
								}}
							>
								{drawTotalWin !== "" ? (
									<span>
										Payout:&nbsp;
										<NumberFormat
											value={drawTotalWin}
											displayType={"text"}
											thousandSeparator={true}
											decimalScale={2}
											fixedDecimalScale={true}
											className="green-color"
											style={{
												fontWeight: 800,
											}}
										/>
									</span>
								) : null}
							</Grid>
						</Grid>
					</form>
				</Grid>
				<Grid item xs={12} sm={12} md={3}>
					<Grid container spacing={0}>
						<Grid item xs={12} style={{ textAlign: "center" }}>
							<Button
								variant="outlined"
								className={styles.bettingButton}
								onClick={setPredefinedAmount(5)}
							>
								<AddIcon className={styles.buttonIcon} />
								$ 5
							</Button>
							<Button
								variant="outlined"
								className={styles.bettingButton}
								onClick={setPredefinedAmount(10)}
							>
								<AddIcon className={styles.buttonIcon} />
								$ 10
							</Button>
							<Button
								variant="outlined"
								className={styles.bettingButton}
								onClick={setPredefinedAmount(20)}
							>
								<AddIcon className={styles.buttonIcon} />
								$ 20
							</Button>
							<Button
								variant="outlined"
								className={styles.bettingButton}
								onClick={setPredefinedAmount(50)}
							>
								<AddIcon className={styles.buttonIcon} />
								$ 50
							</Button>
							<Button
								variant="outlined"
								className={styles.bettingButton}
								onClick={setPredefinedAmount(100)}
							>
								<AddIcon className={styles.buttonIcon} />
								$ 100
							</Button>
							<Button
								variant="outlined"
								className={styles.bettingButton}
								onClick={setPredefinedAmount(200)}
							>
								<AddIcon className={styles.buttonIcon} />
								$ 200
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
};

export { BettingControl };
