/**
 * Change Password Dialog Component
 * 
 * @author EverardOnggon
 */

import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { 
    Dialog,
    DialogContent,
    DialogActions,
    IconButton,
    Typography,
    Button,
    TextField,
} from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose(false)}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const ValidationTextField = withStyles({
    root: {
        width: "100%",
        marginBottom: "0.75rem !important",
        '& input:focus:valid + fieldset': {
            borderColor: 'green',
            borderWidth: 2,
        },
        '& input:focus:invalid + fieldset': {
            borderColor: 'red',
            borderWidth: 2,
        },
        '& input:valid:focus + fieldset': {
            padding: '4px !important',
        },
    },
})(TextField);

const ChangePasswordDialog = ({isOpen, error, message, onSubmit, onClose, handleChange, isLoading}) => {
    return (
        <Dialog
            open={isOpen}
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle onClose={onClose}>Change Password</DialogTitle>
            <form onSubmit={onSubmit}>
                <DialogContent>
                    {error && <Alert severity="error">{message}</Alert>}
                    {!error && message && <Alert severity="success">{message}</Alert>}
                    <br />
                    <div>
                        <ValidationTextField
                            variant="outlined"
                            required
                            id="oldPassword"
                            label="Old Password"
                            className="iron-form-input-wrap"
                            type="password"
                            name="oldPassword"
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <ValidationTextField
                            variant="outlined"
                            required
                            id="newPassword"
                            label="New Password"
                            className="iron-form-input-wrap"
                            type="password"
                            name="newPassword"
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <ValidationTextField
                            variant="outlined"
                            required
                            id="confirmNewPassword"
                            label="Confirm New Password"
                            className="iron-form-input-wrap"
                            type="password"
                            name="confirmNewPassword"
                            onChange={handleChange}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button className="button btn-base" onClick={onClose(false)}>Cancel</Button>
                    <Button
                        type="submit"
                        className="button btn-primary"
                        disabled={isLoading}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export { ChangePasswordDialog };