/**
 * System Config Actions
 *
 * @author EverardOnggon
 */

import axios from "axios";
import {
	API_URL,
	GET_SYSTEM_CONFIG_SUCCESS,
    GET_SYSTEM_CONFIG_FAILED,
    UPDATE_SYSTEM_CONFIG_SUCCESS,
    UPDATE_SYSTEM_CONFIG_FAILED,
} from "./types";
import { getHeaders } from "./AuthActions";

export const getSystemConfig = () => async (dispatch) => {
    try {
        const response = await axios.get(
			`${API_URL}/system/config/all`,
			await getHeaders()
		);
		if (response.status === 200 || response.status === 201) {
			dispatch({
				type: GET_SYSTEM_CONFIG_SUCCESS,
				data: response.data,
			});
		}
    } catch (error) {
        switch (error.response.status) {
			case 401:
				dispatch({
					type: GET_SYSTEM_CONFIG_FAILED,
					error: error.response.status.toString(),
				});
				break;
			default:
				dispatch({
					type: GET_SYSTEM_CONFIG_FAILED,
					error: error.response.data.message,
				});
		}
    }
}

export const updateSystemConfig = (configId, enabled) => async (dispatch) => {
    try {
        const response = await axios.post(
            `${API_URL}/system/config/update/${configId}?enabled=${enabled}`,
            {},
			await getHeaders()
		);
		if (response.status === 200 || response.status === 201) {
			dispatch({
				type: UPDATE_SYSTEM_CONFIG_SUCCESS,
				data: response.data,
			});
		}
    } catch (error) {
        switch (error.response.status) {
			case 401:
				dispatch({
					type: UPDATE_SYSTEM_CONFIG_FAILED,
					error: error.response.status.toString(),
				});
				break;
			default:
				dispatch({
					type: UPDATE_SYSTEM_CONFIG_FAILED,
					error: error.response.data.message,
				});
		}
    }
}
export const updateSystemConfigValue = (configId, value) => async (dispatch) => {
    try {
        const response = await axios.post(
            `${API_URL}/system/config/update/${configId}?value=${value}`,
            {},
			await getHeaders()
		);
		if (response.status === 200 || response.status === 201) {
			dispatch({
				type: UPDATE_SYSTEM_CONFIG_SUCCESS,
				data: response.data,
			});
		}
    } catch (error) {
        switch (error.response.status) {
			case 401:
				dispatch({
					type: UPDATE_SYSTEM_CONFIG_FAILED,
					error: error.response.status.toString(),
				});
				break;
			default:
				dispatch({
					type: UPDATE_SYSTEM_CONFIG_FAILED,
					error: error.response.data.message,
				});
		}
    }
}