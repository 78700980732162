/**
 * My Players Page
 *
 * @author EverardOnggon
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { MyPlayersList, Register, LoadingForm } from "../../components";
import { Notification } from "../../components/Common";
import { getPlayersListByAgentId } from "../../actions/PlayerActions";
import { createUser } from "../../actions/RegisterActions";
import {
	getOtherPlayerById,
	getTransactionTypes,
	moneyTransaction,
} from "../../actions/OtherPlayerActions";
import { role } from "../../constants/User";

class MyPlayersPage extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			playersList: [],
			playersListMessage: "",
			page: 0,
			size: 10,
			totalCount: 0,
			searchNameKey: "",
			tempSearchNameKey: "",
			openCreateUser: false,
			// Create User
			firstName: "",
			middleName: "",
			lastName: "",
			birthDate: "",
			address: "",
			emailAddress: "",
			mobileNumber: "",
			uploadId: "",
			shareRebatePercentage: 0,
			roleId: 0,
			username: "",
			password: "",
			confirmPassword: "",
			message: "",
			error: false,
			isCreateUserLoading: false,
			// Loading
			transactionTypes: [],
			openLoadingForm: false,
			userIdToLoad: null,
			loadAmount: 0,
			loadingPassword: "",
			loadingRemarks: "",
			transactionTypeId: 0,
			loadingMessage: "",
			loadingError: false,
			isTransactionLoading: false,
			// Snackbar
			openSnackbar: false,
			snackbarMessage: "",
			snackbarSeverity: "error",
		};
	}

	componentDidMount() {
		this._isMounted = true;

		this.getPlayersListByAgentId();

		this.props.getTransactionTypes().then(() => {
			// nothing to do here.
		});
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	getPlayersListByAgentId = () => {
		this.props
			.getPlayersListByAgentId(
				this.props.userId,
				this.state.page,
				this.state.size,
				this.state.searchNameKey
			)
			.then(() => {
				if (!this.props.isPlayerError && this.props.players) {
					this.setState({
						playersList: this.props.players,
						playersListMessage: "",
						totalCount: this.props.totalCount,
					});
				} else {
					if (this.props.playerError === "401") {
						this.setState(
							{
								openSnackbar: true,
								snackbarMessage:
									"Session expired. Logging out.",
								snackbarSeverity: "error",
								totalCount: 0,
							},
							() => {
								setTimeout(() => {
									this.props.handleLogout();
								}, 1000);
							}
						);
					} else {
						this.setState({
							playersListMessage: this.props.playerError
								? this.props.playerError
								: "Server error. Please try again.",
							totalCount: 0,
						});
					}
				}
			});
	};

	handleChangePage = (event, newPage) => {
		this.setState(
			{
				page: newPage,
			},
			() => {
				this.getPlayersListByAgentId();
			}
		);
	};

	handleChangeRowsPerPage = (event) => {
		this.setState(
			{
				size: event.target.value ? parseInt(event.target.value) : 10,
				page: 0,
			},
			() => {
				this.getPlayersListByAgentId();
			}
		);
	};

	toggleCreateUserDialog = (isOpen) => () => {
		this.setState({
			openCreateUser: isOpen,
		});
	};

	handleCreateUserFormChange = (event) => {
		const { name, value } = event.target;
		this.setState({
			[name]: value,
		});
	};

	handleFileUpload = (event) => {
		if (this._isMounted) {
			if (event.target.files[0]) {
				this.setState({
					uploadId: event.target.files[0],
				});
			}
		}
	};

	handleCreateUser = (e) => {
		e.preventDefault();

		this.setState({
			message: "",
			error: false,
		});

		let roleId = 0;

		if (this.props.roleId === role.ADMIN) {
			roleId = role.SUPER_AGENT;
		} else if (this.props.roleId === role.SUPER_AGENT) {
			roleId = role.SUPER_SUB_AGENT;
		} else {
			roleId = role.PLAYER;
		}

		const payload = {
			firstName: this.state.firstName,
			middleName: this.state.middleName,
			lastName: this.state.lastName,
			birthDate: this.state.birthDate,
			address: this.state.address,
			emailAddress: this.state.emailAddress,
			mobileNumber: this.state.mobileNumber,
			file: this.state.uploadId,
			shareRebatePercentage: this.state.shareRebatePercentage,
			roleId: this.state.roleId === 0 ? roleId : this.state.roleId,
			username: this.state.username,
			password: this.state.password,
		};

		if (
			payload.firstName.trim() === "" ||
			payload.lastName.trim() === "" ||
			payload.birthDate.trim() === "" ||
			payload.address.trim() === "" ||
			payload.mobileNumber.trim() === "" ||
			payload.username.trim() === "" ||
			payload.password.trim() === ""
		) {
			this.setState({
				message: "Please fill up all required fields",
				error: true,
			});
		} else if (payload.password !== this.state.confirmPassword) {
			this.setState({
				message: "Password mismatched",
				error: true,
			});
		} else {
			this.setState({
				isCreateUserLoading: true,
			});
			this.props.createUser(payload).then(() => {
				if (!this.props.isRegisterError) {
					this.setState(
						{
							openSnackbar: true,
							snackbarMessage: "User created successfully",
							snackbarSeverity: "success",
							openCreateUser: false,
							message: "",
							error: false,
							isCreateUserLoading: false,
						},
						() => {
							this.getPlayersListByAgentId();
						}
					);
				} else {
					if (this.props.registerError === "401") {
						this.setState(
							{
								openSnackbar: true,
								snackbarMessage:
									"Session expired. Logging out.",
								snackbarSeverity: "error",
							},
							() => {
								setTimeout(() => {
									this.props.handleLogout();
								}, 1000);
							}
						);
					} else {
						this.setState({
							message: this.props.registerError
								? this.props.registerError
								: "Server error. Please try again.",
							error: true,
							isCreateUserLoading: false,
						});
					}
				}
			});
		}
	};

	openLoadingForm = (userId) => () => {
		this.props.getOtherPlayerById(userId).then(() => {
			if (!this.props.isOtherPlayerError) {
				this.setState({
					userIdToLoad: userId,
					openLoadingForm: true,
				});
			} else {
				if (this.props.otherPlayerError === "401") {
					this.setState(
						{
							openSnackbar: true,
							snackbarMessage: "Session expired. Logging out.",
							snackbarSeverity: "error",
						},
						() => {
							setTimeout(() => {
								this.props.handleLogout();
							}, 1000);
						}
					);
				} else {
					this.setState({
						openSnackbar: true,
						snackbarMessage: this.props.otherPlayerError
							? this.props.otherPlayerError
							: "Server error. Please try again.",
						snackbarSeverity: "error",
					});
				}
			}
		});
	};

	toggleLoadingFormDialog = (isOpen) => () => {
		this.setState({
			openLoadingForm: isOpen,
		});
	};

	handleLodingFormChange = (event) => {
		const { name, value } = event.target;
		this.setState({
			[name]: value,
		});
	};

	handleLoading = (e) => {
		e.preventDefault();

		this.setState(
			{
				isTransactionLoading: true,
			},
			() => {
				if (this.state.userIdToLoad !== null) {
					const payload = {
						amount: this.state.loadAmount.replace(/,/g, ""),
						password: this.state.loadingPassword,
						playerId: this.state.userIdToLoad,
						remarks: this.state.loadingRemarks,
						typeId: this.state.transactionTypeId,
					};

					this.props.moneyTransaction(payload).then(() => {
						if (!this.props.isMoneyTransactionError) {
							this.setState(
								{
									openSnackbar: true,
									snackbarMessage: "Transaction successful",
									snackbarSeverity: "success",
									openLoadingForm: false,
									loadingMessage: "",
									loadingError: false,
									isTransactionLoading: false,
								},
								() => {
									this.getPlayersListByAgentId();
									setTimeout(() => {
										this.props.getBalance(); // update the available balance displayed.
									}, 1000);
								}
							);
						} else {
							if (this.props.moneyTransactionError === "401") {
								this.setState(
									{
										openSnackbar: true,
										snackbarMessage:
											"Session expired. Logging out.",
										snackbarSeverity: "error",
										isTransactionLoading: false,
									},
									() => {
										setTimeout(() => {
											this.props.handleLogout();
										}, 1000);
									}
								);
							} else {
								this.setState({
									loadingMessage: this.props
										.moneyTransactionError
										? this.props.moneyTransactionError
										: "Server error. Please try again.",
									loadingError: true,
									isTransactionLoading: false,
								});
							}
						}
					});
				} else {
					this.setState({
						openSnackbar: true,
						snackbarMessage:
							"Something went wrong. Please try again.",
						isTransactionLoading: false,
					});
				}
			}
		);
	};

	handleSearchChange = (event) => {
		this.setState({
			tempSearchNameKey: event.target.value,
		});
	};

	handleSearch = () => {
		this.setState(
			{
				searchNameKey: this.state.tempSearchNameKey,
				page: 0,
			},
			() => {
				this.getPlayersListByAgentId();
			}
		);
	};

	handleCloseSnackbar = () => {
		this.setState({
			openSnackbar: false,
		});
	};

	render() {
		return (
			<div className="container">
				<Notification
					open={this.state.openSnackbar}
					onClose={this.handleCloseSnackbar}
					message={this.state.snackbarMessage}
					severity={this.state.snackbarSeverity}
				/>
				<Dialog
					open={this.state.openCreateUser}
					aria-labelledby="create-user-dialog-title"
					fullWidth={true}
					maxWidth="md"
				>
					<DialogTitle id="create-user-dialog-title">
						Create User / Crear Usario
					</DialogTitle>
					<DialogContent>
						<Register
							handleRegister={this.handleCreateUser}
							handleChange={this.handleCreateUserFormChange}
							handleFileUpload={this.handleFileUpload}
							message={this.state.message}
							error={this.state.error}
							isFromLogin={false}
							onClose={this.toggleCreateUserDialog}
							roleId={this.props.roleId}
							isLoading={this.state.isCreateUserLoading}
							roleIdToCreate={this.state.roleId}
						/>
					</DialogContent>
				</Dialog>
				<Dialog
					fullWidth={true}
					maxWidth="sm"
					open={this.state.openLoadingForm}
					aria-labelledby="loading-form-dialog-title"
				>
					<DialogTitle id="loading-form-dialog-title">
						Loading Station
					</DialogTitle>
					<DialogContent>
						<LoadingForm
							transactionTypes={
								this.props.transactionTypes
									? this.props.transactionTypes
									: []
							}
							name={
								this.props.otherUserProfile
									? `${this.props.otherUserProfile.firstName} ${this.props.otherUserProfile.middleName} ${this.props.otherUserProfile.lastName}`
									: ""
							}
							message={this.state.loadingMessage}
							error={this.state.loadingError}
							handleLoading={this.handleLoading}
							handleChange={this.handleLodingFormChange}
							onClose={this.toggleLoadingFormDialog}
							isLoading={this.state.isTransactionLoading}
						/>
					</DialogContent>
				</Dialog>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<MyPlayersList
							players={this.state.playersList}
							totalCount={this.state.totalCount}
							page={this.state.page}
							size={this.state.size}
							handleChangePage={this.handleChangePage}
							handleChangeRowsPerPage={
								this.handleChangeRowsPerPage
							}
							openCreateUser={this.toggleCreateUserDialog}
							openLoadingForm={this.openLoadingForm}
							handleSearchChange={this.handleSearchChange}
							handleSearch={this.handleSearch}
						/>
					</Grid>
				</Grid>
			</div>
		);
	}
}

const mapStatetoProps = ({ user, player, register, otherPlayer }) => {
	const { userId, roleId, code, shareRebatePercentage } = user;
	const { players, totalCount, playerError, isPlayerError } = player;
	const { registerError, isRegisterError } = register;
	const {
		otherUserProfile,
		otherPlayerError,
		isOtherPlayerError,
		transactionTypes,
		transactionTypesError,
		moneyTransactionError,
		isMoneyTransactionError,
	} = otherPlayer;

	return {
		userId,
		roleId,
		code,
		shareRebatePercentage,
		players,
		totalCount,
		playerError,
		isPlayerError,
		registerError,
		isRegisterError,
		otherUserProfile,
		otherPlayerError,
		isOtherPlayerError,
		transactionTypes,
		transactionTypesError,
		moneyTransactionError,
		isMoneyTransactionError,
	};
};

export default connect(mapStatetoProps, {
	getPlayersListByAgentId,
	createUser,
	getOtherPlayerById,
	getTransactionTypes,
	moneyTransaction,
})(MyPlayersPage);
