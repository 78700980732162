/**
 * Live Betting Component
 * 
 * @author EverardOnggon
 */

import React from "react";
import NumberFormat from "react-number-format";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, Toolbar, Typography, Box } from "@material-ui/core";
import AnimatedNumber from "animated-number-react";
import { dark } from "@material-ui/core/styles/createPalette";

const useStyles = makeStyles((theme) => ({
    border: {
        border: "1px solid #FFFFFF",
    },
    toolbar: {
        width: "100%",
        textTransform: "uppercase",
    },
    toolbarText: {
        margin: "0 auto",
        color: "#ffffff",
        fontSize: theme.typography.h5.fontSize,
        [theme.breakpoints.down("sm")]: {
            fontSize: theme.typography.body1.fontSize,
        },
    },
    toolbarTextBold: {
        fontWeight: 700,
        margin: "0 auto",
        color: "#ffffff",
        fontSize: theme.typography.h5.fontSize,
        [theme.breakpoints.down("sm")]: {
            fontSize: theme.typography.body1.fontSize,
        },
    },
    details: {
        fontWeight: 700,
        textAlign: "center",
        fontSize: theme.typography.h3.fontSize,
        color: "#ffc107",
        backgroundColor: "#000",
        padding: "15px 0",
        [theme.breakpoints.down("sm")]: {
            fontSize: theme.typography.h5.fontSize,
        },
    },
    payout: {
        color: "#FFF",
        backgroundColor: "#000",
        textAlign: "center",
        fontSize: theme.typography.subtitle1.fontSize,
        [theme.breakpoints.down("sm")]: {
            fontSize: theme.typography.body1.fontSize,
        },
    }
}));

const formatValue = (value) => '$ ' + value.toLocaleString(undefined, {maximumFractionDigits:0});

const LiveBetting = ({ betOptionPayouts, showActualPlayerBets }) => {
    const styles = useStyles();


    let meronTotalBet = betOptionPayouts ? betOptionPayouts[0].totalBet : 0;
    let meronTotalPlayerBet = betOptionPayouts ? betOptionPayouts[0].totalBetAmountWithoutEqualizer : 0;
    let meronTotalPayout = betOptionPayouts ? betOptionPayouts[0].totalPayout : 1;
    let walaTotalBet = betOptionPayouts ? betOptionPayouts[1].totalBet : 0;
    let walaTotalPlayerBet = betOptionPayouts ? betOptionPayouts[1].totalBetAmountWithoutEqualizer : 0;
    let walaTotalPayout = betOptionPayouts ? betOptionPayouts[1].totalPayout : 1;
    let drawTotalBet = betOptionPayouts ? betOptionPayouts[2].totalBet : 0;
    let drawTotalPlayerBet = betOptionPayouts ? betOptionPayouts[2].totalBetAmountWithoutEqualizer : 0;

    return (
        <Paper>
            <Grid container spacing={0}>
                <Grid
                    item
                    xs={6}
                    className={styles.border}
                >
                    <Box
                        className={styles.toolbar}
                        style={{
                            backgroundColor: "#da1c1c",
                            textAlign: "center"
                        }}
                    >
                        <Typography className={styles.toolbarText}>
                        RED / ROJO
                        </Typography>
                        <Typography className={styles.toolbarText}>
                            (MERON)
                        </Typography>
                    </Box>
                    <Box>
                        <Typography className={styles.details}>
                            <AnimatedNumber
                                value={meronTotalBet}
                                displayType={"text"}
                                thousandSeparator={true}
                                className={styles.amountColor}
                                formatValue={formatValue}
                            />
                        </Typography>
                        <Typography className={styles.payout}>
                            PAGO = <strong>{meronTotalPayout}</strong>
                        </Typography>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={6}
                    className={styles.border}
                >
                    <Box
                        className={styles.toolbar}
                        style={{
                            backgroundColor: "#1231ea",
                            textAlign: "center"
                        }}
                    >
                        <Typography className={styles.toolbarText}>
                            BLUE / AZUL
                        </Typography>
                        <Typography className={styles.toolbarText}>
                            (WALA)
                        </Typography>
                    </Box>
                    <Box>
                        <Typography className={styles.details}>
                            <AnimatedNumber
                                value={walaTotalBet}
                                displayType={"text"}
                                thousandSeparator={true}
                                className={styles.amountColor}
                                formatValue={formatValue}
                            />
                        </Typography>
                        <Typography className={styles.payout}>
                            PAGO = <strong>{walaTotalPayout}</strong>
                        </Typography>
                    </Box>
                </Grid>
            { showActualPlayerBets ?
            <>
                <Grid item xs={6}>
                    <Toolbar
                        className={styles.toolbar}
                        style={{
                            backgroundColor: "#28a745",
                        }}
                    >
                        <Typography className={styles.toolbarText}>
                            {"Draw"}
                        </Typography>
                    </Toolbar>
                </Grid>
                <Grid item xs={6}>
                    <Toolbar
                        className={styles.toolbar}
                        style={{
                            backgroundColor: "#28a745",
                        }}
                    >
                        <Typography className={styles.toolbarTextBold}>
                            <AnimatedNumber
                                value={drawTotalBet}
                                displayType={"text"}
                                thousandSeparator={true}
                                className={styles.amountColor}
                                formatValue={formatValue}
                            />
                        </Typography>
                    </Toolbar>
                </Grid></>
            : ""}
            </Grid>
            { showActualPlayerBets ?
                <Grid container spacing={0}>
                    <Grid
                        item
                        xs={6}
                        className={styles.border}
                    >
                        <Toolbar
                            className={styles.toolbar}
                            style={{
                                backgroundColor: "#da1c1c",
                            }}
                        >
                            <Typography className={styles.toolbarText}>
                                Actual Red
                            </Typography>
                        </Toolbar>
                        <Box>
                            <Typography className={styles.details}>
                                <AnimatedNumber
                                    value={meronTotalPlayerBet}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    className={styles.amountColor}
                                    formatValue={formatValue}
                                />
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        className={styles.border}
                    >
                        <Toolbar
                            className={styles.toolbar}
                            style={{
                                backgroundColor: "#1231ea",
                            }}
                        >
                            <Typography className={styles.toolbarText}>
                                Actual Blue
                            </Typography>
                        </Toolbar>
                        <Box>
                            <Typography className={styles.details}>
                                <AnimatedNumber
                                    value={walaTotalPlayerBet}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    className={styles.amountColor}
                                    formatValue={formatValue}
                                />
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Toolbar
                            className={styles.toolbar}
                            style={{
                                backgroundColor: "#28a745",
                            }}
                        >
                            <Typography className={styles.toolbarText}>
                                {"Actual Draw"}
                            </Typography>
                        </Toolbar>
                    </Grid>
                    <Grid item xs={6}>
                        <Toolbar
                            className={styles.toolbar}
                            style={{
                                backgroundColor: "#28a745",
                            }}
                        >
                            <Typography className={styles.toolbarTextBold}>
                                <AnimatedNumber
                                    value={drawTotalPlayerBet}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    className={styles.amountColor}
                                    formatValue={formatValue}
                                />
                            </Typography>
                        </Toolbar>
                    </Grid>
                </Grid> 
                : ""}
        </Paper>
    )
};

export { LiveBetting };