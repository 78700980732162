/**
 * User Details Page
 *
 * @author EverardOnggon
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { UserUpdate, BalanceSummary, TransactionsList } from "../../components";
import { Notification, HeaderWithBackButton, ConfirmationDialog } from "../../components/Common";
import { getOtherPlayerById, reactivateUser, deactivateUser, deleteUser } from "../../actions/OtherPlayerActions";
import { updateUser } from "../../actions/RegisterActions";
import { getTransactionsByUserId } from "../../actions/TransactionActions";

class UserDetailsPage extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			// User Details
			id: 0,
			firstName: "",
			middleName: "",
			lastName: "",
			birthDate: "",
			address: "",
			emailAddress: "",
			mobileNumber: "",
			uploadId: "",
			shareRebatePercentage: 0,
			roleId: 0,
			username: "",
			password: "",
			confirmPassword: "",
			isUpdateLoading: false,
			enabled: true,
			// Transaction Details
			totalBalance: 0,
			totalDeposits: 0,
			totalWithdrawals: 0,
			totalHolds: 0,
			totalShareRebates: 0,
			transactions: [],
			page: 0,
			size: 5,
			totalCount: 0,
			transactionError: this.props.transactionError,
			// Snackbar
			openSnackbar: false,
			snackbarMessage: "",
			snackbarSeverity: "error",
			// Confirmation Dialog
			isReactivateConfirmationDialog: false,
			isDeactivateConfirmationDialog: false,
			isDeleteConfirmationDialog: false,
			reactivateError: false,
			deactivateError: false,
			deleteError: false,
			reactivateErrorMessage: "",
			deactivateErrorMessage: "",
			deleteErrorMessage: "",
		};
	}

	componentDidMount() {
		this._isMounted = true;

		this.getPlayerDetailsById();
		this.getTransactionsByUserId();
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	getPlayerDetailsById = () => {
		let pathName = window.location.pathname;
		let id = pathName.split("/", 4);

		this.props.getOtherPlayerById(id[3]).then(() => {
			if (!this.props.isOtherPlayerError && this.props.otherUserProfile) {
				this.setState({
					id: this.props.otherUserProfile.id,
					firstName: this.props.otherUserProfile.firstName,
					middleName: this.props.otherUserProfile.middleName,
					lastName: this.props.otherUserProfile.lastName,
					birthDate: this.props.otherUserProfile.birthDate,
					address: this.props.otherUserProfile.address,
					emailAddress: this.props.otherUserProfile.emailAddress,
					mobileNumber: this.props.otherUserProfile.mobileNumber,
					shareRebatePercentage: this.props
						.otherShareRebatePercentage,
					roleId:
						this.props.otherUserProfile &&
						this.props.otherUserProfile.roles
							? this.props.otherUserProfile.roles[0].id
							: 0,
					username: this.props.otherUserProfile.username,
					enabled: this.props.otherUserProfile.enabled,
				});
			} else {
				if (this.props.otherPlayerError === "401") {
					this.setState(
						{
							snackbarMessage: "Session expired. Logging out.",
							snackbarSeverity: "error",
							openSnackbar: true,
						},
						() => {
							setTimeout(() => {
								this.props.handleLogout();
							}, 1000);
						}
					);
				} else {
					this.setState({
						firstName: "",
						middleName: "",
						lastName: "",
						birthDate: "",
						address: "",
						emailAddress: "",
						mobileNumber: "",
						shareRebatePercentage: 0,
						roleId: 0,
						username: "",
						enabled: false,
						snackbarMessage: this.props.otherPlayerError
							? this.props.otherPlayerError
							: "Server error. Please try again.",
						snackbarSeverity: "error",
						openSnackbar: true,
					});
				}
			}
		});
	};

	handleUpdate = (e) => {
		e.preventDefault();

		const payload = {
			address: this.state.address,
			birthDate: this.state.birthDate,
			emailAddress: this.state.emailAddress,
			firstName: this.state.firstName,
			lastName: this.state.lastName,
			middleName: this.state.middleName,
			mobileNumber: this.state.mobileNumber,
			roleId: this.state.roleId,
			shareRebatePercentage: this.state.shareRebatePercentage
				? this.state.shareRebatePercentage
				: 0,
			username: this.state.username,
			password: this.state.password ? this.state.password : "",
		};

		if (
			payload.address.trim() === "" ||
			payload.birthDate.trim() === "" ||
			payload.firstName.trim() === "" ||
			payload.lastName.trim() === "" ||
			payload.mobileNumber.trim() === "" ||
			payload.roleId === 0 ||
			payload.username.trim() === ""
		) {
			this.setState({
				snackbarMessage: "Please fill up all the required fields",
				snackbarSeverity: "error",
				openSnackbar: true,
			});
		} else if (
			payload.password.trim() !== "" &&
			payload.password !== this.state.confirmPassword
		) {
			this.setState({
				snackbarMessage: "Password mismatched",
				snackbarSeverity: "error",
				openSnackbar: true,
			});
		} else {
			this.setState({
				isUpdateLoading: true,
			});
			this.props.updateUser(payload, this.state.id).then(() => {
				if (!this.props.isUpdateError) {
					this.setState({
						snackbarMessage: "User updated successfully",
						snackbarSeverity: "success",
						openSnackbar: true,
						isUpdateLoading: false,
					});
				} else {
					if (this.props.updateError === "401") {
						this.setState(
							{
								snackbarMessage:
									"Session expired. Logging out.",
								snackbarSeverity: "error",
								openSnackbar: true,
								isUpdateLoading: false,
							},
							() => {
								setTimeout(() => {
									this.props.handleLogout();
								}, 1000);
							}
						);
					} else {
						this.setState({
							snackbarMessage: this.props.updateError
								? this.props.updateError
								: "Server error. Please try again.",
							snackbarSeverity: "error",
							openSnackbar: true,
							isUpdateLoading: false,
						});
					}
				}
			});
		}
	};

	getTransactionsByUserId = () => {
		let pathName = window.location.pathname;
		let id = pathName.split("/", 4);

		this.props
			.getTransactionsByUserId(id[3], this.state.page, this.state.size)
			.then(() => {
				if (
					!this.props.isTransactionError &&
					this.props.totalBalance >= 0
				) {
					this.setState({
						totalBalance: this.props.totalBalance,
						totalDeposits: this.props.totalDeposits,
						totalWithdrawals: this.props.totalWithdrawals,
						totalHolds: this.props.totalHolds,
						totalShareRebates: this.props.totalShareRebates,
						transactions: this.props.transactions,
						totalCount: this.props.totalCount,
						error: "",
					});
				} else {
					if (this.props.transactionError === "401") {
						this.setState(
							{
								snackbarMessage:
									"Session expired. Logging out.",
								snackbarSeverity: "error",
								openSnackbar: true,
							},
							() => {
								setTimeout(() => {
									this.props.handleLogout();
								}, 1000);
							}
						);
					} else {
						this.setState({
							totalBalance: 0,
							totalDeposits: 0,
							totalWithdrawals: 0,
							totalHolds: 0,
							totalShareRebates: 0,
							transactions: [],
							page: 0,
							size: 10,
							totalCount: 0,
							error: this.props.transactionError
								? this.props.transactionError
								: "Server error. Please try again.",
						});
					}
				}
			});
	};

	toggleReactivateUser = (isOpen) => () => {
		this.setState({
			isReactivateConfirmationDialog: isOpen,
			reactivateError: false,
			reactivateErrorMessage: "",
		});
	}

	reactivateUser = () => {
		this.props.reactivateUser(this.state.id).then(() => {
			if (!this.props.isOtherPlayerError) {
				this.setState({
					isReactivateConfirmationDialog: false,
					reactivateError: false,
					reactivateErrorMessage: "",
					enabled: true,
					snackbarMessage: "User reactivated successfully",
					snackbarSeverity: "success",
					openSnackbar: true,
				});
			} else {
				if (this.props.isOtherPlayerError === "401") {
					this.setState({
						isReactivateConfirmationDialog: false,
						reactivateError: false,
						reactivateErrorMessage: "",
						snackbarMessage: "Session expired. Logging out.",
						snackbarSeverity: "error",
						openSnackbar: true,
					},
					() => {
						setTimeout(() => {
							this.props.handleLogout();
						}, 1000);
					});
				} else {
					this.setState({
						reactivateError: true,
						reactivateErrorMessage: this.props.otherPlayerError,
					});
				}
			}
		});
	};

	toggleDeactivateUser = (isOpen) => () => {
		this.setState({
			isDeactivateConfirmationDialog: isOpen,
			deactivateError: false,
			deactivateErrorMessage: "",
		});
	};

	deactivateUser = () => {
		this.props.deactivateUser(this.state.id).then(() => {
			if (!this.props.isOtherPlayerError) {
				this.setState({
					isDeactivateConfirmationDialog: false,
					deactivateError: false,
					deactivateErrorMessage: "",
					enabled: false,
					snackbarMessage: "User deactivated successfully",
					snackbarSeverity: "success",
					openSnackbar: true,
				});
			} else {
				if (this.props.isOtherPlayerError === "401") {
					this.setState({
						isDeactivateConfirmationDialog: false,
						deactivateError: false,
						deactivateErrorMessage: "",
						snackbarMessage: "Session expired. Logging out.",
						snackbarSeverity: "error",
						openSnackbar: true,
					},
					() => {
						setTimeout(() => {
							this.props.handleLogout();
						}, 1000);
					});
				} else {
					this.setState({
						deactivateError: true,
						deactivateErrorMessage: this.props.otherPlayerError,
					});
				}
			}
		});
	};

	toggleDeleteUser = (isOpen) => () => {
		this.setState({
			isDeleteConfirmationDialog: isOpen,
			deleteError: false,
			deleteErrorMessage: "",
		});
	};

	deleteUser = () => {
		this.props.deleteUser(this.state.id).then(() => {
			if (!this.props.isOtherPlayerError) {
				this.setState({
					isDeleteConfirmationDialog: false,
					deleteError: false,
					deleteErrorMessage: "",
					enabled: false,
					snackbarMessage: "User deleted successfully",
					snackbarSeverity: "success",
					openSnackbar: true,
				},
				() => {
					setTimeout(() => {
						this.props.history.push("/my-players");
					}, 1000);
				});
			} else {
				if (this.props.isOtherPlayerError === "401") {
					this.setState({
						isDeleteConfirmationDialog: false,
						deleteError: false,
						deleteErrorMessage: "",
						snackbarMessage: "Session expired. Logging out.",
						snackbarSeverity: "error",
						openSnackbar: true,
					},
					() => {
						setTimeout(() => {
							this.props.handleLogout();
						}, 1000);
					});
				} else {
					this.setState({
						deleteError: true,
						deleteErrorMessage: this.props.otherPlayerError,
					});
				}
			}
		});
	};

	handleFormChange = (event) => {
		const { name, value } = event.target;
		this.setState({
			[name]: value,
		});
	};

	handleChangePage = (event, newPage) => {
		this.setState(
			{
				page: newPage,
			},
			() => {
				this.getTransactionsByUserId();
			}
		);
	};

	handleChangeRowsPerPage = (event) => {
		this.setState(
			{
				size: event.target.value ? parseInt(event.target.value) : 10,
				page: 0,
			},
			() => {
				this.getTransactionsByUserId();
			}
		);
	};

	handleCloseSnackbar = () => {
		this.setState({
			openSnackbar: false,
		});
	};

	render() {
		return (
			<div className="container">
				<Notification
					open={this.state.openSnackbar}
					onClose={this.handleCloseSnackbar}
					message={this.state.snackbarMessage}
					severity={this.state.snackbarSeverity}
				/>
				<ConfirmationDialog
					isOpen={this.state.isReactivateConfirmationDialog}
					title="Reactivate User"
					message="Are you sure you want to reactivate this user?"
					onOk={this.reactivateUser}
					onClose={this.toggleReactivateUser}
					error={this.state.reactivateError}
					errorMessage={this.state.reactivateErrorMessage}
				/>
				<ConfirmationDialog
					isOpen={this.state.isDeactivateConfirmationDialog}
					title="Deactivate User"
					message="Are you sure you want to deactivate this user?"
					onOk={this.deactivateUser}
					onClose={this.toggleDeactivateUser}
					error={this.state.deactivateError}
					errorMessage={this.state.deactivateErrorMessage}
				/>
				<ConfirmationDialog
					isOpen={this.state.isDeleteConfirmationDialog}
					title="Delete User"
					message="Are you sure you want to delete this user? You can no longer undo this once deleted."
					onOk={this.deleteUser}
					onClose={this.toggleDeleteUser}
					error={this.state.deleteError}
					errorMessage={this.state.deleteErrorMessage}
				/>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<HeaderWithBackButton
							title="User Details"
							subTitle=""
							buttonLabel="Back"
						/>
					</Grid>
					<Grid item xs={12} className="bg-base">
						<UserUpdate
							firstName={this.state.firstName}
							middleName={this.state.middleName}
							lastName={this.state.lastName}
							birthDate={this.state.birthDate}
							address={this.state.address}
							emailAddress={this.state.emailAddress}
							mobileNumber={this.state.mobileNumber}
							shareRebatePercentage={
								this.state.shareRebatePercentage
							}
							roleId={this.state.roleId}
							username={this.state.username}
							userRoleId={this.props.roleId}
							handleUpdate={this.handleUpdate}
							handleChange={this.handleFormChange}
							isLoading={this.state.isUpdateLoading}
							enabled={this.state.enabled}
							toggleReactivateUser={this.toggleReactivateUser}
							toggleDeactivateUser={this.toggleDeactivateUser}
							toggleDeleteUser={this.toggleDeleteUser}
						/>
					</Grid>
					<Grid item xs={12}>
						<br />
						<hr />
						<h4 style={{ color: "#FFFFFF" }}>
							Transaction Details
						</h4>
					</Grid>
					<Grid item xs={12}>
						<BalanceSummary
							totalBalance={this.state.totalBalance}
							totalDeposits={this.state.totalDeposits}
							totalWithdrawals={this.state.totalWithdrawals}
							totalHolds={this.state.totalHolds}
							totalShareRebates={this.state.totalShareRebates}
						/>
					</Grid>
					<Grid item xs={12}>
						<TransactionsList
							transactions={this.state.transactions}
							totalCount={this.state.totalCount}
							page={this.state.page}
							size={this.state.size}
							handleChangePage={this.handleChangePage}
							handleChangeRowsPerPage={
								this.handleChangeRowsPerPage
							}
						/>
					</Grid>
				</Grid>
			</div>
		);
	}
}

const mapStatetoProps = ({ user, otherPlayer, register, transaction }) => {
	const { roleId, code, shareRebatePercentage } = user;
	const {
		otherUserProfile,
		otherShareRebatePercentage,
		otherPlayerError,
		isOtherPlayerError,
	} = otherPlayer;
	const { updateError, isUpdateError } = register;
	const {
		totalBalance,
		totalDeposits,
		totalWithdrawals,
		totalHolds,
		totalShareRebates,
		transactions,
		totalCount,
		transactionError,
		isTransactionError,
	} = transaction;

	return {
		roleId,
		code,
		shareRebatePercentage,
		otherUserProfile,
		otherShareRebatePercentage,
		otherPlayerError,
		isOtherPlayerError,
		updateError,
		isUpdateError,
		// transaction
		totalBalance,
		totalDeposits,
		totalWithdrawals,
		totalHolds,
		totalShareRebates,
		transactions,
		totalCount,
		transactionError,
		isTransactionError,
	};
};

export default withRouter(connect(mapStatetoProps, {
	getOtherPlayerById,
	updateUser,
	getTransactionsByUserId,
	reactivateUser,
	deactivateUser,
	deleteUser,
})(UserDetailsPage));
