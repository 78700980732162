/**
 * User Update Component
 *
 * @author EverardOnggon
 */

import React from "react";
import { useHistory } from "react-router-dom";
import {
	Grid,
	Button,
	TextField,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { role } from "../../constants/User";

const useStyles = makeStyles((theme) => ({
	root: {
		"& > *": {
			margin: theme.spacing(0.25),
		},
	},
}));

const ValidationTextField = withStyles({
	root: {
		width: "100%",
		marginBottom: "0.75rem !important",
		"& input:focus:valid + fieldset": {
			borderColor: "green",
			borderWidth: 2,
		},
		"& input:focus:invalid + fieldset": {
			borderColor: "red",
			borderWidth: 2,
		},
		"& input:valid:focus + fieldset": {
			padding: "4px !important",
		},
	},
})(TextField);

const UserUpdate = ({
	firstName,
	middleName,
	lastName,
	birthDate,
	address,
	emailAddress,
	mobileNumber,
	shareRebatePercentage,
	roleId,
	username,
	userRoleId,
	handleUpdate,
	handleChange,
	handleFileUpload,
	message,
	error,
	isLoading,
	enabled,
	toggleReactivateUser,
	toggleDeactivateUser,
	toggleDeleteUser,
}) => {
	const classes = useStyles();
	const history = useHistory();

	let roleSelectionStr = '{"roles": []}';
	let roleObj = JSON.parse(roleSelectionStr);
	roleObj["roles"].push({ id: 0, text: "---"});

	// if (userRoleId === role.SUB_AGENT) {
	// 	roleObj["roles"].push({ id: `${role.PLAYER}`, text: "Player" });
	// }

	// if (userRoleId === role.AGENT) {
		roleObj["roles"].push({ id: `${role.PLAYER}`, text: "Player" });
		roleObj["roles"].push({ id: `${role.SUB_AGENT}`, text: "Sub Agent" });
	// }

	// if (userRoleId === role.SUPER_AGENT) {
		roleObj["roles"].push({ id: `${role.AGENT}`, text: "Agent" });
	// }

	// if (userRoleId === role.ADMIN) {
		roleObj["roles"].push({
			id: `${role.SUPER_AGENT}`,
			text: "Master Agent",
		});
		roleObj["roles"].push({ id: `${role.SUPER_SUB_AGENT}`, text: "Master-Sub Agent" });
		roleObj["roles"].push({ id: `${role.OPERATOR}`, text: "Operator" });
		roleObj["roles"].push({ id: `${role.ADMIN}`, text: "Admin" });
		roleObj["roles"].push({ id: `${role.TECHNICAL_SUPPORT}`, text: "Technical Support"});
		roleObj["roles"].push({ id: `${role.EQUALIZER}`, text: "Equalizer"});
	// }

	return (
		<div>
			<form
				onSubmit={handleUpdate}
				className="bg-base"
				style={{ padding: "20px 10px", margin: "20px 10px" }}
			>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={12} md={6}>
						<div>
							<ValidationTextField
								variant="outlined"
								required
								id="firstName"
								label=" / Nombre"
								className="iron-form-input-wrap"
								type="text"
								name="firstName"
								value={firstName}
								onChange={handleChange}
							/>
						</div>
						<div>
							<ValidationTextField
								variant="outlined"
								required
								id="lastName"
								label="Last Name / Apellido"
								className="iron-form-input-wrap"
								type="text"
								name="lastName"
								value={lastName}
								onChange={handleChange}
							/>
						</div>
						<div>
							<ValidationTextField
								variant="outlined"
								required
								id="birthDate"
								label="Date of Birth / Fecha de nacimiento"
								className="iron-form-input-wrap"
								type="date"
								name="birthDate"
								onChange={handleChange}
								value={birthDate}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</div>
						<div>
							<ValidationTextField
								variant="outlined"
								id="username"
								label="Home Address / Nombre de usuario"
								className="iron-form-input-wrap"
								type="text"
								name="username"
								value={username}
								disabled
							/>
						</div>
						<div>
							<ValidationTextField
								variant="outlined"
								id="password"
								label="Password / Contraseña (only set this if you want to override the password)"
								className="iron-form-input-wrap"
								type="password"
								name="password"
								onChange={handleChange}
							/>
						</div>
						<div>
							<ValidationTextField
								variant="outlined"
								id="confirmPassword"
								label="Confirm Password / Confirmar Contraseña"
								className="iron-form-input-wrap"
								type="password"
								name="confirmPassword"
								onChange={handleChange}
							/>
						</div>
					</Grid>
					<Grid item xs={12} sm={12} md={6}>
						<div>
							<ValidationTextField
								variant="outlined"
								required
								id="address"
								label="Home Address / Direccion de casa"
								className="iron-form-input-wrap"
								type="text"
								name="address"
								value={address}
								onChange={handleChange}
							/>
						</div>
						<div>
							<ValidationTextField
								variant="outlined"
								id="emailAddress"
								label="Email Address / Correo electrónico"
								className="iron-form-input-wrap"
								type="email"
								name="emailAddress"
								value={emailAddress ? emailAddress : ""}
								onChange={handleChange}
							/>
						</div>
						<div>
							<ValidationTextField
								variant="outlined"
								id="mobileNumber"
								label="Mobile Number / Teléfono móvil"
								className="iron-form-input-wrap"
								type="text"
								name="mobileNumber"
								value={mobileNumber}
								onChange={handleChange}
							/>
						</div>
						{roleId !== role.ADMIN ? (
							<div>
								<ValidationTextField
									variant="outlined"
									id="shareRebatePercentage"
									label="Share Rebate / Reembolso de acciones %"
									className="iron-form-input-wrap"
									type="text"
									name="shareRebatePercentage"
									value={shareRebatePercentage}
									onChange={handleChange}
								/>
							</div>
						) : null}
						<div>
							<FormControl
								style={{
									width: "100%",
									marginBottom: "0.75rem",
								}}
							>
								<InputLabel
									id="label-role-id"
									style={{ marginLeft: 14 }}
								>
									Role*
								</InputLabel>
								<Select
									variant="outlined"
									labelId="label-role-id"
									id="roleId"
									name="roleId"
									required
									value={roleId}
									onChange={handleChange}
									disabled
								>
									{roleObj["roles"].map((role, index) => (
										<MenuItem value={role.id} key={index}>
											{role.text}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</div>
					</Grid>
					<Grid item xs={12} style={{ textAlign: "center" }}>
						<div className={classes.root}>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								size="medium"
								className="button btn-primary"
								disabled={isLoading}
							>
								Save
							</Button>
							<Button
								variant="contained"
								color="secondary"
								size="medium"
								onClick={() => {
									history.goBack();
								}}
								className="button btn-base"
							>
								Close
							</Button>
						</div>
					</Grid>
					<Grid item xs={12}>
						{enabled ? (
							<Alert severity="warning" action={<Button className="button btn-warning" onClick={toggleDeactivateUser(true)}>Deactivate User</Button>}>
								<AlertTitle>Warning</AlertTitle>
								You can only DEACTIVATE this user if he/she has no remaining balance and no user/s under him/her (in the case of Admin, Master Agent, Agent, and Sub Agent).<br />
								Once deactivated, this user can no longer login his/her account. But you can still ACTIVATE his/her account anytime.
							</Alert>
						) : (
							<Alert severity="info" action={<Button className="button btn-info" onClick={toggleReactivateUser(true)}>Activate User</Button>}>
								<AlertTitle>Activate</AlertTitle>
								You can ACTIVATE this user anytime.<br />
								Once activated, this user can login his/her account again.
							</Alert>
						)}
					</Grid>
					<Grid item xs={12}>
						<Alert severity="error" action={<Button className="button btn-danger" onClick={toggleDeleteUser(true)}>Delete User</Button>}>
							<AlertTitle>Danger zone</AlertTitle>
							You can only DELETE this user if he/she has no remaining balance and no user/s under him/her (in the case of Admin, Master Agent, Agent, and Sub Agent).<br />
							Once this user is deleted, you can no longer undo it.
						</Alert>
					</Grid>
				</Grid>
			</form>
		</div>
	);
};

export { UserUpdate };
