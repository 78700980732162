/**
 * Login Page
 *
 * @author EverardOnggon
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import {
	Grid,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Typography,
} from "@material-ui/core";
import { Login } from "../../components";
// import LoginImg from "../../assets/images/PitMasters_logo.jpg";
// import LoginImg from "../../assets/images/SabongAsia.jpg";
import LoginImg from "../../assets/images/sabungan_logo.png";
import BgImg from "../../assets/images/steel.jpg";
// import SubLoginImg from "../../assets/images/sub_image_20201022.jpg";
// import SubLoginImg from "../../assets/images/bakbakan.png";
import LicenseImg from "../../assets/images/license.png";

class LoginPage extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			openDialog: false,
		};
	}

	componentDidMount() {
		this._isMounted = true;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	toggleDialog = (isOpen) => () => {
		this.setState({
			openDialog: isOpen,
		});
	};

	render() {
		return (
			<div className="bg-steel"
			style={{ backgroundImage: `url(${BgImg})` }}>
				<div className="section-pad">
					<div className="container">
						{/* Temporary Dialog - START */}
						{/* <Dialog
							open={true}
							fullWidth={true}
							maxWidth="lg"
						>
							<DialogContent>
								<h3 style={{textAlign: 'center'}}>
									The website is currently under maintenance.<br/>We're very sorry for the inconvenience.<br />We will be back soon.
								</h3>
							</DialogContent>
						</Dialog> */}
						{/* Temporary Dialog - END */}
						<Dialog
							open={this.state.openDialog}
							onClose={this.toggleDialog(false)}
							aria-labelledby="create-user-dialog-title"
							fullWidth={true}
							maxWidth="sm"
						>
							<DialogTitle
								id="create-user-dialog-title"
								style={{ textAlign: "center" }}
							>
								Contact Us
							</DialogTitle>
							<DialogContent style={{ textAlign: "center" }}>
								<Typography variant="h5">
									Email us at sabonglive@gmail.com
								</Typography>
								<Typography variant="h5">
									Or call/text us at +639770668676
								</Typography>
							</DialogContent>
							<DialogActions>
								<Button
									type="button"
									className="button btn-primary"
									onClick={this.toggleDialog(false)}
									style={{ margin: "0 auto" }}
								>
									OK
								</Button>
							</DialogActions>
						</Dialog>
						<Grid container spacing={0}>
							<Grid
								item
								xs={12}
								sm={12}
								md={11}
								lg={11}
								className="mx-auto"
							>
								<Grid
									container
									spacing={3}
									className="d-flex justify-content-center align-items-center"
								>
									<Grid item xs={12} sm={12} md={6} lg={6}>
										<img src={LoginImg} alt="LoginImg" />
										{/* <img
											src={SubLoginImg}
											alt="SubLoginImg"
											className="mt-25"
										/> */}
									</Grid>
									<Grid item xs={12} sm={12} md={6} lg={6}>
										<div className="form-margin bg-base p-sm-25 py-20 px-15 rounded" style={{ color: "#fff", backgroundColor: "#494B49"}}>
											<Login
												onLogin={this.props.onLogin}
												handleChange={
													this.props.handleChange
												}
												error={this.props.error}
												toggleDialog={this.toggleDialog}
												isLoading={this.props.isLoading}
											/>
										</div>
									</Grid>
								</Grid>
								{/* <Grid
									container
									spacing={3}
									className="d-flex justify-content-center align-items-center"
								>
									<Grid item xs={12} sm={12} md={6} lg={6}>
										<img src={LicenseImg} alt="LicenseImg" />
									</Grid>
								</Grid> */}
							</Grid>
						</Grid>
					</div>
				</div>
			</div>
		);
	}
}

const mapStatetoProps = ({ auth }) => {
	const { AccessToken, TokenExpiration, RefreshToken } = auth;

	return {
		AccessToken,
		TokenExpiration,
		RefreshToken,
	};
};

export default connect(mapStatetoProps, {})(LoginPage);
