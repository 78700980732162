/**
 * Register Reducer
 *
 * @author EverardOnggon
 */

import {
	AUTH_LOGOUT_SUCCESS,
	AUTH_LOGOUT_FAILED,
	REGISTER_SUCCESS,
    REGISTER_FAILED,
	REGISTER_USER_EXISTS,
	CREATE_USER_SUCCESS,
	CREATE_USER_FAILED,
	CREATE_USER_EXISTS,
	UPDATE_USER_SUCCESS,
	UPDATE_USER_FAILED,
} from "../actions/types";

const INITIAL_STATE = {
	registerError: "",
	updateError: "",
	isRegisterError: false,
	isUpdateError: false,
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case AUTH_LOGOUT_SUCCESS:
		case AUTH_LOGOUT_FAILED:
            return {
                state: undefined,
            };
		case REGISTER_SUCCESS:
		case CREATE_USER_SUCCESS:
			return {
                ...state,
				registerError: "",
				isRegisterError: false,
			};
		case REGISTER_FAILED:
        case REGISTER_USER_EXISTS:
		case CREATE_USER_FAILED:
		case CREATE_USER_EXISTS:
			return {
				...state,
				registerError: action.error,
				isRegisterError: true,
			};
		case UPDATE_USER_SUCCESS:
			return {
				...state,
				updateError: "",
				isUpdateError: false,
			};
		case UPDATE_USER_FAILED:
			return {
				...state,
				updateError: action.error,
				isUpdateError: true,
			};
		default:
			return state;
	}
}
