/**
 * Dragon Tails Scoreboard Component
 *
 * @author EverardOnggon
 */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
	Paper,
	Grid,
} from "@material-ui/core";
import { game } from "../../../constants/Game";
import DragonTailsScoreboardRow from "./DragonTailsScoreboardRow";

const useStyles = makeStyles((theme) => ({
	blackPaperBackground: {
		backgroundColor: "#000",
		color: "#ffffff",
		borderRadius: 3,
		maxWidth: "calc(100vw - 30px)",
		overflowX: "scroll",
	},
	table: {
		padding: 5,
		height: 180,
		maxWidth: "100%",
	},
}));

const DragonTailsScoreboard = ({ fights }) => {
    const styles = useStyles();
    const limit = 6;
    
    let results = [];
    let row = 0;
    let col = 0;
    let prevResult = 0;
    let sequenceCount = 0;
    let newLimit = limit;

    for (let i = 0; i < fights.length; i++) {
        let currentResult = game.CANCELLED;

        if (fights[i].result) {
            if (fights[i].result.cancel) {
                currentResult = game.CANCELLED;
            } else if (fights[i].result.draw) {
                currentResult = game.CANCELLED;
            } else {
                currentResult = fights[i].result.winningBetPayout.betOption.id;
            }
        }

        if (prevResult === 0) {
            results[row] = [];
            sequenceCount++;
            prevResult = currentResult;
        } else if (prevResult === currentResult || currentResult === game.CANCELLED ) {
            sequenceCount++;
            if (sequenceCount > newLimit) {
                if (col === (limit - 1)) {
                    row++;
                } else {
                    // check for double dragon
                    if (!results[row][col+1]) {
                        if (!results[row-1][col+1]) {
                            if (col < (limit - 1)) {
                                col++;
                            } else {
                                row++;
                            }
                            if (newLimit < limit) {
                                newLimit++;
                            }
                        } else {
                            // check if result of the other tail is the same or not
                            let prevTailFight = results[row-1][col+1];
                            let resultToCheck = currentResult;
                            if (currentResult === game.CANCELLED) {
                                resultToCheck = prevResult;
                            }

                            if (prevTailFight.tailResult === resultToCheck) {
                                row++;
                            } else {
                                if (col < (limit - 1)) {
                                    col++;
                                } else {
                                    row++;
                                }
                                if (newLimit < limit) {
                                    newLimit++;
                                }
                            }
                        }
                    } else {
                        row++;
                    }
                }

                if (!results[row]) {
                    results[row] = [];
                }
            } else {
                if (!results[row][col + 1]) {
                    col++;
                } else {
                    row++;
                    if (!results[row]) {
                        results[row] = [];
                    }
                    newLimit = col + 1;
                }
            }
        } else if (prevResult !== currentResult) {
            col = 0;
            if (sequenceCount > newLimit) {
                if (newLimit > 1) {
                    row = row - (sequenceCount - newLimit - 1);
                } else {
                    row++;
                }
            } else {
                row++;
            }
            sequenceCount = 1;
            if (!results[row]) {
                results[row] = [];
            }

            // update new limit here
            for (let l = newLimit; l <= limit; l++) {
                if (!results[row][l-1]) {
                    newLimit = l;
                } else {
                    break;
                }
            }
        }

        // set tail result for future checking
        if (currentResult !== game.CANCELLED) {
            fights[i].tailResult = currentResult;
        } else {
            fights[i].tailResult = prevResult;
        }
		results[row][col] = fights[i]; //currentResult;
        
        if (currentResult !== game.CANCELLED) {
            prevResult = currentResult; // update previous result with the current result
        }
    }

    // Populate with marker data the blank items.
    for (let i = 0; i < results.length; i++) {
        for (let j = 0; j < limit; j++) {
            if (!results[i][j]) {
                results[i][j] = 0;
            }
        }
    }

    return (
		<Paper className={styles.blackPaperBackground}>
			<Grid container>
				<Grid item xs={12}>
					<table className={styles.table}>
                        <tbody>
                            <tr>
                                {results.map((items, index) => {
                                    return (
                                        <DragonTailsScoreboardRow
                                            fights={items}
                                            key={index}
                                        />
                                    )
                                })}
                            </tr>
                        </tbody>
					</table>
				</Grid>
			</Grid>
		</Paper>
	);
};

export { DragonTailsScoreboard };
