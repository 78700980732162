/**
 * Fight Result Override Component
 *
 * @author EverardOnggon
 */

import React from "react";
import { Grid, Paper, FormControl, InputLabel, Select, Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { game } from "../../../constants/Game";

const useStyles = makeStyles((theme) => ({
    paperBackground: {
        padding: "20px",
        borderRadius: 0,
    },
}));

const FightResultOverride = ({eventFights, fightNoToOverride, handleFightNoSelect, newFightResult, handleNewFightResult, handleFightResultOverride}) => {
    const styles = useStyles();

	return (
		<div>
            <Paper className={styles.paperBackground}>
				<Grid
					container
					spacing={2}
					className="d-flex justify-content-center align-items-center"
				>
					<Grid item xs={12}>
						<h4>Fight Result Override</h4>
                        <p>You can change the result of a specific fight here of the current event.</p>
                        <Alert severity="warning">
							This feature serves as the last resort. It is highly advisable to not use this as much as possible since it affects money transactions.
						</Alert>
					</Grid>
					<Grid item xs={12}>
                        <FormControl>
                            <InputLabel htmlFor="event-fight-number">Fight #</InputLabel>
                            <Select
                                native
                                value={fightNoToOverride}
                                onChange={handleFightNoSelect}
                                inputProps={{
                                    name: 'fightNo',
                                    id: 'event-fight-number'
                                }}
                            >
                                <option key={0} aria-label="---" value="" />
                                {eventFights.map((row, index) => {
                                    let result = "";
                                    if (row.result) {
                                        if (row.result.cancel) {
                                            result = game.FIGHT_RESULT[game.CANCELLED];
                                        } else if (row.result.draw) {
                                            result = game.FIGHT_RESULT[game.DRAW];
                                        } else {
                                            result = game.FIGHT_RESULT[row.result.winningBetPayout.betOption.id];
                                        }
                                    }
                                    return (
                                        <option key={index + 1} value={row.id}>{row.fightNo} | Result: {result}</option>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <FormControl>
                            <InputLabel htmlFor="event-fight-new-result">New Result</InputLabel>
                            <Select
                                native
                                value={newFightResult}
                                onChange={handleNewFightResult}
                                inputProps={{
                                    name: 'newFightResult',
                                    id: 'event-fight-new-result'
                                }}
                            >
                                <option aria-label="---" value="" />
                                <option value={game.MERON}>{game.FIGHT_RESULT[game.MERON]}</option>
                                <option value={game.WALA}>{game.FIGHT_RESULT[game.WALA]}</option>
                                <option value={game.DRAW}>{game.FIGHT_RESULT[game.DRAW]}</option>
                                <option value={game.CANCELLED}>{game.FIGHT_RESULT[game.CANCELLED]}</option>
                            </Select>
                        </FormControl>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <FormControl>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleFightResultOverride}
                                style={{marginTop: 10}}
                            >
                                Override
                            </Button>
                        </FormControl>
					</Grid>
				</Grid>
            </Paper>
		</div>
	);
};

export { FightResultOverride };
