/**
 * Player Actions
 *
 * @author EverardOnggon
 */

import axios from "axios";
import {
	API_URL,
	GET_PLAYERS_LIST_SUCCESS,
	GET_PLAYERS_LIST_FAILED,
	GET_PLAYER_BALANCE_SUCCESS,
	GET_PLAYER_BALANCE_FAILED,
	GET_BET_SUMMARY_SUCCESS,
	GET_BET_SUMMARY_FAILED,
	MAKE_PLAYER_BET_SUCCESS,
	MAKE_PLAYER_BET_FAILED
} from "./types";
import { getHeaders } from "./AuthActions";

export const getPlayersListByAgentId = (
	agentId,
	page,
	size,
	searchNameKey
) => async (dispatch) => {
	try {
		let url = searchNameKey
			? `${API_URL}/player/under/${agentId}?page=${page}&size=${size}&searchNameKey=${searchNameKey}`
			: `${API_URL}/player/under/${agentId}?page=${page}&size=${size}`;
		const response = await axios.get(url, await getHeaders());
		if (response.status === 200) {
			dispatch({
				type: GET_PLAYERS_LIST_SUCCESS,
				data: response.data,
			});
		}
	} catch (error) {
		switch (error.response.status) {
			case 401:
				dispatch({
					type: GET_PLAYERS_LIST_FAILED,
					error: error.response.status.toString(),
				});
				break;
			default:
				dispatch({
					type: GET_PLAYERS_LIST_FAILED,
					error: error.response.data.message,
				});
		}
	}
};

export const getBalanceByPlayerId = (playerId) => async (dispatch) => {
	try {
		const response = await axios.get(
			`${API_URL}/player/${playerId}/balance`,
			await getHeaders()
		);
		if (response.status === 200) {
			dispatch({
				type: GET_PLAYER_BALANCE_SUCCESS,
				data: response.data,
			});
		}
	} catch (error) {
		switch (error.response.status) {
			case 401:
				dispatch({
					type: GET_PLAYER_BALANCE_FAILED,
					error: error.response.status.toString(),
				});
				break;
			default:
				dispatch({
					type: GET_PLAYER_BALANCE_FAILED,
					error: error.response.data.message,
				});
		}
	}
};

export const getBetSummaryByFightIdAndPlayerId = (fightId, playerId) => async (
	dispatch
) => {
	try {
		const response = await axios.get(
			`${API_URL}/txn/bet/fight/${fightId}/${playerId}`,
			await getHeaders()
		);
		if (response.status === 200 || response.status === 201) {
			dispatch({
				type: GET_BET_SUMMARY_SUCCESS,
				data: response.data,
			});
		}
	} catch (error) {
		switch (error.response.status) {
			case 401:
				dispatch({
					type: GET_BET_SUMMARY_FAILED,
					error: error.response.status.toString(),
				});
				break;
			default:
				dispatch({
					type: GET_BET_SUMMARY_FAILED,
					error: error.response.data.message,
				});
		}
	}
};
export const makePlayerBet = (playerId, payload) => async (dispatch) => {
	try {
		const response = await axios.post(
			`${API_URL}/player/bet/${playerId}`,
			payload,
			await getHeaders()
		);
		if (response.status === 200 || response.status === 201) {
			dispatch({
				type: MAKE_PLAYER_BET_SUCCESS,
				data: response.data,
			});
		}
	} catch (error) {
		switch (error.response.status) {
			case 401:
				dispatch({
					type: MAKE_PLAYER_BET_FAILED,
					error: error.response.status.toString(),
				});
				break;
			default:
				dispatch({
					type: MAKE_PLAYER_BET_FAILED,
					error: error.response.data.message,
				});
		}
	}
};

export const getUsersList = (
	agentId,
	page,
	size,
	searchNameKey
) => async (dispatch) => {
	try {
		let url = searchNameKey
			? `${API_URL}/player/under/${agentId}?page=${page}&size=${size}&generation=3&searchNameKey=${searchNameKey}`
			: `${API_URL}/player/under/${agentId}?page=${page}&size=${size}&generation=3`;
		const response = await axios.get(url, await getHeaders());
		if (response.status === 200) {
			dispatch({
				type: GET_PLAYERS_LIST_SUCCESS,
				data: response.data,
			});
		}
	} catch (error) {
		switch (error.response.status) {
			case 401:
				dispatch({
					type: GET_PLAYERS_LIST_FAILED,
					error: error.response.status.toString(),
				});
				break;
			default:
				dispatch({
					type: GET_PLAYERS_LIST_FAILED,
					error: error.response.data.message,
				});
		}
	}
};