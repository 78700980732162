/**
 * Report Filter Component
 *
 * @author EverardOnggon
 */

import React from "react";
import { Grid, TextField, Button, MenuItem } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import FilterListIcon from '@material-ui/icons/FilterList';

const ReportFilter = ({handleDateFilterChange, handleFilter, users, excludedPlayers, handleUsernamesChange}) => {
    return (
		<div>
			<Grid
				container
				spacing={1}
				className="d-flex justify-content-center align-items-center"
			>
				<Grid item xs={12} sm={5}>
                    <TextField
                        id="dateFrom"
                        name="dateFrom"
                        label="Date From"
                        type="date"
                        variant="filled"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{backgroundColor: "#FFFFFF", width: "49%"}}
                        onChange={handleDateFilterChange}
                    />
                    &nbsp;
                    <TextField
                        id="dateTo"
                        name="dateTo"
                        label="Date To"
                        type="date"
                        variant="filled"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{backgroundColor: "#FFFFFF", width: "49%"}}
                        onChange={handleDateFilterChange}
                    />
                </Grid>
                <Grid item xs={8} sm={5}>
                    {/* <TextField
                        id="excludedPlayers"
                        name="excludedPlayers"
                        select
                        variant="filled"
                        label="Usernames"
                        SelectProps={{
                            multiple: true,
                            value: excludedPlayers,
                            onChange: handleUsernamesChange,
                        }}
                        style={{width: "100%", backgroundColor: "#FFFFFF"}}
                    >
                        {users.map((row, index) => {
                            return (
                                <MenuItem value={row.userProfile.username} key={index}>{row.userProfile.username}</MenuItem>
                            )
                        })}
                    </TextField> */}
                    <Autocomplete
                        multiple
                        autoSelect
                        limitTags={3}
                        id="excludedPlayers"
                        name="excludedPlayers"
                        options={users}
                        getOptionLabel={(option) => option.userProfile.username}
                        value={excludedPlayers}
                        style={{width: "100%", backgroundColor: "#FFFFFF"}}
                        renderInput={(params) => <TextField {...params} label="Usernames" variant="filled" />}
                        onChange={handleUsernamesChange}
                    />
                </Grid>
                <Grid item xs={4} sm={2}>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        style={{
                            height: "100%",
                            width: "100%",
                            maxHeight: "56px",
                            borderRadius: 0,
                        }}
                        onClick={handleFilter}
                    >
                        <FilterListIcon />&nbsp;Filter
                    </Button>
				</Grid>
			</Grid>
		</div>
	);
};

export { ReportFilter };
