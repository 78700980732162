/**
 * Support Tools Page
 *
 * @author EverardOnggon
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { Notification, ConfirmationDialog } from "../../components/Common";
import { getSystemConfig, updateSystemConfig, updateSystemConfigValue } from "../../actions/SystemConfigAction";
import { getCurrentEventByGameId, manuallyFinalizeFight, jackUpFight } from "../../actions/GameEventActions";
import { config } from "../../constants/Config";
import { game, gameId } from "../../constants/Game";
import { SystemLock } from "../../components/TechSupport/SystemLock";
import { AutoEqualize } from "../../components/TechSupport/AutoEqualize";
import { FightResultOverride } from "../../components/TechSupport/FightResultOverride";
import { JackUpFight } from "../../components/TechSupport/JackUpFight";

class SupportToolsPage extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
            systemConfigs: [],
            // System Lock/Unlock
            systemLockEnabled: false,
            isSystemEnableConfirmationDialog: false,
            // Auto Equalize Enable/Disable
            autoEqualizeEnabled: false,
            isAutoEqualizeConfirmationDialog: false,
            autoEqualizeAmount: 0,
            isAutoEqualizeAmountConfirmationDialog: false,
            jackUpTreshold: 0,
            isJackUpThresholdConfirmationDialog: false,
            // Fight Result Override
            eventFights: [],
            fightNoToOverride: "",
            fightNoToJackUp: "",
            newFightResult: "",
            isFightResultOverrideConfirmationDialog: false,
            isJackUpConfirmationDialog: false,
			// Snackbar
			openSnackbar: false,
			snackbarMessage: "",
			snackbarSeverity: "error",
		};
	}

	componentDidMount() {
        this._isMounted = true;

        this.getSystemConfig();
        this.getEventDetails();
	}

	componentWillUnmount() {
		this._isMounted = false;
    }

    /***
     * System Lock/Unlock
     */
    
    getSystemConfig = () => {
        this.props.getSystemConfig().then(() => {
            if (!this.props.isSystemConfigError && this.props.systemConfigs.length > 0) {
				this.setState({
					systemConfigs: this.props.systemConfigs,
                });
                this.props.systemConfigs.forEach(element => {
                    if (element.key === config.KEY_LOCK_TRANSACTIONS) {
                        this.setState({
                            systemLockEnabled: element.enabled,
                        });
                    }
                    if (element.key === config.KEY_AUTOMATIC_EQUALIZE) {
                        this.setState({
                            autoEqualizeEnabled: element.enabled,
                        });
                    }
                    if (element.key === config.KEY_AUTOMATIC_EQUALIZE_AMOUNT) {
                        this.setState({
                            autoEqualizeAmount: element.value,
                        });
                    }
                    if (element.key === config.KEY_JACK_UP_THRESHOLD) {
                        this.setState({
                            jackUpTreshold: element.value,
                        });
                    }
                });
			} else {
				if (this.props.systemConfigError === "401") {
					this.setState({
						// Snackbar
						openSnackbar: true,
						snackbarMessage: "Session expired. Logging out.",
						snackbarSeverity: "error",
					},
					() => {
						setTimeout(() => {
							this.props.handleLogout();
						}, 1000);
					});
				} else {
					this.setState({
						// Snackbar
						openSnackbar: true,
						snackbarMessage: this.props.systemConfigError,
						snackbarSeverity: "error",
						systemConfigs: [],
					});
				}
			}
        })
    }

    handleSystemLock = () => {
        let configId = null;
        this.state.systemConfigs.forEach(element => {
            if (element.key === config.KEY_LOCK_TRANSACTIONS) {
                configId = element.id;
            }
        });

        this.props.updateSystemConfig(configId, this.state.systemLockEnabled).then(() => {
            if (!this.props.isSystemConfigError && this.props.systemConfigs.length > 0) {
				this.setState({
                    isSystemEnableConfirmationDialog: false,
                    // Snackbar
					openSnackbar: true,
                    snackbarMessage: this.state.systemLockEnabled ? "System is locked" : "System is unlocked",
					snackbarSeverity: "success",
				});
			} else {
				if (this.props.systemConfigError === "401") {
					this.setState({
                        isSystemEnableConfirmationDialog: false,
						// Snackbar
						openSnackbar: true,
						snackbarMessage: "Session expired. Logging out.",
						snackbarSeverity: "error",
					},
					() => {
						setTimeout(() => {
							this.props.handleLogout();
						}, 1000);
					});
				} else {
					this.setState({
                        isSystemEnableConfirmationDialog: false,
						// Snackbar
						openSnackbar: true,
						snackbarMessage: this.props.systemConfigError,
						snackbarSeverity: "error",
					});
				}
			}
        })
    }

    handleAutoEqualize = () => {
        let configId = null;
        this.state.systemConfigs.forEach(element => {
            if (element.key === config.KEY_AUTOMATIC_EQUALIZE) {
                configId = element.id;
            }
        });

        this.props.updateSystemConfig(configId, this.state.autoEqualizeEnabled).then(() => {
            if (!this.props.isSystemConfigError && this.props.systemConfigs.length > 0) {
				this.setState({
                    isAutoEqualizeConfirmationDialog: false,
                    // Snackbar
					openSnackbar: true,
                    snackbarMessage: this.state.autoEqualizeEnabled ? "Auto Equalize is Enabled" : "Auto Equalize is Disabled",
					snackbarSeverity: "success",
				});
			} else {
				if (this.props.systemConfigError === "401") {
					this.setState({
                        isAutoEqualizeConfirmationDialog: false,
						// Snackbar
						openSnackbar: true,
						snackbarMessage: "Session expired. Logging out.",
						snackbarSeverity: "error",
					},
					() => {
						setTimeout(() => {
							this.props.handleLogout();
						}, 1000);
					});
				} else {
					this.setState({
                        isAutoEqualizeConfirmationDialog: false,
						// Snackbar
						openSnackbar: true,
						snackbarMessage: this.props.systemConfigError,
						snackbarSeverity: "error",
					});
				}
			}
        })
    }

    handleAutoEqualizeAmountOk = () => {
        let configId = null;
        this.state.systemConfigs.forEach(element => {
            if (element.key === config.KEY_AUTOMATIC_EQUALIZE_AMOUNT) {
                configId = element.id;
            }
        });

        this.props.updateSystemConfigValue(configId, this.state.autoEqualizeAmount).then(() => {
            if (!this.props.isSystemConfigError && this.props.systemConfigs.length > 0) {
				this.setState({
                    isAutoEqualizeAmountConfirmationDialog: false,
                    // Snackbar
					openSnackbar: true,
                    snackbarMessage: "Auto Equalize is Updated to " + this.state.autoEqualizeAmount,
					snackbarSeverity: "success",
				});
			} else {
				if (this.props.systemConfigError === "401") {
					this.setState({
                        isAutoEqualizeAmountConfirmationDialog: false,
						// Snackbar
						openSnackbar: true,
						snackbarMessage: "Session expired. Logging out.",
						snackbarSeverity: "error",
					},
					() => {
						setTimeout(() => {
							this.props.handleLogout();
						}, 1000);
					});
				} else {
					this.setState({
                        isAutoEqualizeAmountConfirmationDialog: false,
						// Snackbar
						openSnackbar: true,
						snackbarMessage: this.props.systemConfigError,
						snackbarSeverity: "error",
					});
				}
			}
        })
    }

    handleJackUpThresholdOk = () => {
        let configId = null;
        this.state.systemConfigs.forEach(element => {
            if (element.key === config.KEY_JACK_UP_THRESHOLD) {
                configId = element.id;
            }
        });

        this.props.updateSystemConfigValue(configId, this.state.jackUpTreshold).then(() => {
            if (!this.props.isSystemConfigError && this.props.systemConfigs.length > 0) {
				this.setState({
                    isJackUpThresholdConfirmationDialog: false,
                    // Snackbar
					openSnackbar: true,
                    snackbarMessage: "Jack Up Threshold Equalize is Updated to " + this.state.jackUpTreshold,
					snackbarSeverity: "success",
				});
			} else {
				if (this.props.systemConfigError === "401") {
					this.setState({
                        isJackUpThresholdConfirmationDialog: false,
						// Snackbar
						openSnackbar: true,
						snackbarMessage: "Session expired. Logging out.",
						snackbarSeverity: "error",
					},
					() => {
						setTimeout(() => {
							this.props.handleLogout();
						}, 1000);
					});
				} else {
					this.setState({
                        isJackUpThresholdConfirmationDialog: false,
						// Snackbar
						openSnackbar: true,
						snackbarMessage: this.props.systemConfigError,
						snackbarSeverity: "error",
					});
				}
			}
        })
    }

    toggleSystemLockEnableConfirmationDialog = (isOpen) => () => {
		this.setState({
            isSystemEnableConfirmationDialog: isOpen,
            systemLockEnabled: !isOpen ? !this.state.systemLockEnabled : this.state.systemLockEnabled, // revert system lock value if cancelled.
        });
    };

    toggleAutoEqualizeEnableConfirmationDialog = (isOpen) => () => {
		this.setState({
            isAutoEqualizeConfirmationDialog: isOpen,
            autoEqualizeEnabled: !isOpen ? !this.state.autoEqualizeEnabled : this.state.autoEqualizeEnabled,
        });
    };

    toggleAutoEqualizeAmountConfirmationDialog = (isOpen) => () => {
		this.setState({
            isAutoEqualizeAmountConfirmationDialog: isOpen
        });
    };

    toggleJackUpThresholdConfirmationDialog = (isOpen) => () => {
		this.setState({
            isJackUpThresholdConfirmationDialog: isOpen
        });
    };
    
    handleSystemLockChange = (event) => {
        this.setState({
            systemLockEnabled: event.target.checked,
            isSystemEnableConfirmationDialog: true,
        });
    }
    
    handleAutoEqualizeChange = (event) => {
        this.setState({
            autoEqualizeEnabled: event.target.checked,
            isAutoEqualizeConfirmationDialog: true,
        });
    }
    
    handleAutoEqualizeAmountChange = (event) => {
        this.setState({
            autoEqualizeAmount: event.target.value,
        });
    }
    
    handleAutoEqualizeAmountConfirmation = (event) => {
        this.setState({
            isAutoEqualizeAmountConfirmationDialog: true
        });
    }
    
    handleJackUpThresholdChange = (event) => {
        this.setState({
            jackUpTreshold: event.target.value
        });
    }
    
    handleJackUpThresholdConfirmation = (event) => {
        this.setState({
            isJackUpThresholdConfirmationDialog: true
        });
    }

    /***
     * Fight Result Override
     */
    getEventDetails = () => {
		if (this._isMounted) {
			this.props.getCurrentEventByGameId(gameId.COCK_FIGHT).then(() => {
				if (!this.props.isGameEventError) {
					this.setState({
						eventFights: this.props.currentEventDetails.totalFights > 0 ? this.props.currentEventDetails.fights : [],
					});
				} else {
                    if (this.props.gameEventError === "401") {
                        this.setState({
                            // Snackbar
                            openSnackbar: true,
                            snackbarMessage: "Session expired. Logging out.",
                            snackbarSeverity: "error",
                        },
                        () => {
                            setTimeout(() => {
                                this.props.handleLogout();
                            }, 1000);
                        });
                    } else {
                        this.setState({
                            eventFights: [],
                            // Snackbar
                            openSnackbar: true,
                            snackbarMessage: this.props.gameEventError,
                            snackbarSeverity: "error",
                        });
                    }
				}
			});
		}
    };
    
    handleFightNoSelect = (event) => {
        this.setState({
            fightNoToOverride: event.target.value,
        });
    };

    handleFightNoJackUpSelect = (event) => {
        this.setState({
            fightNoToJackUp: event.target.value,
        });
    };

    handleNewFightResult = (event) => {
        this.setState({
            newFightResult: event.target.value,
        });
    };

    toggleFightResultOverrideConfirmationDialog = (isOpen) => () => {
        this.setState({
            isFightResultOverrideConfirmationDialog: isOpen,
        });
    };

    toggleJackUpConfirmationDialog = (isOpen) => () => {
        this.setState({
            isJackUpConfirmationDialog: isOpen,
        });
    };

    handleFightResultOverride = () => {
        if (this.state.fightNoToOverride !== "" && this.state.newFightResult !== "") {
            this.setState({
                isFightResultOverrideConfirmationDialog: true,
            });
        } else {
            this.setState({
                // Snackbar
                openSnackbar: true,
                snackbarMessage: "Please select the fight number to override and it's corresponding new result.",
                snackbarSeverity: "error",
            });
        }
    };

    handleJackUp = () => {
        if (this.state.fightNoToJackUp !== "") {
            this.setState({
                isJackUpConfirmationDialog: true,
            });
        } else {
            this.setState({
                // Snackbar
                openSnackbar: true,
                snackbarMessage: "Please select the fight number to Jack Up.",
                snackbarSeverity: "error",
            });
        }
    };

    overrideFightResult = () => {
        if (this.state.fightNoToOverride !== "" && this.state.newFightResult !== "") {
            let payload = null;
            if (this.state.newFightResult == game.CANCELLED) {
                payload = {
                    "cancelled": true,
                    "draw": false,
                    "winningBetOption": 0,
                };
            } else {
                payload = {
                    "cancelled": false,
                    "draw": false,
                    "winningBetOption": this.state.newFightResult,
                };
            }
            this.props.manuallyFinalizeFight(this.state.fightNoToOverride, payload).then(() => {
                if (!this.props.isManualFinalizeFightError) {
                    this.setState({
                        isFightResultOverrideConfirmationDialog: false,
                        // Snackbar
                        openSnackbar: true,
                        snackbarMessage: "The fight is successfully overridden.",
                        snackbarSeverity: "success",
                    });
                } else {
                    if (this.props.manualFinalizeFightError === "401") {
                        this.setState({
                            isFightResultOverrideConfirmationDialog: false,
                            // Snackbar
                            openSnackbar: true,
                            snackbarMessage: "Session expired. Logging out.",
                            snackbarSeverity: "error",
                        },
                        () => {
                            setTimeout(() => {
                                this.props.handleLogout();
                            }, 1000);
                        });
                    } else {
                        this.setState({
                            isFightResultOverrideConfirmationDialog: false,
                            // Snackbar
                            openSnackbar: true,
                            snackbarMessage: this.props.manualFinalizeFightError,
                            snackbarSeverity: "error",
                        });
                    }
                }
            });
            this.setState({
                isFightResultOverrideConfirmationDialog: false,
            });
        } else {
            this.setState({
                // Snackbar
                openSnackbar: true,
                snackbarMessage: "Please select the fight number to override and it's corresponding new result.",
                snackbarSeverity: "error",
            });
        }
    };

    handleJackUpOk = () => {
        if (this.state.fightNoToJackUp !== "") {
            this.props.jackUpFight(this.state.fightNoToJackUp).then(() => {
                if (!this.props.isJackUpFightError) {
                    this.setState({
                        isJackUpConfirmationDialog: false,
                        // Snackbar
                        openSnackbar: true,
                        snackbarMessage: "The fight is successfully Jacked Up.",
                        snackbarSeverity: "success",
                    });
                } else {
                    if (this.props.jackUpFightError === "401") {
                        this.setState({
                            isJackUpConfirmationDialog: false,
                            // Snackbar
                            openSnackbar: true,
                            snackbarMessage: "Session expired. Logging out.",
                            snackbarSeverity: "error",
                        },
                        () => {
                            setTimeout(() => {
                                this.props.handleLogout();
                            }, 1000);
                        });
                    } else {
                        this.setState({
                            isJackUpConfirmationDialog: false,
                            // Snackbar
                            openSnackbar: true,
                            snackbarMessage: this.props.jackUpFightError,
                            snackbarSeverity: "error",
                        });
                    }
                }
            });
            this.setState({
                isJackUpConfirmationDialog: false,
            });
        } else {
            this.setState({
                // Snackbar
                openSnackbar: true,
                snackbarMessage: "Please select the fight number to Jack Up.",
                snackbarSeverity: "error",
            });
        }
    };

	handleCloseSnackbar = () => {
		this.setState({
			openSnackbar: false,
		});
	};

	render() {
		return (
			<div className="container">
				<Notification
					open={this.state.openSnackbar}
					onClose={this.handleCloseSnackbar}
					message={this.state.snackbarMessage}
					severity={this.state.snackbarSeverity}
				/>
                <ConfirmationDialog
					isOpen={this.state.isSystemEnableConfirmationDialog}
					title="Lock/Unlock System"
					message={this.state.systemLockEnabled ? "Are you sure you want to lock the system?" : "Are you sure you want to unlock the system"}
					onOk={this.handleSystemLock}
					onClose={this.toggleSystemLockEnableConfirmationDialog}
				/>
                <ConfirmationDialog
					isOpen={this.state.isAutoEqualizeConfirmationDialog}
					title="Auto Equalize Bets"
					message={this.state.autoEqualizeEnabled ? "Are you sure you want to enable auto equalize?" : "Are you sure you want to disable auto equalize?"}
					onOk={this.handleAutoEqualize}
					onClose={this.toggleAutoEqualizeEnableConfirmationDialog}
				/>
                <ConfirmationDialog
					isOpen={this.state.isAutoEqualizeAmountConfirmationDialog}
					title="Auto Equalize Bet Amount"
					message={"Are you sure you want to update Auto Equalize Amount?"}
                    onOk={this.handleAutoEqualizeAmountOk}
					onClose={this.toggleAutoEqualizeAmountConfirmationDialog}
				/>
                <ConfirmationDialog
					isOpen={this.state.isJackUpThresholdConfirmationDialog}
					title="Jack Up Threshold"
					message={"Are you sure you want to update Jack Up Threshold?"}
					onOk={this.handleJackUpThresholdOk}
					onClose={this.toggleAutoEqualizeAmountConfirmationDialog}
				/>
                <ConfirmationDialog
					isOpen={this.state.isJackUpConfirmationDialog}
					title="Jack Up"
					message={`Are you sure you want to Jack Up Current Fight with id : ${this.state.fightNoToJackUp}?`}
					onOk={this.handleJackUpOk}
					onClose={this.toggleJackUpConfirmationDialog}
				/>
                <ConfirmationDialog
					isOpen={this.state.isFightResultOverrideConfirmationDialog}
					title="Override Result"
					message={`Are you sure you want to override the result to ${game.FIGHT_RESULT[this.state.newFightResult]}?`}
					onOk={this.overrideFightResult}
					onClose={this.toggleFightResultOverrideConfirmationDialog}
				/>
				<Grid container spacing={2}>
					<Grid item xs={12}>
                        <SystemLock
                            systemLockEnabled={this.state.systemLockEnabled}
                            handleChange={this.handleSystemLockChange}
                        />
					</Grid>
					<Grid item xs={12}>
                        <AutoEqualize
                            autoEqualizeEnabled={this.state.autoEqualizeEnabled}
                            handleChange={this.handleAutoEqualizeChange}
                            autoEqualizeAmount={this.state.autoEqualizeAmount}
                            handleAutoEqualizeAmountChange={this.handleAutoEqualizeAmountChange}
                            handleAutoEqualizeAmountConfirmation={this.handleAutoEqualizeAmountConfirmation}
                            jackUpTreshold={this.state.jackUpTreshold}
                            handleJackUpThresholdChange={this.handleJackUpThresholdChange}
                            handleJackUpThresholdConfirmation={this.handleJackUpThresholdConfirmation}
                        />
					</Grid>
                    <Grid item xs={12}>
                        <JackUpFight
                            eventFights={this.state.eventFights}
                            fightNoToJackUp={this.state.fightNoToJackUp}
                            handleFightNoJackUpSelect={this.handleFightNoJackUpSelect}
                            handleJackUp={this.handleJackUp}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FightResultOverride
                            eventFights={this.state.eventFights}
                            fightNoToOverride={this.state.fightNoToOverride}
                            handleFightNoSelect={this.handleFightNoSelect}
                            newFightResult={this.state.newFightResult}
                            handleNewFightResult={this.handleNewFightResult}
                            handleFightResultOverride={this.handleFightResultOverride}
                        />
                    </Grid>
				</Grid>
			</div>
		);
	}
}

const mapStatetoProps = ({ systemConfig, gameEvent }) => {
    const { systemConfigs, systemConfigError, isSystemConfigError } = systemConfig;
    const { currentEventDetails, gameEventError, isGameEventError, isManualFinalizeFightError, manualFinalizeFightError, 
        isJackUpFightError, jackUpFightError } = gameEvent;
    return {
        systemConfigs,
        systemConfigError,
        isSystemConfigError,
        currentEventDetails,
        gameEventError,
        isGameEventError,
        isManualFinalizeFightError,
        manualFinalizeFightError,
        isJackUpFightError,
        jackUpFightError
    };
};

export default connect(mapStatetoProps, { getSystemConfig, updateSystemConfig, updateSystemConfigValue, getCurrentEventByGameId, manuallyFinalizeFight, jackUpFight })(SupportToolsPage);
