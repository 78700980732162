/**
 * NavItemLink Component
 *
 * @author EverardOnggon
 */

import React, { Fragment } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { grey } from "@material-ui/core/colors";
import { Link } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(() => ({
	active: {
		backgroundColor: "#424242 !important",
	},
}));

const LightTooltip = withStyles(() => ({
	tooltip: {
		backgroundColor: "#424242",
		color: "#ffffff",
		fontSize: 16,
		padding: 10,
	},
}))(Tooltip);

const NavItemLink = (props) => {
	const classes = useStyles();
	const { id, icon, title, path, child_routes } = props.menu;

	const handleClick = () => {
		// setOpen(props.drawerOpen ? !open : true);
		// props.onClick(props.menu.id);
		if (id === 10) {
			props.handleLogout();
		} else if (id === 9) {
			props.handleChangePassword();
		} else {
			props.onNavItemClick(false);
		}
	};

	return (
		<Fragment>
			<ListItem
				button
				component={!child_routes ? Link : ""}
				to={path}
				onClick={handleClick}
				selected={
					props.drawerOpen
						? props.selected === id
						: Math.floor(props.selected) === id
				}
				className={
					props.drawerOpen
						? props.selected === id
							? classes.active
							: ""
						: Math.floor(props.selected) === id
						? classes.active
						: ""
				}
			>
				<ListItemIcon>
					<LightTooltip
						title={props.drawerOpen ? "" : title}
						placement="right"
					>
						<Icon style={{ color: grey[50] }}>{icon}</Icon>
					</LightTooltip>
				</ListItemIcon>
				<ListItemText primary={title} />
			</ListItem>
		</Fragment>
	);
};

export { NavItemLink };
