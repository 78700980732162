/**
 * System Profit Summary Component
 * 
 * @author EverardOnggon
 */

 import React from "react";
 import NumberFormat from "react-number-format";
 import { withStyles, makeStyles } from "@material-ui/core/styles";
 import { 
     Paper,
     Grid,
     Toolbar,
     TableContainer,
     Table,
     TableBody,
     TableRow,
     TableCell,
     Button } from "@material-ui/core";
 import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
 
 const StyledTableCell = withStyles((theme) => ({
     root: {
         border: "1px solid #000000",
     },
     head: {
         backgroundColor: "#424242",
         color: "#ffffff",
         fontSize: 14,
         fontWeight: 800,
         textAlign: "center",
         padding: 5,
     },
     body: {
         fontSize: 16,
         fontWeight: 800,
         padding: 5,
         backgroundColor: "#424242",
         color: "#fff",
     },
 }))(TableCell);
 
 const StyledTableRow = withStyles((theme) => ({
     root: {
         backgroundColor: "#ffffff",
     },
 }))(TableRow);
 
 
 const StyledButton = withStyles((theme) => ({
     disabled: {
         opacity: "0.5",
     },
 }))(Button);
 
 const useStyles = makeStyles((theme) => ({
     toolbar: {
        backgroundColor: "#424242",
		color: "#fff",
         fontSize: theme.typography.h6.fontSize,
         fontWeight: theme.typography.h6.fontWeight,
         minHeight: "auto",
         padding: 10,
         borderRadius: "4px 4px 0 0",
     },
     blackPaperBackground: {
        backgroundColor: "#424242",
		color: "#fff",
         padding: "20px",
         borderRadius: "0 0 4px 4px",
     },
     table: {
         width: "100%",
     },
     tableContainer: {
         height: 200,
     }
 }));
 
 const SystemProfitSummary = ( { systemTotalProfit, systemProfitOddsAmountToCancel, systemTotalFundsForOddsExcemptedBets, openLoadingForm } ) => {
     const styles = useStyles();
 
     return (
         <div>
             <Toolbar className={styles.toolbar}>
                 <FormatListBulletedIcon />&nbsp;Overview
             </Toolbar>
             <Paper className={styles.blackPaperBackground} elevation={8}>
                 <Grid container spacing={2}>
                     <Grid item xs={12}>
                         <TableContainer>
                             <Table className={styles.table}>
                                 <TableBody>
                                     <StyledTableRow>
                                         <StyledTableCell style={{width: "30%"}}>Total Profit</StyledTableCell>
                                         <StyledTableCell style={{width: "70%"}}>
                                             <NumberFormat
                                                 value={systemTotalProfit}
                                                 displayType={"text"}
                                                 thousandSeparator={true}
                                             />
                                         </StyledTableCell>
                                     </StyledTableRow>
                                     <StyledTableRow>
                                         <StyledTableCell style={{width: "30%"}}>Odds Amount to Cancel</StyledTableCell>
                                         <StyledTableCell style={{width: "70%"}}>
                                             <NumberFormat
                                                 value={systemProfitOddsAmountToCancel}
                                                 displayType={"text"}
                                                 thousandSeparator={true}
                                             />
                                         </StyledTableCell>
                                     </StyledTableRow>
                                     <StyledTableRow>
                                         <StyledTableCell style={{width: "30%"}}>Total Funds (Draw Bets)</StyledTableCell>
                                         <StyledTableCell style={{width: "70%"}}>
                                             <NumberFormat
                                                 value={systemTotalFundsForOddsExcemptedBets}
                                                 displayType={"text"}
                                                 thousandSeparator={true}
                                             />
                                         </StyledTableCell>
                                     </StyledTableRow>
                                 </TableBody>
                             </Table>
                         </TableContainer>
                     </Grid>
                 </Grid>
             </Paper>
         </div>
     )
 };
 
 export { SystemProfitSummary };