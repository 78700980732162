/**
 * System Performance Actions
 *
 * @author EverardOnggon
 */

import axios from "axios";
import {
	API_URL,
	GET_SYSTEM_PROFIT_SUCCESS,
    GET_SYSTEM_PROFIT_FAILED,
} from "./types";
import { getHeaders } from "./AuthActions";

export const getSystemPerformanceByGameId = (gameId, page, size) => async (dispatch) => {
    try {
        const response = await axios.get(`${API_URL}/system/performance/profit/${gameId}?page=${page}&size=${size}`, await getHeaders());
		if (response.status === 200 || response.status === 201) {
			dispatch({
                type: GET_SYSTEM_PROFIT_SUCCESS,
                data: response.data,
			});
		}
    } catch (error) {
		switch (error.response.status) {
            case 401:
				dispatch({
					type: GET_SYSTEM_PROFIT_FAILED,
					error: error.response.status.toString(),
				});
                break;
            default:
                dispatch({
                    type: GET_SYSTEM_PROFIT_FAILED,
                    error: error.response.data.message,
                });
        }
	}
};
