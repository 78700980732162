/**
 * Other Player Reducer
 *
 * @author EverardOnggon
 */

import {
    AUTH_LOGOUT_SUCCESS,
    AUTH_LOGOUT_FAILED,
	GET_PLAYER_SUCCESS,
    GET_PLAYER_FAILED,
    GET_TRANSACTION_TYPES_SUCCESS,
    GET_TRANSACTION_TYPES_FAILED,
    MONEY_TRANSACTION_SUCCESS,
    MONEY_TRANSACTION_FAILED,
    REACTIVATE_USER_SUCCESS,
    REACTIVATE_USER_FAILED,
    DEACTIVATE_USER_SUCCESS,
    DEACTIVATE_USER_FAILED,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILED,
} from "../actions/types";

const INITIAL_STATE = {
    otherUserProfile: {},
    otherShareRebatePercentage: 0,
    otherPlayerError: "",
    isOtherPlayerError: false,
    transactionTypes: [],
    transactionTypesError: "",
    moneyTransactionError: "",
    isMoneyTransactionError: false,
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case AUTH_LOGOUT_SUCCESS:
        case AUTH_LOGOUT_FAILED:
            return {
                state: undefined,
            };
		case GET_PLAYER_SUCCESS:
			return {
                ...state,
                otherUserProfile: action.data.userProfile,
                otherShareRebatePercentage: action.data.userProfile.shareRebatePercentage,
                otherPlayerError: "",
                isOtherPlayerError: false,
			};
		case GET_PLAYER_FAILED:
			return {
				...state,
                otherPlayerError: action.error,
                isOtherPlayerError: true,
                otherShareRebatePercentage: 0,
                otherUserProfile: {},
            };
        case GET_TRANSACTION_TYPES_SUCCESS:
            return {
                ...state,
                transactionTypes: action.data,
                transactionTypesError: "",
            };
        case GET_TRANSACTION_TYPES_FAILED:
            return {
                ...state,
                transactionTypes: [],
                transactionTypesError: action.error,
            };
        case MONEY_TRANSACTION_SUCCESS:
            return {
                ...state,
                moneyTransactionError: "",
                isMoneyTransactionError: false,
            };
        case MONEY_TRANSACTION_FAILED:
            return {
                ...state,
                moneyTransactionError: action.error,
                isMoneyTransactionError: true,
            }
        case REACTIVATE_USER_SUCCESS:
        case DEACTIVATE_USER_SUCCESS:
        case DELETE_USER_SUCCESS:
            return {
                ...state,
                otherPlayerError: "",
                isOtherPlayerError: false,
            }
        case REACTIVATE_USER_FAILED:
        case DEACTIVATE_USER_FAILED:
        case DELETE_USER_FAILED:
            return {
                ...state,
                otherPlayerError: action.error,
                isOtherPlayerError: true,
            }
		default:
			return state;
	}
}