/**
 * System Profits List Component
 * 
 * @author EverardOnggon
 */

 import React from "react";
 import Moment from "react-moment";
 import NumberFormat from "react-number-format";
 import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
 import { Paper, Grid, Toolbar, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TableFooter, TablePagination, IconButton } from "@material-ui/core";
 import Alert from "@material-ui/lab/Alert";
 import HistoryIcon from '@material-ui/icons/History';
 import FirstPageIcon from '@material-ui/icons/FirstPage';
 import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
 import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
 import LastPageIcon from '@material-ui/icons/LastPage';
 import { game } from "../../constants/Game";
 
 const StyledTableCell = withStyles((theme) => ({
     root: {
         border: "1px solid #000000",
     },
     head: {
         backgroundColor: "#424242",
         color: "#ffffff",
         fontSize: "0.875rem",
         fontWeight: 800,
         textAlign: "center",
         padding: 5,
     },
     body: {
         fontSize: "0.875rem",
         fontWeight: 800,
         padding: 5,
         textAlign: "center",
         backgroundColor: "#424242",
         color: "#fff",
     },
 }))(TableCell);
 
 const StyledTableRow = withStyles((theme) => ({
     root: {
         backgroundColor: "#ffffff",
     },
 }))(TableRow);
 
 const StyledTablePagination = withStyles((theme) => ({
     root: {
         color: "#ffffff",
     },
     selectIcon: {
         color: "#ffffff",
     },
     actions: {
         color: "#ffffff",
     },
 }))(TablePagination);
 
 const useStyles = makeStyles((theme) => ({
     toolbar: {
        backgroundColor: "#424242",
		color: "#fff",
         fontSize: theme.typography.h6.fontSize,
         fontWeight: theme.typography.h6.fontWeight,
         minHeight: "auto",
         padding: 10,
         borderRadius: "4px 4px 0 0",
     },
     blackPaperBackground: {
        backgroundColor: "#424242",
		color: "#fff",
         padding: "20px",
         borderRadius: "0 0 4px 4px",
     },
     table: {
         width: "100%",
     },
     tableContainer: {
         height: 200,
     }
 }));
 
 const usePaginationStyles = makeStyles((theme) => ({
     root: {
       flexShrink: 0,
       marginLeft: theme.spacing(2.5),
     },
     iconButton: {
         color: "#FFFFFF",
     },
     disabledButton: {
         opacity: "0.6",
     },
 }));
 
 function TablePaginationActions(props) {
     const classes = usePaginationStyles();
     const theme = useTheme();
     const { count, page, rowsPerPage, onChangePage } = props;
   
     const handleFirstPageButtonClick = (event) => {
         onChangePage(event, 0);
     };
   
     const handleBackButtonClick = (event) => {
         onChangePage(event, page - 1);
     };
   
     const handleNextButtonClick = (event) => {
         onChangePage(event, page + 1);
     };
   
     const handleLastPageButtonClick = (event) => {
         onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
     };
   
     return (
         <div className={classes.root}>
             <IconButton
                 onClick={handleFirstPageButtonClick}
                 classes={{label: classes.iconButton, disabled: classes.disabledButton}}
                 disabled={page === 0}
                 aria-label="first page"
             >
                 {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
             </IconButton>
             <IconButton
                 onClick={handleBackButtonClick}
                 classes={{label: classes.iconButton, disabled: classes.disabledButton}}
                 disabled={page === 0}
                 aria-label="previous page"
             >
                 {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
             </IconButton>
             <IconButton
                 onClick={handleNextButtonClick}
                 classes={{label: classes.iconButton, disabled: classes.disabledButton}}
                 disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                 aria-label="next page"
             >
                 {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
             </IconButton>
             <IconButton
                 onClick={handleLastPageButtonClick}
                 classes={{label: classes.iconButton, disabled: classes.disabledButton}}
                 disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                 aria-label="last page"
             >
                 {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
             </IconButton>
         </div>
     );
 }
 
 const SystemProfitsList = ( { systemProfits, totalCount, page, size, handleChangePage, handleChangeRowsPerPage } ) => {
     const styles = useStyles();
 
     return (
         <div>
             <Toolbar className={styles.toolbar}>
                 <HistoryIcon />&nbsp;Profit History
             </Toolbar>
             <Paper className={styles.blackPaperBackground} elevation={8}>
                 <Grid container spacing={2}>
                     <Grid item xs={12}>
                         {systemProfits.length === 0 ?
                         (
                             <Alert severity="error">No transactions found</Alert>
                         ) :
                         (
                             <TableContainer>
                                 <Table className={styles.table}>
                                     <TableHead>
                                         <TableRow>
                                             <StyledTableCell style={{width: "16%"}}>Transaction Date</StyledTableCell>
                                             <StyledTableCell style={{width: "16%"}}>Transaction Type</StyledTableCell>
                                             <StyledTableCell style={{width: "21%"}}>Event</StyledTableCell>
                                             <StyledTableCell style={{width: "9%"}}>Deal No.</StyledTableCell>
                                             <StyledTableCell style={{width: "14%"}}>Result</StyledTableCell>
                                             <StyledTableCell style={{width: "14%"}}>System Profit</StyledTableCell>
                                             <StyledTableCell style={{width: "10%"}}>Fund</StyledTableCell>
                                         </TableRow>
                                     </TableHead>
                                     <TableBody>
                                         {systemProfits.map((row, index) => {
                                             let result = "";
                                             let resultColor = "#000000";
                                             let profitAmountColor = "#000000";
                                             let fundAmountColor = "#000000";
 
                                             if (row.totalProfit > 0) {
                                                 profitAmountColor = "#28a745";
                                             } else if (row.totalProfit < 0) {
                                                 profitAmountColor = "#da1c1c";
                                             }
 
                                             if (row.totalFundsForOddsExcemptedBets > 0) {
                                                 fundAmountColor = "#28a745";
                                             } else if (row.totalFundsForOddsExcemptedBets < 0) {
                                                 fundAmountColor = "#da1c1c";
                                             }
 
                                             if (row.result.cancel) {
                                                result = "Cancelled";
                                                resultColor = "#ffc107";
                                            } else if (row.result.draw) {
                                                result = "Draw";
                                                resultColor = "#28a745";
                                            } else {
                                                 result = row.result.winningBetPayout.betOption.value;
                                                 if (row.result.winningBetPayout.betOption.id === game.MERON) {
                                                     resultColor = "#da1c1c";
                                                 } else if (row.result.winningBetPayout.betOption.id === game.WALA) {
                                                     resultColor = "#1231ea";
                                                 } else if (row.result.winningBetPayout.betOption.id === game.DRAW) {
                                                     resultColor = "#28a745";
                                                 } 
                                             }
                                             return (
                                                 <StyledTableRow key={index}>
                                                     <StyledTableCell>
                                                         <Moment format="YYYY-MM-DD hh:mm A">
                                                             {row.createDate}
                                                         </Moment>
                                                     </StyledTableCell>
                                                     <StyledTableCell>
                                                         System Profit
                                                     </StyledTableCell>
                                                     <StyledTableCell>
                                                         {row.event.title}
                                                     </StyledTableCell>
                                                     <StyledTableCell>
                                                         {row.fightNo}
                                                     </StyledTableCell>
                                                     <StyledTableCell>
                                                         <span
                                                             style={{
                                                                 color: `${resultColor}`,
                                                                 fontWeight: 800,
                                                             }}
                                                         >
                                                             {result}
                                                         </span>
                                                     </StyledTableCell>
                                                     <StyledTableCell>
                                                         <NumberFormat
                                                             style={{
                                                                 color: `${profitAmountColor}`,
                                                                 fontWeight: 800,
                                                             }}
                                                             value={row.totalProfit}
                                                             displayType={"text"}
                                                             thousandSeparator={true}
                                                         />
                                                     </StyledTableCell>
                                                     <StyledTableCell>
                                                         <NumberFormat
                                                             style={{
                                                                 color: `${fundAmountColor}`,
                                                                 fontWeight: 800,
                                                             }}
                                                             value={row.totalFundsForOddsExcemptedBets}
                                                             displayType={"text"}
                                                             thousandSeparator={true}
                                                         />
                                                     </StyledTableCell>
                                                 </StyledTableRow>
                                             )
                                         })}
                                     </TableBody>
                                     <TableFooter>
                                         <TableRow>
                                             <StyledTablePagination
                                                 rowsPerPageOptions={[5, 10, 25]}
                                                 colSpan={8}
                                                 count={totalCount}
                                                 rowsPerPage={size}
                                                 page={page}
                                                 onChangePage={handleChangePage}
                                                 onChangeRowsPerPage={handleChangeRowsPerPage}
                                                 ActionsComponent={TablePaginationActions}
                                             />
                                         </TableRow>
                                     </TableFooter>
                                 </Table>
                             </TableContainer>
                         )
                         }
                     </Grid>
                 </Grid>
             </Paper>
         </div>
     )
 };
 
 export { SystemProfitsList };