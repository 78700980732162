/**
 * Dashboard Page
 *
 * @author EverardOnggon
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Box } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import {
	Announcement,
	VideoStream,
	LiveBetting,
	BettingControl,
	ResultHistory,
	VideoTitle,
	AgentDetails,
	CancelledPopUp,
	FightDetails,
	ResultHistoryCircles,
	DragonTailsScoreboard,
} from "../../components/Dashboard";
import { ConfirmationDialog, Notification, FixedNotification } from "../../components/Common";
import { API_URL } from "../../actions/types";
import { store } from "../../store";
import { game, gameId } from "../../constants/Game";
import {
	getCurrentEventByGameId,
	getCurrentFightByEventId,
	getVideoURL,
} from "../../actions/GameEventActions";
import { getBetSummaryByFightIdAndPlayerId, makePlayerBet } from "../../actions/PlayerActions";
import { role } from "../../constants/User";
import { isBrowser } from "react-device-detect";
import LicenseImg from "../../assets/images/dashboard-license.png";

import Flag1 from "../../assets/images/flag/American Samoa.jpg";
import Flag2 from "../../assets/images/flag/Columbia.jpg";
import Flag3 from "../../assets/images/flag/Cuba.jpg";
import Flag4 from "../../assets/images/flag/Dominican Republic.jpg";
import Flag5 from "../../assets/images/flag/Guam.jpg";
import Flag6 from "../../assets/images/flag/Haiti.jpg";
import Flag7 from "../../assets/images/flag/Honduras.jpg";
import Flag8 from "../../assets/images/flag/Indonesia.jpg";
import Flag9 from "../../assets/images/flag/Mexico.jpg";
import Flag10 from "../../assets/images/flag/Panama.jpg";
import Flag11 from "../../assets/images/flag/Peru.jpg";
import Flag12 from "../../assets/images/flag/Puerto rico.jpg";
import Flag13 from "../../assets/images/flag/Thailand.jpg";
import Flag14 from "../../assets/images/flag/Vietnam.jpg";

class DashboardPage extends Component {
	_isMounted = false;
	stompClient = null;
	// sock = null;

	// sock = new SockJS(
	// 	`${API_URL}/websocket?platformTenantId=figbaBets&access_token=${
	// 		store.getState().auth.AccessToken
	// 	}`
	// );

	constructor(props) {
		super(props);

		this.state = {
			currentEventDetails: [],
			videoStreamUrl: "",
			currentFightDetails: [],
			disableBetControls: true,
			meronCount: 0,
			walaCount: 0,
			drawCount: 0,
			cancelledCount: 0,
			betAmount: 5,
			isBetSelectionConfirmationDialog: false,
			selectedBet: 0,
			// Notification
			openSnackbar: false,
			snackbarMessage: "",
			snackbarSeverity: "",
			// Fixed Notification
			openFixedSnackbar: false,
			fixedSnackbarTitle: "",
			fixedSnackbarMessage: "",
			fixedSnackbarSeverity: "",
			// Announcement
			announcement: "",
			// Code
			code: "",
			// Bet
			meronBetAmount: 0,
			walaBetAmount: 0,
			drawBetAmount: 0,
			meronTotalPayout: 0,
			walaTotalPayout: 0,
			drawTotalPayout: 0,
			oneStarTotalPayout: 0,
			doubleStarTotalPayout: 0,
			// Cancelled
			openCancelled: false,
			// toggle video
			showFightVideo: false,
			remainingSecondsToBet: 0,
			isBetDialogOpen: false,
			betSelected: null
		};
	}

	componentDidMount() {
		this._isMounted = true;

		this.setState({
			code: this.props.code,
		});

		this.getEventDetails();

		// setTimeout(() => {
		// 	this.getFightDetails();
		// }, 700);

		// setTimeout(() => {
		// 	this.getBetAmount();
		// }, 1400);

		if (this._isMounted && store.getState().auth.AccessToken) {
			const sock = new SockJS(
				`${API_URL}/websocket?platformTenantId=figbaBets&access_token=${
					store.getState().auth.AccessToken
				}`
			);
			DashboardPage.stompClient = Stomp.over(sock);
			//DashboardPage.stompClient.debug = null;

			// connect to websocket
			sock.onopen = function() {
				console.log("PulaPutiLive open");
			};

			DashboardPage.stompClient.connect(
				{},
				this.onConnected,
				this.onConnectionError
			);
		}
	}

	componentWillUnmount() {
		if (this._isMounted && store.getState().auth.AccessToken) {
			DashboardPage.stompClient.disconnect(this.onDisconnected());
		}

		this._isMounted = false;
	}

	onConnected = () => {
		DashboardPage.stompClient.subscribe(
			"/topic/gamevent",
			this.onEventChangesReceived
		);
		DashboardPage.stompClient.subscribe(
			"/topic/gamefight",
			this.onFightChangesReceived
		);
		DashboardPage.stompClient.subscribe(
			"/user/queue/gamefight/error",
			this.onSubscribeError
		);
	};

	onDisconnected = () => {
		if (DashboardPage.stompClient.status === 'CONNECTED') {
			DashboardPage.stompClient.unsubscribe("/topic/gamevent");
			DashboardPage.stompClient.unsubscribe("/topic/gamefight");
			DashboardPage.stompClient.unsubscribe("/user/queue/gamefight/error");
		}
	};

	onConnectionError = (error) => {
		console.log("Connection error:", error);
		this.setState({
			openFixedSnackbar: true,
			fixedSnackbarTitle: "Connection Error",
			fixedSnackbarMessage: "Please refresh your page or re-login.",
			fixedSnackbarSeverity: "error",
		});
	};

	onSubscribeError = (error) => {
		let error_message = JSON.parse(error.body);
		this.setState({
			openFixedSnackbar: true,
			fixedSnackbarTitle: null,
			fixedSnackbarMessage: error_message.error_description,
			fixedSnackbarSeverity: "error",
		});
	};

	onEventChangesReceived = (payload) => {
		var event = JSON.parse(payload.body);
		if (this._isMounted) {
			this.updateEventDetailsFromWebSocket(event);
		}
	};

	onFightChangesReceived = (payload) => {
		var fight = JSON.parse(payload.body);
		if (this._isMounted) {
			this.updateFightDetailsFromWebSocket(fight);

			if (fight.status.id === game.FINALIZED) {
				if (fight.result.cancel) {
					this.setState({
						openCancelled: true,
					});
				} else {
					this.setState({
						openCancelled: false,
					});
				}
			} else {
				this.setState({
					openCancelled: false,
				});
			}
		}
	};

	updateEventDetailsFromWebSocket = (eventDetails) => {
		let meronCount = 0;
		let walaCount = 0;
		let drawCount = 0;
		let cancelledCount = eventDetails.resultsHistory.totalCancels;

		for (var i = 0; i < eventDetails.resultsHistory.winningBetHistories.length; i++) {
			if ( eventDetails.resultsHistory.winningBetHistories[i].betOption.id === game.MERON ) {
				meronCount = eventDetails.resultsHistory.winningBetHistories[i].totalWins;
			} else if ( eventDetails.resultsHistory.winningBetHistories[i].betOption.id === game.WALA ) {
				walaCount = eventDetails.resultsHistory.winningBetHistories[i].totalWins;
			} else if ( eventDetails.resultsHistory.winningBetHistories[i].betOption.id === game.DRAW ) {
				drawCount = eventDetails.resultsHistory.winningBetHistories[i].totalWins;
			} 
		}

		drawCount = drawCount + eventDetails.resultsHistory.totalDraws;
		
		this.setState({
			currentEventDetails: eventDetails,
			meronCount: meronCount,
			walaCount: walaCount,
			drawCount: drawCount,
			cancelledCount: cancelledCount,
			videoStreamUrl: eventDetails
				? eventDetails.videoUrl
				: this.state.videoStreamUrl,
			eventTitle: eventDetails ? eventDetails.title : "",
			showFightVideo: eventDetails.videoStatus.id === game.DOWN ? false : true,
		});
	};

	updateFightDetailsFromWebSocket = (fightDetails) => {
		this.setState({
			currentFightDetails: fightDetails,
			disableBetControls:
				fightDetails.status.id === game.OPEN ||
				fightDetails.status.id === game.LAST_CALL
					? false
					: true,
		});

		if (fightDetails.status.id === game.CLOSED) {
			this.getBetAmount(); // update bet amount to show amount to win
		} else if (fightDetails.status.id === game.FINALIZED ) {
			this.props.getBalance(); // update available balance
		} else if (fightDetails.status.id === game.STANDBY) {
			// reset the betting values
			this.setState({
				meronBetAmount: 0,
				walaBetAmount: 0,
				drawBetAmount: 0,
				meronTotalPayout: 0,
				walaTotalPayout: 0,
				drawTotalPayout: 0,
			});
		}
	};

	getEventDetails = () => {
		if (this._isMounted) {
			this.props.getCurrentEventByGameId(gameId.COCK_FIGHT).then(() => {
				if (!this.props.isGameEventError) {

					let meronCount = 0;
					let walaCount = 0;
					let drawCount = 0; 
					let cancelledCount = 0;

					if ( this.props.currentEventDetails && this.props.currentEventDetails.resultsHistory) {
						cancelledCount = this.props.currentEventDetails.resultsHistory.totalCancels;
						for (var i = 0; i < this.props.currentEventDetails.resultsHistory.winningBetHistories.length; i++) {
							if ( this.props.currentEventDetails.resultsHistory.winningBetHistories[i].betOption.id === game.MERON ) {
								meronCount = this.props.currentEventDetails.resultsHistory.winningBetHistories[i].totalWins;
							} else if ( this.props.currentEventDetails.resultsHistory.winningBetHistories[i].betOption.id === game.WALA ) {
								walaCount = this.props.currentEventDetails.resultsHistory.winningBetHistories[i].totalWins;
							} else if ( this.props.currentEventDetails.resultsHistory.winningBetHistories[i].betOption.id === game.DRAW ) {
								drawCount = this.props.currentEventDetails.resultsHistory.winningBetHistories[i].totalWins;
							}
						}
					}

					drawCount = drawCount + this.props.currentEventDetails.resultsHistory.totalDraws;
		
					this.setState({
						currentEventDetails: this.props.currentEventDetails,
						meronCount: meronCount,
						walaCount: walaCount,
						drawCount: drawCount,
						cancelledCount: cancelledCount,
						videoStreamUrl: this.props.currentEventDetails
							? this.props.currentEventDetails.videoUrl
							: "",
						announcement: this.props.currentEventDetails
							? this.props.currentEventDetails.announcement
							: "",
						showFightVideo: this.props.currentEventDetails
							? this.props.currentEventDetails.videoStatus && this.props.currentEventDetails.videoStatus.id === game.DOWN ? false : true
							: false,
					}, () => {
						this.getFightDetails(); // get fight details after getting the current event details
					});
				} else {
					this.setState({
						currentEventDetails: [],
						disableBetControls: true,
						meronCount: 0,
						walaCount: 0,
						drawCount: 0,
						cancelledCount: 0,
						videoStreamUrl: "",
						announcement: "",
						showFightVideo: false,
						meronBetAmount: 0,
						walaBetAmount: 0,
						drawBetAmount: 0,
						meronTotalPayout: 0,
						walaTotalPayout: 0,
						drawTotalPayout: 0,
					});

					this.props.getVideoURL(gameId.COCK_FIGHT).then(() => {
						if (!this.props.isGameEventError) {
							this.setState({
								videoStreamUrl: this.props.videoUrl,
							});
						}
					});
				}
			});
		}
	};

	getFightDetails = () => {
		if (this._isMounted) {
			if (
				this.props.currentEventDetails &&
				this.props.currentEventDetails.id
			) {
				this.props
					.getCurrentFightByEventId(this.props.currentEventDetails.id)
					.then(() => {
						if (!this.props.isGameEventError) {
							this.setState({
								currentFightDetails: this.props
									.currentFightDetails,
								disableBetControls:
									this.props.currentFightDetails.status &&
									(this.props.currentFightDetails.status
										.id === game.OPEN ||
										this.props.currentFightDetails.status
											.id === game.LAST_CALL)
										? false
										: true,
							}, () => {
								this.getBetAmount(); // get bet amount details after getting the fight details
							});
						} else {
							this.setState({
								disableBetControls: true,
							});
						}
					});
			}
		}
	};

	handleBet = (selectedBet) => () => {
		if (this.state.betAmount < 5) {
			this.setState({
				openSnackbar: true,
				snackbarMessage: "Bet amount cannot be less than $5",
				snackbarSeverity: "error",
			});
		} else if (selectedBet !== game.MERON && selectedBet !== game.WALA && selectedBet !== game.DRAW) {
			this.setState({
				openSnackbar: true,
				snackbarMessage: "Invalid bet option. Please try again.",
				snackbarSeverity: "error",
			});
		} else {
			this.setState({
				isBetSelectionConfirmationDialog: true,
				selectedBet: selectedBet,
			});
		}
	};

	submitBet = () => {
		let payload = {
			gameFightId: this.state.currentFightDetails.id,
			betOptionId: this.state.selectedBet,
			amount: this.state.betAmount.toString().replace(/,/g, ""),
			playerId: this.props.playerId
		};

		this.props
			.makePlayerBet(
				this.props.playerId,
				JSON.stringify(payload)
			)
			.then(() => {
				if ( !this.props.isPlayerError && this.props.bets.length > 0 ) {

					let meronBetAmount = 0;
					let walaBetAmount = 0;
					let drawBetAmount = 0;
					let meronTotalPayout = 0;
					let walaTotalPayout = 0;
					let drawTotalPayout = 0;
					
					for (var i = 0; i < this.props.bets.length; i++) {
						if ( this.props.bets[i].betOption.id === game.MERON ) {
							meronBetAmount = this.props.bets[i].totalBet;
							meronTotalPayout = this.props.bets[i].totalPayout;
						} else if ( this.props.bets[i].betOption.id === game.WALA ) {
							walaBetAmount = this.props.bets[i].totalBet;
							walaTotalPayout = this.props.bets[i].totalPayout;
						} else if ( this.props.bets[i].betOption.id === game.DRAW ) {
							drawBetAmount = this.props.bets[i].totalBet;
							drawTotalPayout = this.props.bets[i].betOption.multiplier;
						}
					}

					this.setState({
						meronBetAmount: meronBetAmount,
						walaBetAmount: walaBetAmount,
						meronTotalPayout: meronTotalPayout,
						walaTotalPayout: walaTotalPayout,
						drawBetAmount: drawBetAmount,
						drawTotalPayout: drawTotalPayout,
						totalHolds: this.props.newHoldsFromBets
					});

					this.props.setTotalHolds(this.state.totalHolds);

				} else {
					if (this.props.playerError === "401") {
						this.setState(
							{
								snackbarMessage: "Session expired. Logging out.",
								snackbarSeverity: "error",
								openSnackbar: true,
							},
							() => {
								setTimeout(() => {
									this.props.handleLogout();
								}, 1000);
							}
						);
					} else {
						this.getBetAmount();
						this.setState(
							{
								snackbarMessage: this.props.playerError,
								snackbarSeverity: "error",
								openSnackbar: true,
							}
						);
					}
				}
			});
		
		this.setState({
			isBetSelectionConfirmationDialog: false,
			betAmount: 0,
			isBetDialogOpen: false
		});
	};

	getBetAmount = () => {
		if (this._isMounted) {
			this.props
				.getBetSummaryByFightIdAndPlayerId(
					this.state.currentFightDetails.id,
					this.props.playerId
				)
				.then(() => {
					if (
						!this.props.isPlayerError &&
						this.props.bets.length > 0
					) {
						let meronBetAmount = 0;
						let walaBetAmount = 0;
						let drawBetAmount = 0;
						let meronTotalPayout = 0;
						let walaTotalPayout = 0;
						let drawTotalPayout = 0;

						for (var i = 0; i < this.props.bets.length; i++) {
							if ( this.props.bets[i].betOption.id === game.MERON ) {
								meronBetAmount = this.props.bets[i].totalBet;
								meronTotalPayout = this.props.bets[i].totalPayout;
							} else if ( this.props.bets[i].betOption.id === game.WALA ) {
								walaBetAmount = this.props.bets[i].totalBet;
								walaTotalPayout = this.props.bets[i].totalPayout;
							} else if ( this.props.bets[i].betOption.id === game.DRAW ) {
								drawBetAmount = this.props.bets[i].totalBet;
								drawTotalPayout = this.props.bets[i].betOption.multiplier;
							}
						}
						this.setState({
							meronBetAmount: meronBetAmount,
							walaBetAmount: walaBetAmount,
							meronTotalPayout: meronTotalPayout,
							walaTotalPayout: walaTotalPayout,
							drawBetAmount: drawBetAmount,
							drawTotalPayout: drawTotalPayout,
						});
					} else {
						if (this.props.playerError === "401") {
							this.setState(
								{
									snackbarMessage: "Session expired. Logging out.",
									snackbarSeverity: "error",
									openSnackbar: true,
								},
								() => {
									setTimeout(() => {
										this.props.handleLogout();
									}, 1000);
								}
							);
						}
					}
				});
		}
	};

	toggleBetSelectionConfirmationDialog = (isOpen) => () => {
		this.setState({
			isBetSelectionConfirmationDialog: isOpen,
		});
	};

	handleBetAmountChange = (event) => {
		this.setState({
			betAmount: event.target.value !== "" ? event.target.value : 0,
		});
	};

	setPredefinedAmount = (amount) => () => {
		this.setState({
			betAmount: parseInt(this.state.betAmount) + parseInt(amount),
		});
	};

	clear = () => {
		this.setState({
			betAmount: 0,
		});
	};

	handleCloseSnackbar = () => {
		this.setState({
			openSnackbar: false,
		});
	};

	handleCloseFixedSnackbar = () => {
		this.setState({
			openFixedSnackbar: false,
		});
	};

	handleOpenBettingDialog = (betSelected) => {
		this.setState({
			isBetDialogOpen: true,
			betSelected: betSelected
		});
	}

	handleCloseBettingDialog = () => {
		this.setState({
			isBetDialogOpen: false
		});
	}

	render() {
		if (
			this.props.totalBalance < 10 &&
			this.props.roleId !== role.ADMIN &&
			this.props.roleId !== role.OPERATOR &&
			this.props.roleId !== role.TECHNICAL_SUPPORT
		) {
			return (
				<div className="container">
					<Grid
						container
						spacing={2}
						className="d-flex justify-content-center align-items-center"
					>
						<Grid item xs={11} md={10} lg={6}>
							<div className="form-margin bg-base p-sm-25 py-20 px-15 rounded" style={{background: "#424242"}}>
								<Alert
									severity="warning"
									style={{fontSize: '1.2rem', color: "#ffffff", background: "#111111"}}
								>
									<AlertTitle style={{ fontSize: "1.5rem" }}>
										You cannot view and play
									</AlertTitle>
									<AlertTitle style={{ fontSize: "1.5rem" }}>
										No puedes ver ni jugar.
									</AlertTitle>
									Your available balance is below our required
									minimum of $10.
									<br />
									Su saldo disponible está por debajo de nuestro mínimo 
									requerido de $10. 
									<br />
									<br />
									Please contact{" "}
									{this.props.roleId === role.SUPER_AGENT
										? "the admin"
										: "your agent"}{" "}
									to reload.
									<br />
									Comuníquese con{" "}
									{this.props.roleId === role.SUPER_AGENT
										? "the admin"
										: "su agente"}{" "}
									para recargar.
									<br />
									<br />
									{this.props.roleId ===
									role.SUPER_AGENT ? null : (
										<AgentDetails
											name={this.props.agent.displayName}
											mobileNumber={
												this.props.agent.mobileNumber
											}
											emailAddress={
												this.props.agent.emailAddress
													? this.props.agent
															.emailAddress
													: "None"
											}
										/>
									)}
									<br />
									You can still access the other pages like
									the Transactions and History.
									<br />
									Aún puede acceder a otras páginas como Transacciones e Historial.
								</Alert>
							</div>
						</Grid>
					</Grid>
				</div>
			);
		} else {
			var betLabel = game.FIGHT_RESULT[this.state.selectedBet];
			if(betLabel == "MERON")
				betLabel = "RED/ROJO/MERON";
			else if(betLabel == "WALA")
				betLabel = "BLUE/AZUL/WALA";
			else if(betLabel == "DRAW")
				betLabel = "DRAW/EMPATE";
			return (
				<div className="container">
					<ConfirmationDialog
						isOpen={this.state.isBetSelectionConfirmationDialog}
						title="Selected Bet"
						message={`You are betting (Estás apostando) $${this.state.betAmount} to ${
							betLabel
						}. Are you sure? (¿Está seguro?)`}
						onOk={this.submitBet}
						onClose={this.toggleBetSelectionConfirmationDialog}
					/>
					<Notification
						open={this.state.openSnackbar}
						onClose={this.handleCloseSnackbar}
						message={this.state.snackbarMessage}
						severity={this.state.snackbarSeverity}
					/>
					<FixedNotification
						open={this.state.openFixedSnackbar}
						onClose={this.handleCloseFixedSnackbar}
						title={this.state.fixedSnackbarTitle}
						message={this.state.fixedSnackbarMessage}
						severity={this.state.fixedSnackbarSeverity}
					/>
					<CancelledPopUp isOpen={this.state.openCancelled} />
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Grid container spacing={0}>
								<Grid
									item
									xs={12}
									sm={12}
									md={11}
									lg={11}
									xl={10}
									className="mx-auto"
								>
									<Grid container spacing={2}>
										<Grid
											item
											xs={12}
											sm={7}
											md={7}
											lg={7}
										>
											<Grid container spacing={3}>
												<Grid item xs={12} >
													{/* {this.state.videoStreamUrl !==
													"" ? ( */}
													<VideoTitle 
														isOperator={false}
														title={
															this.state
																.currentEventDetails
																? this.state
																		.currentEventDetails
																		.title
																: ""
														}
														source={JSON.stringify({
															src: this.state
																.videoStreamUrl,
															type:
																"application/x-mpegURL",
														})}
													/>
													<VideoStream
														title={
															this.state
																.currentEventDetails
																? this.state
																		.currentEventDetails
																		.title
																: ""
														}
														id="dashboardPlayer"
														source={JSON.stringify({
															src: this.state
																.videoStreamUrl,
															type:
																"application/x-mpegURL",
														})}
														showFightVideo={this.state.showFightVideo}
													/>
													{/* ) : (
														<div
															style={{
																textAlign: "center",
																width: "100%",
																height: 250,
																paddingTop: "20%",
															}}
														>
															<CircularProgress />
														</div>
													)} */}
												</Grid>
												<Grid item xs={12}>
													<Announcement message={this.state.announcement} />
												</Grid>
												<Grid item xs={12}>
													<LiveBetting
														betOptionPayouts={
															this.state
																.currentFightDetails
																.betOptionPayouts
														}
														showActualPlayerBets={
															this.props.roleId === role.ADMIN ||
															this.props.roleId === role.TECHNICAL_SUPPORT ||
															this.props.roleId === role.EQUALIZER }
													/>
												</Grid>
												{
												this.props.roleId === role.ADMIN ||
												this.props.roleId === role.SUPER_AGENT || 
												this.props.roleId === role.TECHNICAL_SUPPORT ? null : 
													(
													<Grid item xs={12} component={Box} display={{xs: 'none', md: 'block'}}>
														<AgentDetails
															name={
																this.props.agent
																	.displayName
															}
															mobileNumber={
																this.props.agent
																	.mobileNumber
															}
															emailAddress={
																this.props.agent
																	.emailAddress
																	? this.props
																			.agent
																			.emailAddress
																	: "None"
															}
														/>
													</Grid>
												)}
												{
												this.props.roleId === role.ADMIN ||
												this.props.roleId === role.SUPER_AGENT ||
												this.props.roleId === role.PLAYER ||
												this.props.roleId === role.TECHNICAL_SUPPORT ||
												this.props.roleId === role.EQUALIZER ? null :
												 	(
													<Grid item xs={12} component={Box} display={{xs: 'none', md: 'block'}}>
														<p
															style={{
																fontSize:
																	"0.875rem",
																fontWeight: 600,
																color:
																	"#FFFFFF",
															}}
														>
															Your registration
															link:&nbsp;
															<a
																href={`${window.location.origin}/register/${this.state.code}`}
																target="_blank"
																rel="noopener noreferrer"
															>
																{
																	window
																		.location
																		.origin
																}
																/register/
																{
																	this.state
																		.code
																}
															</a>
														</p>
													</Grid>
												)}
												{/* <Grid item xs={12} component={Box} display={{xs: 'none', md: 'block'}}>
													<img src={LicenseImg} alt="LicenseImg" />
												</Grid> */}
											</Grid>
										</Grid>
										<Grid
											item
											xs={12}
											sm={5}
											md={5}
											lg={5}
										>
											<Grid container spacing={2}>
												{
												this.props.roleId === role.ADMIN ||
												this.props.roleId === role.SUPER_AGENT ||
												this.props.roleId === role.AGENT ||
												this.props.roleId === role.TECHNICAL_SUPPORT ? (
													<Grid item xs={12}>
														<FightDetails
															fightDetails={
																this.state.currentFightDetails
															}
														/>
													</Grid>
													) : (
													<Grid item xs={12}>
														<BettingControl
															amount={ this.state.betAmount }
															isDisabled={ this.state.disableBetControls }
															fightDetails={ this.state.currentFightDetails }
															handleBet={ this.handleBet }
															handleBetAmountChange={ this.handleBetAmountChange }
															setPredefinedAmount={ this.setPredefinedAmount }
															clear={this.clear}
															meronBetAmount={ this.state.meronBetAmount }
															walaBetAmount={ this.state.walaBetAmount }
															drawBetAmount={ this.state.drawBetAmount }
															meronTotalPayout={ this.state.meronTotalPayout }
															walaTotalPayout={ this.state.walaTotalPayout }
															drawTotalPayout={ this.state.drawTotalPayout }
															remainingSecondsToBet={ this.state.remainingSecondsToBet }
															openDialog={ this.handleOpenBettingDialog }
															betSelected={ this.state.betSelected }
															onCloseDialog={ this.handleCloseBettingDialog }
															isDialogOpen={ this.state.isBetDialogOpen }
														/>
													</Grid>
												)}
												<Grid item xs={12}>
													<ResultHistory
														meronCount={
															this.state
																.meronCount
														}
														walaCount={
															this.state.walaCount
														}
														drawCount={
															this.state.drawCount
														}
														cancelledCount={
															this.state
																.cancelledCount
														}
														fights={
															this.state
																.currentEventDetails
																.totalFights > 0
																? this.state
																		.currentEventDetails
																		.fights
																: []
														}
													/>
												</Grid>
												<Grid item xs={12}>
													<DragonTailsScoreboard
														fights={
															this.state
																.currentEventDetails
																.totalFights > 0
																? this.state
																		.currentEventDetails
																		.fights
																: []
														}
													/>
												</Grid>
												<Grid item xs={12}>
													<ResultHistoryCircles
														fights={
															this.state
																.currentEventDetails
																.totalFights > 0
																? this.state
																		.currentEventDetails
																		.fights
																: []
														}
													/>
												</Grid>
												<Grid item xs={12}>
													<Grid container>
														<Grid item xs={12} className={`p-5`} style={{background: "#000"}}>
															<img
																src={Flag1}
																alt="Flag1"
																className={`p-5 w-flag`}
															/>
															<img
																src={Flag2}
																alt="Flag2"
																className={`p-5 w-flag`}
															/>
															<img
																src={Flag3}
																alt="Flag3"
																className={`p-5 w-flag`}
															/>
															<img
																src={Flag4}
																alt="Flag4"
																className={`p-5 w-flag`}
															/>
															<img
																src={Flag5}
																alt="Flag5"
																className={`p-5 w-flag`}
															/>
															<img
																src={Flag6}
																alt="Flag6"
																className={`p-5 w-flag`}
															/>
															<img
																src={Flag7}
																alt="Flag7"
																className={`p-5 w-flag`}
															/>
															<img
																src={Flag8}
																alt="Flag8"
																className={`p-5 w-flag`}
															/>
															<img
																src={Flag9}
																alt="Flag9"
																className={`p-5 w-flag`}
															/>
															<img
																src={Flag10}
																alt="Flag10"
																className={`p-5 w-flag`}
															/>
															<img
																src={Flag11}
																alt="Flag11"
																className={`p-5 w-flag`}
															/>
															<img
																src={Flag12}
																alt="Flag12"
																className={`p-5 w-flag`}
															/>
															<img
																src={Flag13}
																alt="Flag13"
																className={`p-5 w-flag`}
															/>
															<img
																src={Flag14}
																alt="Flag14"
																className={`p-5 w-flag`}
															/>
															<p style={{color: "#FFF", padding: "7px", fontWeight: "bold", marginBottom: 0}}>
															Esta transmisión en vivo de peleas de gallos es legal en Filipinas. Esta es una pelea de tipo cuchillo largo y los gallos se emparejan según su peso.
															<br /><br />
															Todas las peleas son justas y están manejadas por un árbitro (sentenciador) que tiene la autoridad exclusiva para declarar al ganador.
															</p>
														</Grid>
													</Grid>
												</Grid>
												{
												this.props.roleId === role.ADMIN ||
												this.props.roleId === role.SUPER_AGENT ||
												this.props.roleId === role.TECHNICAL_SUPPORT ? null : (
													<Grid item xs={12} component={Box} display={{xs: 'block', md: 'none'}}>
														<AgentDetails
															name={
																this.props.agent
																	.displayName
															}
															mobileNumber={
																this.props.agent
																	.mobileNumber
															}
															emailAddress={
																this.props.agent
																	.emailAddress
																	? this.props
																			.agent
																			.emailAddress
																	: "None"
															}
														/>
													</Grid>
												)}
												{
												this.props.roleId === role.ADMIN ||
												this.props.roleId === role.SUPER_AGENT || 
												this.props.roleId === role.PLAYER ||
												this.props.roleId === role.TECHNICAL_SUPPORT ||
												this.props.roleId === role.EQUALIZER ? null : (
													<Grid item xs={12} component={Box} display={{xs: 'block', md: 'none'}}>
														<p
															style={{
																fontSize:
																	"0.875rem",
																fontWeight: 600,
																color:
																	"#FFFFFF",
															}}
														>
															Your registration
															link:&nbsp;
															<a
																href={`${window.location.origin}/register/${this.state.code}`}
																target="_blank"
																rel="noopener noreferrer"
															>
																{
																	window
																		.location
																		.origin
																}
																/register/
																{
																	this.state
																		.code
																}
															</a>
														</p>
													</Grid>
												)}
												{/* <Grid item xs={12} component={Box} display={{xs: 'block', md: 'none'}}>
													<img src={LicenseImg} alt="LicenseImg" />
												</Grid> */}
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</div>
			);
		}
	}
}

const mapStatetoProps = ({ auth, user, gameEvent, player }) => {
	const { AccessToken, TokenExpiration, RefreshToken } = auth;
	const {
		userId,
		totalHolds,
		roleId,
		agent,
		code,
		userError,
		isUserError,
		playerId,
	} = user;
	const {
		gameEventError,
		isGameEventError,
		currentEventDetails,
		currentFightDetails,
		videoUrl,
	} = gameEvent;
	const { bets, playerError, isPlayerError, newHoldsFromBets } = player;

	return {
		AccessToken,
		TokenExpiration,
		RefreshToken,
		userId,
		totalHolds,
		newHoldsFromBets,
		roleId,
		agent,
		code,
		userError,
		isUserError,
		playerId,
		gameEventError,
		isGameEventError,
		currentEventDetails,
		currentFightDetails,
		videoUrl,
		bets,
		playerError,
		isPlayerError,
	};
};

export default connect(mapStatetoProps, {
	getCurrentEventByGameId,
	getCurrentFightByEventId,
	getVideoURL,
	getBetSummaryByFightIdAndPlayerId,
	makePlayerBet
})(DashboardPage);
