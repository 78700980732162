/**
 * Transaction Constants
 */

export const transaction = {
	// TRANSACTION TYPE
	DEPOSIT: 1,
    WITHDRAWAL: 2,
    BET_WIN: 3,
    BET_LOSS: 4,
    BET_DRAW: 5,
    BET_CANCEL: 6,
    DEPOSIT_SHARE_REBATE: 7,
    DEPOSIT_TO_PLAYER: 8,
    WITHDRAWAL_FROM_PLAYER: 9,
    WITHDRAWAL_SHARE_REBATE: 10,
};
