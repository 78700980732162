/**
 * Fight Result Override Component
 *
 * @author EverardOnggon
 */

import React from "react";
import { Grid, Paper, FormControl, InputLabel, Select, Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { game } from "../../../constants/Game";

const useStyles = makeStyles((theme) => ({
    paperBackground: {
        padding: "20px",
        borderRadius: 0,
    },
}));

const JackUpFight = ({eventFights, fightNoToJackUp, handleFightNoJackUpSelect, handleJackUp}) => {
    const styles = useStyles();

	return (
		<div>
            <Paper className={styles.paperBackground}>
				<Grid
					container
					spacing={2}
					className="d-flex justify-content-center align-items-center"
				>
					<Grid item xs={12}>
						<h4>Jack Up Fight</h4>
                        <p>You can Jack Up a fight for it to be cancelled.</p>
                        <Alert severity="warning">
							This feature serves as the last resort. It is highly advisable to not use this as much because it is irreversible.
						</Alert>
					</Grid>
					<Grid item xs={12}>
                        <FormControl>
                            <InputLabel htmlFor="event-fight-number">Fight #</InputLabel>
                            <Select
                                native
                                value={fightNoToJackUp}
                                onChange={handleFightNoJackUpSelect}
                                inputProps={{
                                    name: 'fightNo',
                                    id: 'event-fight-number'
                                }}
                            >
                                <option key={0} aria-label="---" value="" />
                                {eventFights.map((row, index) => {
                                    return (
                                        <option key={index + 1} value={row.id}>Fight No. {row.fightNo}</option>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <FormControl>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleJackUp}
                                style={{marginTop: 10}}
                            >
                                Jack Up
                            </Button>
                        </FormControl>
					</Grid>
				</Grid>
            </Paper>
		</div>
	);
};

export { JackUpFight };
