/**
 * Event Reducer
 *
 * @author EverardOnggon
 */

import {
	AUTH_LOGOUT_SUCCESS,
	AUTH_LOGOUT_FAILED,
	GET_EVENT_DETAILS_BY_IDS_SUCCESS,
	GET_EVENT_DETAILS_BY_IDS_FAILED,
} from "../actions/types";

const INITIAL_STATE = {
	eventDetailsError: "",
	isEventDetailsError: false,
	initialBalance: 0,
	betCount: 0,
	totalWins: 0,
	totalLosses: 0,
	totalReturns: 0,
	totalDraws: 0,
	totalBets: 0,
	netResult: 0,
	betTransactions: {},
	eventTitle: "",
	eventDateTime: "",
};

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case AUTH_LOGOUT_SUCCESS:
		case AUTH_LOGOUT_FAILED:
			return {
				state: undefined,
			};
		case GET_EVENT_DETAILS_BY_IDS_SUCCESS:
			return {
				...state,
				eventDetailsError: "",
				isEventDetailsError: false,
				initialBalance: action.data.initialBalance,
				betCount: action.data.betCount,
				totalWins: action.data.totalWins,
				totalLosses: action.data.totalLosses,
				totalReturns: action.data.totalReturns,
				totalDraws: action.data.totalDraws,
				totalBets: action.data.totalBets,
				netResult: action.data.netResult,
				betTransactions: action.data.betTransactions,
				eventTitle: action.data.eventTitle,
				eventDateTime: action.data.eventDateTime,
			};
		case GET_EVENT_DETAILS_BY_IDS_FAILED:
			return {
				...state,
				eventDetailsError: action.error,
				isEventDetailsError: true,
				initialBalance: 0,
				betCount: 0,
				totalWins: 0,
				totalLosses: 0,
				totalReturns: 0,
				totalDraws: 0,
				totalBets: 0,
				netResult: 0,
				betTransactions: {},
				eventTitle: "",
				eventDateTime: "",
			};
		default:
			return state;
	}
}
