/**
 * Bets Report Component
 * 
 * @author EverardOnggon
 */

import React from "react";
import NumberFormat from "react-number-format";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, Toolbar, TableContainer, Table, TableBody, TableRow, TableCell } from "@material-ui/core";
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

const StyledTableCell = withStyles((theme) => ({
    root: {
        border: "1px solid #000000",
    },
    head: {
        backgroundColor: "#424242",
        color: "#ffffff",
        fontSize: 14,
        fontWeight: 800,
        textAlign: "center",
        padding: 5,
    },
    body: {
        fontSize: 16,
        fontWeight: 800,
        padding: 5,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        backgroundColor: "#ffffff",
        '&:nth-of-type(odd)': {
            backgroundColor: "#f1f1f1",
        }
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    toolbar: {
        backgroundColor: "#424242",
        color: "#ffffff",
        fontSize: theme.typography.h6.fontSize,
        fontWeight: theme.typography.h6.fontWeight,
        minHeight: "auto",
        padding: 10,
    },
	blackPaperBackground: {
		backgroundColor: "#000000",
		color: "#ffffff",
        padding: "20px",
        borderRadius: 0,
    },
    table: {
        width: "100%",
    },
    tableContainer: {
        height: 200,
    }
}));

const BetsReport = ( { initialBalance, betCount, totalWins, totalLosses, totalReturns, totalDraws, totalBets, netResult } ) => {
    const styles = useStyles();

    return (
        <div>
            <Toolbar className={styles.toolbar}>
                <FormatListBulletedIcon />&nbsp;Bets Report
            </Toolbar>
            <Paper className={styles.blackPaperBackground}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table className={styles.table}>
                                <TableBody>
                                    <StyledTableRow>
                                        <StyledTableCell style={{width: "50%"}}>Initial Balance</StyledTableCell>
                                        <StyledTableCell style={{width: "50%"}}>
                                            <NumberFormat
                                                value={initialBalance}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                            />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell style={{width: "50%"}}>Bet Count</StyledTableCell>
                                        <StyledTableCell style={{width: "50%"}}>
                                            <NumberFormat
                                                value={betCount}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                            />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell style={{width: "50%"}}>Total Win</StyledTableCell>
                                        <StyledTableCell style={{width: "50%"}}>
                                            <NumberFormat
                                                value={totalWins}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                            />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell style={{width: "50%"}}>Total Lost</StyledTableCell>
                                        <StyledTableCell style={{width: "50%"}}>
                                            <NumberFormat
                                                value={totalLosses}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                            />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell style={{width: "50%"}}>Total Returned</StyledTableCell>
                                        <StyledTableCell style={{width: "50%"}}>
                                            <NumberFormat
                                                value={totalReturns}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                            />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell style={{width: "50%"}}>Total Draw Bets</StyledTableCell>
                                        <StyledTableCell style={{width: "50%"}}>
                                            <NumberFormat
                                                value={totalDraws}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                            />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell style={{width: "50%"}}>Total Bets</StyledTableCell>
                                        <StyledTableCell style={{width: "50%"}}>
                                            <NumberFormat
                                                value={totalBets}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                            />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell style={{width: "50%"}}>Net Result</StyledTableCell>
                                        <StyledTableCell style={{width: "50%"}}>
                                            <NumberFormat
                                                value={netResult}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                            />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
};

export { BetsReport };