/**
 * Transactions List Component
 * 
 * @author EverardOnggon
 */

import React from "react";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import {
    Paper,
    Grid,
    Toolbar,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableFooter,
    TablePagination,
    IconButton,
    MenuItem,
    TextField,
    Button,
    Divider,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import HistoryIcon from '@material-ui/icons/History';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import FilterListIcon from '@material-ui/icons/FilterList';
import LastPageIcon from '@material-ui/icons/LastPage';
import { transaction } from "../../constants/Transaction";

const StyledTableCell = withStyles((theme) => ({
    root: {
        border: "1px solid #000000",
    },
    head: {
        backgroundColor: "#424242",
        color: "#ffffff",
        fontSize: "0.875rem",
        fontWeight: 800,
        textAlign: "center",
        padding: 5,
    },
    body: {
        fontSize: "0.875rem",
        fontWeight: 800,
        padding: 5,
        textAlign: "center",
        backgroundColor: "#424242",
		color: "#fff",
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        backgroundColor: "#ffffff",
        '&:nth-of-type(odd)': {
            backgroundColor: "#f1f1f1",
        }
    },
}))(TableRow);

const StyledTablePagination = withStyles((theme) => ({
    root: {
        color: "#ffffff",
    },
    selectIcon: {
        color: "#ffffff",
    },
    actions: {
        color: "#ffffff",
    },
}))(TablePagination);

const useStyles = makeStyles((theme) => ({
    toolbar: {
        backgroundColor: "#424242",
        color: "#ffffff",
        fontSize: theme.typography.h6.fontSize,
        fontWeight: theme.typography.h6.fontWeight,
        minHeight: "auto",
        padding: 10,
    },
	blackPaperBackground: {
		backgroundColor: "#000000",
		color: "#ffffff",
        padding: "20px",
        borderRadius: 0,
    },
    table: {
        width: "100%",
    },
    tableContainer: {
        height: 200,
    }
}));

const usePaginationStyles = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    iconButton: {
        color: "#FFFFFF",
    },
    disabledButton: {
        opacity: "0.6",
    },
}));

function TablePaginationActions(props) {
    const classes = usePaginationStyles();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;
  
    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                classes={{label: classes.iconButton, disabled: classes.disabledButton}}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                classes={{label: classes.iconButton, disabled: classes.disabledButton}}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                classes={{label: classes.iconButton, disabled: classes.disabledButton}}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                classes={{label: classes.iconButton, disabled: classes.disabledButton}}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
  }

const TransactionsList = ( { transactions, totalCount, page, size, handleChangePage, handleChangeRowsPerPage, transactionTypes, selectedTransactionTypes, handleTransactionTypesChange, handleDateFilterChange, handleFilter } ) => {
    const styles = useStyles();

    return (
        <div>
            <Toolbar className={styles.toolbar}>
                <HistoryIcon />&nbsp;Transaction History
            </Toolbar>
            <Paper className={styles.blackPaperBackground}>
                <Grid container spacing={2}>
                    { transactionTypes ? (
                        <Grid item xs={4} sm={5}>
                            <TextField
                                id="selectedTransactionTypes"
                                name="selectedTransactionTypes"
                                select
                                variant="filled"
                                label="Transaction Types"
                                SelectProps={{
                                    multiple: true,
                                    value: selectedTransactionTypes,
                                    onChange: handleTransactionTypesChange,
                                }}
                                style={{width: "100%", backgroundColor: "#FFFFFF"}}
                            >
                                {transactionTypes.map((row, index) => {
                                    return (
                                        <MenuItem value={row.id} key={index}>{row.value}</MenuItem>
                                    )
                                })}
                            </TextField>
                        </Grid>
                    ) : null}
                    <Grid item xs={6} sm={5}>
                        <TextField
                            id="dateFrom"
                            name="dateFrom"
                            label="Date From"
                            type="date"
                            variant="filled"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{backgroundColor: "#FFFFFF", width: "49%"}}
                            onChange={handleDateFilterChange}
                        />
                        &nbsp;
                        <TextField
                            id="dateTo"
                            name="dateTo"
                            label="Date To"
                            type="date"
                            variant="filled"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{backgroundColor: "#FFFFFF", width: "49%"}}
                            onChange={handleDateFilterChange}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            style={{
                                height: "100%",
                                width: "100%",
                                maxHeight: "56px",
                                borderRadius: 0,
                            }}
                            onClick={handleFilter}
                        >
                            <FilterListIcon />&nbsp;Filter
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider
                            style={{backgroundColor: "#ffffff"}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {transactions.length === 0 ?
                        (
                            <Alert severity="error">No transactions found</Alert>
                        ) :
                        (
                            <TableContainer>
                                <Table className={styles.table}>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell style={{width: "15%"}}>Transaction Date</StyledTableCell>
                                            <StyledTableCell style={{width: "10%"}}>Transaction Type</StyledTableCell>
                                            <StyledTableCell style={{width: "10%"}}>Amount</StyledTableCell>
                                            <StyledTableCell style={{width: "10%"}}>Old Balance</StyledTableCell>
                                            <StyledTableCell style={{width: "10%"}}>New Balance</StyledTableCell>
                                            <StyledTableCell style={{width: "15%"}}>Transacted by</StyledTableCell>
                                            <StyledTableCell style={{width: "15%"}}>Transacted to</StyledTableCell>
                                            <StyledTableCell style={{width: "15%"}}>Remarks</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {transactions.map((row, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell>
                                                    <Moment format="YYYY-MM-DD hh:mm A">
                                                        {row.createDate}
                                                    </Moment>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {row.type.value}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    $&nbsp;
                                                    <NumberFormat
                                                        value={row.amount}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    $&nbsp;
                                                    <NumberFormat
                                                        value={row.previousBalance}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    $&nbsp;
                                                    <NumberFormat
                                                        value={row.newBalance}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {row.type.id === transaction.DEPOSIT_SHARE_REBATE ? "System" : (
                                                        row.transactedBy ? row.transactedBy.displayName : ""
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {row.transactedTo && row.transactedTo.userProfile ? row.transactedTo.userProfile.displayName : ""}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {row.remarks}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <StyledTablePagination
                                                rowsPerPageOptions={[5, 10, 25]}
                                                colSpan={8}
                                                count={totalCount}
                                                rowsPerPage={size}
                                                page={page}
                                                onChangePage={handleChangePage}
                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        )
                        }
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
};

export { TransactionsList };