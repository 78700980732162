/**
 * Event Details Page
 *
 * @author EverardOnggon
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import Moment from "react-moment";
import { Grid } from "@material-ui/core";
import { BetsReport, BetLogs } from "../../components";
import { HeaderWithBackButton, Notification } from "../../components/Common";
import { getEventDetailsByEventIdAndPlayerId } from "../../actions/EventActions";

class EventDetailsPage extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			eventTransactionsListMessage: "",
			page: 0,
			size: 10,
			totalCount: 0,
			initialBalance: 0,
			betCount: 0,
			totalWins: 0,
			totalLosses: 0,
			totalReturns: 0,
			totalDraws: 0,
			totalBets: 0,
			netResult: 0,
			betTransactions: {},
			eventTitle: "",
			eventDateTime: null,
			// Snackbar
			openSnackbar: false,
			snackbarMessage: "",
			snackbarSeverity: "error",
		};
	}

	componentDidMount() {
		this._isMounted = true;

		this.getEventDetailsByEventIdAndPlayerId();
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	getEventDetailsByEventIdAndPlayerId = () => {
		let pathName = window.location.pathname;
		let param = pathName.split("/", 5);
		let eventId = param[3];
		let playerId = param.length >= 5 ? param[4] : this.props.playerId;

		this.props
			.getEventDetailsByEventIdAndPlayerId(
				eventId,
				playerId,
				this.state.page,
				this.state.size
			)
			.then(() => {
				if (!this.props.isEventDetailsError && this.props.eventTitle) {
					this.setState({
						eventTransactionsListMessage: "",
						initialBalance: this.props.initialBalance,
						betCount: this.props.betCount,
						totalWins: this.props.totalWins,
						totalLosses: this.props.totalLosses,
						totalReturns: this.props.totalReturns,
						totalDraws: this.props.totalDraws,
						totalBets: this.props.totalBets,
						netResult: this.props.netResult,
						betTransactions: this.props.betTransactions,
						eventTitle: this.props.eventTitle,
						eventDateTime: this.props.eventDateTime,
					});
				} else {
					if (this.props.eventDetailsError === "401") {
						this.setState({
							snackbarMessage: "Session expired. Logging out.",
							snackbarSeverity: "error",
							openSnackbar: true,
						},
						() => {
							setTimeout(() => {
								this.props.handleLogout();
							}, 1000);
						});
					} else {
						this.setState({
							eventTransactionsListMessage: this.props
								.eventDetailsError,
							initialBalance: 0,
							betCount: 0,
							totalWins: 0,
							totalLosses: 0,
							totalReturns: 0,
							totalDraws: 0,
							totalBets: 0,
							netResult: 0,
							betTransactions: {},
							eventTitle: "",
							eventDateTime: null,
						});
					}
				}
			});
	};

	handleChangePage = (event, newPage) => {
		this.setState(
			{
				page: newPage,
			},
			() => {
				this.getEventDetailsByEventIdAndPlayerId();
			}
		);
	};

	handleChangeRowsPerPage = (event) => {
		this.setState(
			{
				size: event.target.value ? parseInt(event.target.value) : 10,
				page: 0,
			},
			() => {
				this.getEventDetailsByEventIdAndPlayerId();
			}
		);
	};

	render() {
		return (
			<div className="container">
				<Notification
					open={this.state.openSnackbar}
					onClose={this.handleCloseSnackbar}
					message={this.state.snackbarMessage}
					severity={this.state.snackbarSeverity}
				/>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<HeaderWithBackButton
							title={this.state.eventTitle}
							subTitle={
								this.state.eventDateTime !== null ? (
									<Moment format="YYYY-MM-DD hh:mm A">
										{this.state.eventDateTime}
									</Moment>
								) : (
									""
								)
							}
							buttonLabel="Back"
						/>
					</Grid>
					<Grid item xs={12}>
						<BetsReport
							initialBalance={this.state.initialBalance}
							betCount={this.state.betCount}
							totalWins={this.state.totalWins}
							totalLosses={this.state.totalLosses}
							totalReturns={this.state.totalReturns}
							totalDraws={this.state.totalDraws}
							totalBets={this.state.totalBets}
							netResult={this.state.netResult}
						/>
					</Grid>
					<Grid item xs={12}>
						<BetLogs
							betLogs={
								this.state.betTransactions
									? this.state.betTransactions.content
									: []
							}
							totalCount={
								this.state.betTransactions
									? this.state.betTransactions.totalElements
									: 0
							}
							page={this.state.page}
							size={this.state.size}
							handleChangePage={this.handleChangePage}
							handleChangeRowsPerPage={
								this.handleChangeRowsPerPage
							}
						/>
					</Grid>
				</Grid>
			</div>
		);
	}
}

const mapStatetoProps = ({ event, user }) => {
	const {
		eventDetailsError,
		isEventDetailsError,
		initialBalance,
		betCount,
		totalWins,
		totalLosses,
		totalReturns,
		totalDraws,
		totalBets,
		netResult,
		betTransactions,
		eventTitle,
		eventDateTime,
	} = event;
	const { playerId } = user;

	return {
		eventDetailsError,
		isEventDetailsError,
		initialBalance,
		betCount,
		totalWins,
		totalLosses,
		totalReturns,
		totalDraws,
		totalBets,
		netResult,
		betTransactions,
		eventTitle,
		eventDateTime,
		playerId,
	};
};

export default connect(mapStatetoProps, {
	getEventDetailsByEventIdAndPlayerId,
})(EventDetailsPage);
