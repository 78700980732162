/**
 * Fixed Notification Component
 * 
 * @author EverardOnggon
 */

import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Snackbar, IconButton } from '@material-ui/core';
import MuiAlert from "@material-ui/lab/Alert";
import { AlertTitle } from "@material-ui/lab";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    close: {
        padding: theme.spacing(0.5),
    },
}));

const Alert = (props) => {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const FixedNotification = ({open, onClose, title, message, severity}) => {
    const classes = useStyles();

    return (
        <Snackbar
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			open={open}
            onClose={onClose}
		>
			<Alert
                severity={severity}
                action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            className={classes.close}
                            onClick={onClose}
                        >
                            <CloseIcon />
                        </IconButton>
                }
            >
                { title ? <AlertTitle>{title}</AlertTitle> : null }
                {message}
            </Alert>
		</Snackbar>
    )
}

export { FixedNotification };