/**
 * Header with Back Button Component
 *
 * @author EverardOnggon
 */

import React from "react";
import { useHistory } from "react-router-dom";
import { Typography, Button, Grid } from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const HeaderWithBackButton = ({ title, subTitle, buttonLabel }) => {
    const history = useHistory();

	return (
		<div>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="medium"
                        onClick={() => {history.goBack()}}
                        className="button btn-base"
                    >
                        <ArrowBackIosIcon />&nbsp;{buttonLabel}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant="h4"
                        className="text-center"
                        style={{
                            color: "#FFFFFF"
                        }}
                    >
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant="h6"
                        className="text-center"
                        style={{
                            color: "#FFFFFF"
                        }}
                    >
                        {subTitle}
                    </Typography>
                    <br />
                    <br />
                </Grid>
            </Grid>
		</div>
	);
};

export { HeaderWithBackButton };
