/**
 * Reports Actions
 *
 * @author EverardOnggon
 */

import axios from "axios";
import {
	API_URL,
	GET_REBATES_REPORT_SUCCESS,
    GET_REBATES_REPORT_FAILED,
    GET_SYSTEM_PROFIT_REPORT_SUCCESS,
    GET_SYSTEM_PROFIT_REPORT_FAILED,
    GET_BETS_REPORT_SUCCESS,
    GET_BETS_REPORT_FAILED,
} from "./types";
import { getHeaders } from "./AuthActions";

export const getRebatesReport = (payload) => async (dispatch) => {
    try {
        const response = await axios.post(`${API_URL}/report/rebates`, payload, await getHeaders());
		if (response.status === 200 || response.status === 201) {
			dispatch({
				type: GET_REBATES_REPORT_SUCCESS,
				data: response.data,
			});
		}
    } catch (error) {
        switch (error.response.status) {
			case 401:
				dispatch({
					type: GET_REBATES_REPORT_FAILED,
					error: error.response.status.toString(),
				});
				break;
			default:
				dispatch({
					type: GET_REBATES_REPORT_FAILED,
					error: error.response.data.message,
				});
		}
    }
}

export const getSystemProfitReport = (payload) => async (dispatch) => {
    try {
        const response = await axios.post(`${API_URL}/report/system/profit`, payload, await getHeaders());
		if (response.status === 200 || response.status === 201) {
			dispatch({
				type: GET_SYSTEM_PROFIT_REPORT_SUCCESS,
				data: response.data,
			});
		}
    } catch (error) {
        switch (error.response.status) {
			case 401:
				dispatch({
					type: GET_SYSTEM_PROFIT_REPORT_FAILED,
					error: error.response.status.toString(),
				});
				break;
			default:
				dispatch({
					type: GET_SYSTEM_PROFIT_REPORT_FAILED,
					error: error.response.data.message,
				});
		}
    }
}

export const getBetsReport = (payload) => async (dispatch) => {
    try {
        const response = await axios.post(`${API_URL}/report/bets`, payload, await getHeaders());
		if (response.status === 200 || response.status === 201) {
			dispatch({
				type: GET_BETS_REPORT_SUCCESS,
				data: response.data,
			});
		}
    } catch (error) {
        switch (error.response.status) {
			case 401:
				dispatch({
					type: GET_BETS_REPORT_FAILED,
					error: error.response.status.toString(),
				});
				break;
			default:
				dispatch({
					type: GET_BETS_REPORT_FAILED,
					error: error.response.data.message,
				});
		}
    }
}