/**
 * Game Event Reducer
 *
 * @author EverardOnggon
 */

import {
	AUTH_LOGOUT_SUCCESS,
	AUTH_LOGOUT_FAILED,
	CREATE_EVENT_SUCCESS,
	CREATE_EVENT_FAILED,
	CLOSE_EVENT_SUCCESS,
	CLOSE_EVENT_FAILED,
	GET_EVENTS_BY_GAME_SUCCESS,
	GET_EVENTS_BY_GAME_FAILED,
	GET_EVENT_DETAILS_SUCCESS,
	GET_EVENT_DETAILS_FAILED,
	GET_CURRENT_EVENT_BY_GAME_SUCCESS,
	GET_CURRENT_EVENT_BY_GAME_FAILED,
	GET_CURRENT_FIGHT_BY_EVENT_SUCCESS,
	GET_CURRENT_FIGHT_BY_EVENT_FAILED,
	GET_VIDEO_URL_SUCCESS,
	GET_VIDEO_URL_FAILED,
	EDIT_EVENT_SUCCESS,
	EDIT_EVENT_FAILED,
	MANUAL_FINALIZE_FIGHT_SUCCESS,
	MANUAL_FINALIZE_FIGHT_FAILED,
	JACK_UP_FIGHT_SUCCESS,
	JACK_UP_FIGHT_FAILED,
} from "../actions/types";

const INITIAL_STATE = {
	gameEventError: "",
	isGameEventError: false,
	eventId: "",
	events: [],
	totalCount: 0,
	currentEventDetails: [],
	currentFightDetails: [],
	videoUrl: "",
	eventTitle: "",
	eventDateTime: null,
	isManualFinalizeFightError: false,
	manualFinalizeFightError: "",
	isJackUpFightError: false,
	jackUpFightError: "",
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case AUTH_LOGOUT_SUCCESS:
		case AUTH_LOGOUT_FAILED:
			return {
				state: undefined,
			};
		case CREATE_EVENT_SUCCESS:
			return {
				...state,
				gameEventError: "",
				isGameEventError: false,
				currentEventDetails: [],
				eventId: action.data,
			};
		case CREATE_EVENT_FAILED:
		case CLOSE_EVENT_FAILED:
		case GET_EVENT_DETAILS_FAILED:
			return {
				...state,
				gameEventError: action.error,
				isGameEventError: true,
				currentEventDetails: [],
				eventId: "",
				eventTitle: "",
				eventDateTime: null,
			};
		case CLOSE_EVENT_SUCCESS:
			return {
				...state,
				gameEventError: "",
				isGameEventError: false,
				currentEventDetails: [],
				eventId: "",
			};
		case GET_EVENTS_BY_GAME_SUCCESS:
			return {
				...state,
				events: action.data.content,
                totalCount: action.data.totalElements,
				gameEventError: "",
				isGameEventError: false,
			};
		case GET_EVENTS_BY_GAME_FAILED:
			return {
				...state,
				gameEventError: action.error,
				isGameEventError: true,
				events: [],
			};
		case GET_EVENT_DETAILS_SUCCESS:
			return {
				...state,
				gameEventError: "",
				isGameEventError: false,
				currentEventDetails: action.data,
				eventId: action.data.id,
				eventTitle: action.data.title,
				eventDateTime: action.data.createDate,
			};
		case GET_CURRENT_EVENT_BY_GAME_SUCCESS:
			return {
				...state,
				gameEventError: "",
				isGameEventError: false,
				currentEventDetails: action.data,
				eventId: action.data.id,
			};
		case GET_CURRENT_EVENT_BY_GAME_FAILED:
			return {
				...state,
				gameEventError: action.error,
				isGameEventError: true,
				currentEventDetails: [],
				eventId: "",
			};
		case GET_CURRENT_FIGHT_BY_EVENT_SUCCESS:
			return {
				...state,
				gameEventError: "",
				isGameEventError: false,
				currentFightDetails: action.data,
			};
		case GET_CURRENT_FIGHT_BY_EVENT_FAILED:
			return {
				...state,
				gameEventError: action.error,
				isGameEventError: true,
				currentFightDetails: [],
			};
		case GET_VIDEO_URL_SUCCESS:
			return {
				...state,
				gameEventError: "",
				isGameEventError: false,
				videoUrl: action.data,
			}
		case GET_VIDEO_URL_FAILED:
			return {
				...state,
				gameEventError: action.error,
				isGameEventError: true,
				videoUrl: "",
			}
		case EDIT_EVENT_SUCCESS:
			return {
				...state,
				gameEventError: "",
				isGameEventError: false,
			}
		case EDIT_EVENT_FAILED:
			return {
				...state,
				gameEventError: action.error,
				isGameEventError: true,
			}
		case MANUAL_FINALIZE_FIGHT_SUCCESS:
			return {
				...state,
				isManualFinalizeFightError: false,
				manualFinalizeFightError: "",
			}
		case MANUAL_FINALIZE_FIGHT_FAILED:
			return {
				...state,
				isManualFinalizeFightError: true,
				manualFinalizeFightError: action.error,
			}
		case JACK_UP_FIGHT_SUCCESS:
			return {
				...state,
				isJackUpFightError: false,
				jackUpFightError: "",
			}
		case JACK_UP_FIGHT_FAILED:
			return {
				...state,
				isJackUpFightError: true,
				jackUpFightError: action.error,
			}
		default:
			return state;
	}
}
