/**
 * Results Page
 *
 * @author EverardOnggon
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import Moment from "react-moment";
import { Grid } from "@material-ui/core";
import {
    ResultHistory,
    ResultHistoryCircles,
    DragonTailsScoreboard,
} from "../../components/Dashboard";
import { HeaderWithBackButton, Notification } from "../../components/Common";
import { getEventDetails } from "../../actions/GameEventActions";
import { game } from "../../constants/Game";

class ResultsPage extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			currentEventDetails: [],
			meronCount: 0,
			walaCount: 0,
			drawCount: 0,
            cancelledCount: 0,
            eventTitle: "",
            eventDateTime: null,
			// Notification
			openSnackbar: false,
			snackbarMessage: "",
			snackbarSeverity: "",
		};
	}

	componentDidMount() {
		this._isMounted = true;

		this.getEventDetails();
	}

	componentWillUnmount() {
		this._isMounted = false;
    }
    
    getEventDetails = () => {
		if (this._isMounted) {
            let pathName = window.location.pathname;
            let eventId = pathName.split("/", 4);
            
			this.props.getEventDetails(eventId[3]).then(() => {
				if (!this.props.isGameEventError) {
					this.setState({
						currentEventDetails: this.props.currentEventDetails,
						meronCount:
							this.props.currentEventDetails &&
							this.props.currentEventDetails.resultsHistory
								? this.props.currentEventDetails.resultsHistory
										.winningBetHistories[game.MERON - 1]
										.totalWins
								: 0,
						walaCount:
							this.props.currentEventDetails &&
							this.props.currentEventDetails.resultsHistory
								? this.props.currentEventDetails.resultsHistory
										.winningBetHistories[game.WALA - 1]
										.totalWins
								: 0,
						drawCount:
							this.props.currentEventDetails &&
							this.props.currentEventDetails.resultsHistory
								? this.props.currentEventDetails.resultsHistory
										.totalDraws
								: 0,
						cancelledCount:
							this.props.currentEventDetails &&
							this.props.currentEventDetails.resultsHistory
								? this.props.currentEventDetails.resultsHistory
										.totalCancels
                                : 0,
                        eventTitle: this.props.eventTitle,
                        eventDateTime: this.props.eventDateTime,
					});
				} else {
                    if (this.props.gameEventError === "401") {
                        this.setState({
							snackbarMessage: "Session expired. Logging out.",
							snackbarSeverity: "error",
							openSnackbar: true,
						},
						() => {
							setTimeout(() => {
								this.props.handleLogout();
							}, 1000);
						});
                    } else {
                        this.setState({
                            currentEventDetails: [],
                            meronCount: 0,
                            walaCount: 0,
                            drawCount: 0,
                            cancelledCount: 0,
                            eventTitle: "",
                            eventDateTime: null,
                        });
                    }
				}
			});
		}
	};

	render() {
		return (
			<div className="container">
				<Notification
					open={this.state.openSnackbar}
					onClose={this.handleCloseSnackbar}
					message={this.state.snackbarMessage}
					severity={this.state.snackbarSeverity}
				/>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<HeaderWithBackButton
							title={this.state.eventTitle}
							subTitle={
								this.state.eventDateTime !== null ? (
									<Moment format="YYYY-MM-DD hh:mm A">
										{this.state.eventDateTime}
									</Moment>
								) : (
									""
								)
							}
							buttonLabel="Back"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
                        <ResultHistory
							meronCount={this.state.meronCount}
							walaCount={this.state.walaCount}
							drawCount={this.state.drawCount}
                            cancelledCount={this.state.cancelledCount}
							fights={
								this.state.currentEventDetails.totalFights > 0
									? this.state.currentEventDetails.fights
									: []
                            }
                            tableHeight={400}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <DragonTailsScoreboard
									fights={
                                        this.state.currentEventDetails.totalFights > 0
											? this.state.currentEventDetails.fights
											: []
									}
								/>
                            </Grid>
                            <Grid item xs={12}>
                                <ResultHistoryCircles
									fights={
										this.state.currentEventDetails.totalFights > 0
											? this.state.currentEventDetails.fights
											: []
										}
								/>
                            </Grid>
                        </Grid>
					</Grid>
				</Grid>
			</div>
		);
	}
}

const mapStatetoProps = ({ gameEvent }) => {
	const {
		gameEventError,
		isGameEventError,
		currentEventDetails,
        eventTitle,
        eventDateTime,
	} = gameEvent;

	return {
		gameEventError,
		isGameEventError,
		currentEventDetails,
        eventTitle,
        eventDateTime,
	};
};

export default connect(mapStatetoProps, {
	getEventDetails,
})(ResultsPage);
