/**
 * Operator Dashboard Page
 *
 * @author EverardOnggon
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withStyles, Grid, Button, Backdrop, Switch } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import {
	VideoStream,
	LiveBetting,
	FightControls,
	ResultHistory,
	VideoTitle,
	ResultHistoryCircles,
	DragonTailsScoreboard,
} from "../../components/Dashboard";
import { API_URL } from "../../actions/types";
import { store } from "../../store";
import { game, gameId } from "../../constants/Game";
import { ConfirmationDialog, Notification, FixedNotification, ResultConfirmationDialog } from "../../components/Common";
import {
	CreateEvent,
	EditEvent,
	BroadcastAnnouncement,
} from "../../components/Operator";
import {
	createEvent,
	closeEvent,
	getEventDetails,
	getCurrentEventByGameId,
	getCurrentFightByEventId,
	getVideoURL,
	editEvent,
} from "../../actions/GameEventActions";
import { checkToken } from "../../actions/UserActions";

// Add CSS styles
const useStyles = (theme) => ({
	createEventButton: {
		borderRadius: "20%",
	},
});

class OperatorDashboardPage extends Component {
	_isMounted = false;
	stompClient = null;
	sock = null;

	sock = new SockJS(
		`${API_URL}/websocket?platformTenantId=figbaBets&access_token=${
			store.getState().auth.AccessToken
		}`
	);

	constructor(props) {
		super(props);

		this.state = {
			isCreateEventOpen: false,
			isCloseEventConfirmationDialog: false,
			eventTitle: "",
			videoStreamUrl: "",
			createEventError: false,
			closeEventError: false,
			createEventMessage: "",
			closeEventMessage: "",
			fights: [],
			// canCreateEvent: false,
			closeEventDisabled: false,
			currentEventDetails: [],
			// fight controls
			currentFightDetails: [],
			inputFightNumber: "",
			isCreateFightConfirmationDialog: false,
			isCreateFightDisabled: true,
			// results
			currentFightResult: null,
			currentFightResultText: "-",
			isFightResultConfirmationDialog: false,
			meronCount: 0,
			walaCount: 0,
			drawCount: 0,
			cancelledCount: 0,
			// Snackbar
			openSnackbar: false,
			snackbarMessage: "",
			snackbarSeverity: "",
			// Fixed Snackbar
			openFixedSnackbar: false,
			fixedSnackbarTitle: "",
			fixedSnackbarMessage: "",
			fixedSnackbarSeverity: "",
			// edit event
			isEditEventOpen: false,
			editEventError: false,
			editEventMessage: "",
			editTitle: "",
			editVideoUrl: "",
			// Announcement
			announcement: "",
			// fight result loader
			resultLoader: false,
			// edit fight
			isUpdateFightConfirmationDialog: false,
			// toggle video
			showFightVideo: false,
		};
	}

	componentDidMount() {
		this._isMounted = true;

		// Get if there is any current event on-going
		this.getEventDetails(null);

		// Get Current Fight by Event ID
		// setTimeout(() => {
		// 	this.getFightDetails();
		// }, 700);

		OperatorDashboardPage.stompClient = Stomp.over(this.sock);
		OperatorDashboardPage.stompClient.debug = null;

		// connect to websocket
		this.sock.onopen = function() {
			console.log("SabunganLive open");
		};

		OperatorDashboardPage.stompClient.connect(
			{},
			this.onConnected,
			this.onConnectionError
		);
	}

	componentWillUnmount() {
		if (this._isMounted && store.getState().auth.AccessToken) {
			OperatorDashboardPage.stompClient.disconnect(this.onDisconnected());
		}

		this._isMounted = false;
	}

	onConnected = () => {
		OperatorDashboardPage.stompClient.subscribe(
			"/topic/gamevent",
			this.onEventChangesReceived
		);
		OperatorDashboardPage.stompClient.subscribe(
			"/topic/gamefight",
			this.onFightChangesReceived
		);
		OperatorDashboardPage.stompClient.subscribe(
			"/user/queue/gamefight/error",
			this.onSubscribeError
		);
	};

	onDisconnected = () => {
		if (OperatorDashboardPage.stompClient.status === 'CONNECTED') {
			OperatorDashboardPage.stompClient.unsubscribe("/topic/gamevent");
			OperatorDashboardPage.stompClient.unsubscribe("/topic/gamefight");
			OperatorDashboardPage.stompClient.unsubscribe("/user/queue/gamefight/error");
		}
	};

	onConnectionError = (error) => {
		console.log("Connection error:", error);
		this.setState({
			openFixedSnackbar: true,
			fixedSnackbarTitle: "Connection Error",
			fixedSnackbarMessage: "Please refresh your page or re-login.",
			fixedSnackbarSeverity: "error",
		});
	};

	onSubscribeError = (error) => {
		console.log("Subscribe error:", error);
		let error_message = JSON.parse(error.body);
		this.setState({
			openFixedSnackbar: true,
			fixedSnackbarTitle: null,
			fixedSnackbarMessage: error_message.error_description,
			fixedSnackbarSeverity: "error",
			resultLoader: false,
		});
	};

	onEventChangesReceived = (payload) => {
		var event = JSON.parse(payload.body);
		this.updateEventDetailsFromWebSocket(event);
	};

	onFightChangesReceived = (payload) => {
		var fight = JSON.parse(payload.body);
		this.updateFightDetailsFromWebSocket(fight);

		if (fight.result !== undefined) {
			let result = "-"
			if (fight.result.draw) {
				result = "DRAW";
			} else if (fight.result.cancel) {
				result = "CANCELLED";
			} else if (fight.result.winningBetPayout) {
				result = fight.result.winningBetPayout.betOption.value.toUpperCase();
			}

			this.setState({
				currentFightResultText: result,
			});
		}
	};

	createEvent = (e) => {
		e.preventDefault();
		const payload = {
			gameId: gameId.COCK_FIGHT, // fixed to cock fight for now
			title: this.state.eventTitle,
			venue: "",
			videoUrl: "-", //this.state.videoStreamUrl,
		};

		if (payload.title.trim() === "" || payload.videoUrl.trim() === "") {
			this.setState({
				createEventMessage: "Please input required fields.",
				createEventError: true,
			});
		} else {
			this.props.createEvent(payload).then(() => {
				if (!this.props.isGameEventError) {
					this.getEventDetails(this.props.eventId);

					this.setState({
						createEventMessage: "",
						createEventError: false,
						isCreateEventOpen: false,
						// reset results
						meronCount: 0,
						walaCount: 0,
						drawCount: 0,
						cancelledCount: 0,
						// snackbar
						openSnackbar: true,
						snackbarMessage: "Event created successfully",
						snackbarSeverity: "success",
						// Fight
						isCreateFightDisabled: false,
					});
				} else {
					this.setState({
						createEventMessage: this.props.gameEventError
							? this.props.gameEventError
							: "Server error. Please try again.",
						createEventError: true,
					});
				}
			});
		}
	};

	editEvent = (e) => {
		e.preventDefault();

		const payload = {
			title: this.state.editTitle,
			venue: "",
			videoUrl: "-", //this.state.editVideoUrl,
		};

		if (payload.title.trim() === "" || payload.videoUrl.trim() === "") {
			this.setState({
				editEventMessage: "Please input required fields.",
				editEventError: true,
			});
		} else {
			this.props
				.editEvent(payload, this.state.currentEventDetails.id)
				.then(() => {
					if (!this.props.isGameEventError) {
						this.setState(
							{
								isEditEventOpen: false,
								editEventError: false,
								editEventMessage: "",
								// snackbar
								openSnackbar: true,
								snackbarMessage:
									"Event updated successfully. Please refresh if you changed the video URL.",
								snackbarSeverity: "success",
							},
							() => {
								this.getEventDetails(this.state.currentEventDetails.id);
							}
						);
					} else {
						if (this.props.gameEventError === "401") {
							this.setState({
								editEventMessage:
									"Session expired. Please re-login.",
								editEventError: true,
							});
						} else {
							this.setState({
								editEventMessage: this.props.gameEventError
									? this.props.gameEventError
									: "Server error. Please try again.",
								editEventError: true,
							});
						}
					}
				});
		}
	};

	closeEvent = (e) => {
		e.preventDefault();

		if (this.props.eventId) {
			this.props.closeEvent(this.props.eventId).then(() => {
				if (!this.props.isGameEventError) {
					this.setState({
						isCloseEventConfirmationDialog: false,
						// canCreateEvent: true,
						closeEventDisabled: true,
						closeEventError: false,
						eventTitle: "",
						currentEventDetails: [],
						closeEventMessage: "",
						// reset results
						meronCount: 0,
						walaCount: 0,
						drawCount: 0,
						cancelledCount: 0,
						// fight
						isCreateFightDisabled: true,
					});
				} else {
					this.setState({
						closeEventError: true,
						closeEventMessage: this.props.gameEventError
							? this.props.gameEventError
							: "Server error. Please try again.",
					});
				}
			});
		} else {
			this.setState({
				closeEventError: true,
				closeEventMessage:
					"Cannot close the event. Please try to re-login then try again.",
			});
		}
	};

	getEventDetails = (eventId) => {
		if (eventId) {
			this.props.getEventDetails(eventId).then(() => {
				if (!this.props.isGameEventError) {

					let meronCount = 0;
					let walaCount = 0;
					let drawCount = 0; 
					let cancelledCount = 0;

					if ( this.props.currentEventDetails && this.props.currentEventDetails.resultsHistory) {
						cancelledCount = this.props.currentEventDetails.resultsHistory.totalCancels;
						for (var i = 0; i < this.props.currentEventDetails.resultsHistory.winningBetHistories.length; i++) {
							if ( this.props.currentEventDetails.resultsHistory.winningBetHistories[i].betOption.id === game.MERON ) {
								meronCount = this.props.currentEventDetails.resultsHistory.winningBetHistories[i].totalWins;
							} else if ( this.props.currentEventDetails.resultsHistory.winningBetHistories[i].betOption.id === game.WALA ) {
								walaCount = this.props.currentEventDetails.resultsHistory.winningBetHistories[i].totalWins;
							} else if ( this.props.currentEventDetails.resultsHistory.winningBetHistories[i].betOption.id === game.DRAW ) {
								drawCount = this.props.currentEventDetails.resultsHistory.winningBetHistories[i].totalWins;
							}
						}
					}

					drawCount = drawCount + this.props.currentEventDetails.resultsHistory.totalDraws;

					this.setState({
						// canCreateEvent: false,
						closeEventDisabled: false,
						currentEventDetails: this.props.currentEventDetails,
						meronCount: meronCount,
						walaCount: walaCount,
						drawCount: drawCount,
						cancelledCount: cancelledCount,
						videoStreamUrl: this.props.currentEventDetails
							? this.props.currentEventDetails.videoUrl
							: "",
						eventTitle: this.props.currentEventDetails
							? this.props.currentEventDetails.title
							: "",
						announcement: this.props.currentEventDetails
							? this.props.currentEventDetails.announcement
							: "",
						showFightVideo: this.props.currentEventDetails
							? this.props.currentEventDetails.videoStatus.id === game.DOWN ? false : true
							: false,
					});
				} else {
					this.setState({
						// canCreateEvent: true,
						closeEventDisabled: true,
						currentEventDetails: [],
						meronCount: 0,
						walaCount: 0,
						drawCount: 0,
						cancelledCount: 0,
						videoStreamUrl: "",
						announcement: "",
						showFightVideo: false,
						// fight
						isCreateFightDisabled: true,
					});
				}
			});
		} else {
			this.props.getCurrentEventByGameId(gameId.COCK_FIGHT).then(() => {
				if (!this.props.isGameEventError) {

					let meronCount = 0;
					let walaCount = 0;
					let drawCount = 0; 
					let cancelledCount = 0;

					if ( this.props.currentEventDetails && this.props.currentEventDetails.resultsHistory) {
						cancelledCount = this.props.currentEventDetails.resultsHistory.totalCancels;
						for (var i = 0; i < this.props.currentEventDetails.resultsHistory.winningBetHistories.length; i++) {
							if ( this.props.currentEventDetails.resultsHistory.winningBetHistories[i].betOption.id === game.MERON ) {
								meronCount = this.props.currentEventDetails.resultsHistory.winningBetHistories[i].totalWins;
							} else if ( this.props.currentEventDetails.resultsHistory.winningBetHistories[i].betOption.id === game.WALA ) {
								walaCount = this.props.currentEventDetails.resultsHistory.winningBetHistories[i].totalWins;
							} else if ( this.props.currentEventDetails.resultsHistory.winningBetHistories[i].betOption.id === game.DRAW ) {
								drawCount = this.props.currentEventDetails.resultsHistory.winningBetHistories[i].totalWins;
							}
						}
					}
					
					this.setState({
						// canCreateEvent: false,
						closeEventDisabled: false,
						currentEventDetails: this.props.currentEventDetails,
						meronCount: meronCount,
						walaCount: walaCount,
						drawCount: drawCount,
						cancelledCount: cancelledCount,
						videoStreamUrl: this.props.currentEventDetails
							? this.props.currentEventDetails.videoUrl
							: "",
						eventTitle: this.props.currentEventDetails
							? this.props.currentEventDetails.title
							: "",
						announcement: this.props.currentEventDetails
							? this.props.currentEventDetails.announcement
							: "",
						showFightVideo: this.props.currentEventDetails
							? this.props.currentEventDetails.videoStatus && this.props.currentEventDetails.videoStatus.id === game.DOWN ? false : true
							: false,
					}, () => {
						this.getFightDetails(); // get fight details after getting the event details
					});
				} else {
					this.setState({
						// canCreateEvent: true,
						closeEventDisabled: true,
						currentEventDetails: [],
						meronCount: 0,
						walaCount: 0,
						drawCount: 0,
						cancelledCount: 0,
						videoStreamUrl: "",
						eventTitle: "",
						announcement: "",
						showFightVideo: false,
						// fight
						isCreateFightDisabled: true,
					});

					this.props.getVideoURL(gameId.COCK_FIGHT).then(() => {
						if (!this.props.isGameEventError) {
							this.setState({
								videoStreamUrl: this.props.videoUrl,
							});
						}
					});
				}
			});
		}
	};

	getFightDetails = () => {
		if (
			this.props.currentEventDetails &&
			this.props.currentEventDetails.id
		) {
			this.props
				.getCurrentFightByEventId(this.props.currentEventDetails.id)
				.then(() => {
					if (!this.props.isGameEventError) {
						let fightResult = 0;
						let isCreateFightDisabled = true;
						if (
							this.props.currentFightDetails.result &&
							this.props.currentFightDetails.status.id ===
								game.FINALIZED
						) {
							isCreateFightDisabled = false;

							if (this.props.currentFightDetails.result.cancel ) {
								fightResult = game.CANCELLED;
							} else if (this.props.currentFightDetails.result.draw ) {
								fightResult = game.DRAW;
							} else {
								fightResult = this.props.currentFightDetails.result.winningBetPayout.betOption.id;
							}
						}
						this.setState({
							currentFightDetails: this.props.currentFightDetails,
							inputFightNumber: this.props.currentFightDetails.fightNo,
							currentFightResult: fightResult,
							currentFightResultText: game.FIGHT_RESULT[fightResult],
							isCreateFightDisabled: isCreateFightDisabled,
						});
					} else {
						this.setState({
							inputFightNumber: 0,
							meronCount: 0,
							walaCount: 0,
							drawCount: 0,
							cancelledCount: 0,
							isCreateFightDisabled: false,
						});
					}
				});
		}
	};

	updateEventDetailsFromWebSocket = (eventDetails) => {
		let meronCount = 0;
		let walaCount = 0;
		let drawCount = 0;
		let cancelledCount = eventDetails.resultsHistory.totalCancels;

		for (var i = 0; i < eventDetails.resultsHistory.winningBetHistories.length; i++) {
			if ( eventDetails.resultsHistory.winningBetHistories[i].betOption.id === game.MERON ) {
				meronCount = eventDetails.resultsHistory.winningBetHistories[i].totalWins;
			} else if ( eventDetails.resultsHistory.winningBetHistories[i].betOption.id === game.WALA ) {
				walaCount = eventDetails.resultsHistory.winningBetHistories[i].totalWins;
			} else if ( eventDetails.resultsHistory.winningBetHistories[i].betOption.id === game.DRAW ) {
				drawCount = eventDetails.resultsHistory.winningBetHistories[i].totalWins;
			} 
		}

		drawCount = drawCount + eventDetails.resultsHistory.totalDraws;
		
		this.setState({
			currentEventDetails: eventDetails,
			meronCount: meronCount,
			walaCount: walaCount,
			drawCount: drawCount,
			cancelledCount: cancelledCount,
			videoStreamUrl: eventDetails
				? eventDetails.videoUrl
				: this.state.videoStreamUrl,
			eventTitle: eventDetails ? eventDetails.title : "",
			showFightVideo: eventDetails.videoStatus.id === game.DOWN ? false : true,
		});
	};

	updateFightDetailsFromWebSocket = (fightDetails) => {
		this.setState({
			currentFightDetails: fightDetails,
			inputFightNumber: fightDetails.fightNo,
			isCreateFightDisabled:
				fightDetails.status.id === game.FINALIZED ? false : true,
			resultLoader: false,
		});
	};

	toggleCreateEventDialog = (isOpen) => () => {
		this.setState({
			isCreateEventOpen: isOpen,
		});
	};

	toggleEditEventDialog = (isOpen) => () => {
		this.setState({
			isEditEventOpen: isOpen,
		});

		if (isOpen) {
			this.setState({
				editTitle: this.state.currentEventDetails.title,
				editVideoUrl: this.state.videoStreamUrl,
			});
		}
	};

	toggleCloseEventConfirmationDialog = (isOpen) => () => {
		this.setState({
			isCloseEventConfirmationDialog: isOpen,
		});
	};

	handleEventChange = (event) => {
		const { name, value } = event.target;
		this.setState({
			[name]: value,
		});
	};

	handleInputFightNumberChange = (event) => {
		this.setState({
			inputFightNumber: event.target.value,
		});
	};

	createFight = () => {
		try {
			if (this.state.inputFightNumber) {
				const gameFightPayload = {
					gameEventId: this.props.currentEventDetails.id,
					fightNo: this.state.inputFightNumber,
					event: "CREATING",
				};
				OperatorDashboardPage.stompClient.send(
					"/appsocket/gamefight/create",
					{},
					JSON.stringify(gameFightPayload)
				);
				this.setState({
					currentFightResult: null,
					currentFightResultText: "-",
					isCreateFightConfirmationDialog: false,
				});

				// handle token refresh
				this.handleTokenRefresh();
			} else {
				this.setState({
					openFixedSnackbar: true,
					fixedSnackbarTitle: null,
					fixedSnackbarMessage: "Enter a valid fight number.",
					fixedSnackbarSeverity: "error",
					isCreateFightConfirmationDialog: false,
				});
			}
		} catch (error) {
			this.setState({
				openFixedSnackbar: true,
				fixedSnackbarTitle: null,
				fixedSnackbarMessage: "Creating a fight failed. Please try again.",
				fixedSnackbarSeverity: "error",
				isCreateFightConfirmationDialog: false,
			});
		}
	};

	updateFight = () => {
		try {
			let previousFightNo = this.props.currentFightDetails
			.fightNo;
			if (this.state.inputFightNumber) {
				const gameFightPayload = {
					gameFightId: this.state.currentFightDetails.id,
					fightNo: this.state.inputFightNumber,
					event: "UPDATING",
				};
				OperatorDashboardPage.stompClient.send(
					"/appsocket/gamefight/update",
					{},
					JSON.stringify(gameFightPayload)
				);
				this.setState({
					isUpdateFightConfirmationDialog: false,
					// snackbar
					openSnackbar: true,
					snackbarMessage: "Fight No. is update from " + previousFightNo + " to " + this.state.inputFightNumber + " .",
					snackbarSeverity: "success",
				});

				// handle token refresh
				this.handleTokenRefresh();
			} else {
				this.setState({
					openFixedSnackbar: true,
					fixedSnackbarTitle: null,
					fixedSnackbarMessage: "Enter a valid fight number.",
					fixedSnackbarSeverity: "error",
					isUpdateFightConfirmationDialog: false,
				});
			}

		} catch (error) {
			this.setState({
				openFixedSnackbar: true,
				fixedSnackbarTitle: null,
				fixedSnackbarMessage: "Editing fight failed. Please try again.",
				fixedSnackbarSeverity: "error",
				isUpdateFightConfirmationDialog: false,
			});
		}
	};

	toggleVideo = () => () => {
		
		try {
			let newFightVideoStatus = !this.state.showFightVideo;
			const videoStatusPayload = {
				gameEventId: this.props.currentEventDetails.id,
				status: newFightVideoStatus ? game.UP : game.DOWN,
				event: "TOGGLE",
			};
			OperatorDashboardPage.stompClient.send(
				`/appsocket/gamevent/videostatus/${this.props.eventId}`,
				{},
				JSON.stringify(videoStatusPayload)
			);
			this.setState({
				isUpdateFightConfirmationDialog: false,
				// snackbar
				openSnackbar: true,
				snackbarMessage: "Video is now " + (newFightVideoStatus ? "up" : "down") + ".",
				snackbarSeverity: "success",
				showFightVideo: newFightVideoStatus,
			});

			// handle token refresh
			this.handleTokenRefresh();

		} catch (error) {
			this.setState({
				openFixedSnackbar: true,
				fixedSnackbarTitle: null,
				fixedSnackbarMessage: "Error in updating video status.",
				fixedSnackbarSeverity: "error",
				isUpdateFightConfirmationDialog: false,
			});
		}
	};

	setBettingStatus = (status) => () => {
		OperatorDashboardPage.stompClient.send(
			`/appsocket/gamefight/update/${this.state.currentFightDetails.id}/${status}`,
			{},
			{}
		);

		// handle token refresh
		this.handleTokenRefresh();
	};

	handleFightResult = (result) => () => {
		this.setState({
			currentFightResult: result,
			isFightResultConfirmationDialog: true,
		});
	};

	setFightResult = () => {
		if (this.state.currentFightResult) {
			let winningBetOption = null;
			let isDraw = false;
			let isCancelled = false;

			if ( this.state.currentFightResult === game.MERON || 
				this.state.currentFightResult === game.WALA ||
				this.state.currentFightResult === game.DRAW ) {
				winningBetOption = this.state.currentFightResult;
			} else if (this.state.currentFightResult === game.CANCELLED) {
				isCancelled = true;
			} 
			
			if (this.state.currentFightResult === game.DRAW) {
				isDraw = true;
			}

			const resultPayload = {
				winningBetOption: winningBetOption,
				draw: isDraw,
				cancelled: isCancelled,
			};

			OperatorDashboardPage.stompClient.send(
				`/appsocket/gamefight/finalize/${this.state.currentFightDetails.id}`,
				{},
				JSON.stringify(resultPayload)
			);

			this.setState({
				// currentFightResultText: this.state.currentFightResult
				// 	? game.FIGHT_RESULT[this.state.currentFightResult]
				// 	: "-",
				isFightResultConfirmationDialog: false,
				resultLoader: true,
			});

			// handle token refresh
			this.handleTokenRefresh();
		}
	};

	broadcastAnnouncement = (e) => {
		e.preventDefault();

		const announcementPayload = {
			announcement: this.state.announcement
				? this.state.announcement
				: "",
		};
		OperatorDashboardPage.stompClient.send(
			`/appsocket/gamevent/announcement/${this.props.eventId}`,
			{},
			JSON.stringify(announcementPayload)
		);
	};

	toggleFightResultConfirmationDialog = (isOpen) => () => {
		this.setState({
			isFightResultConfirmationDialog: isOpen,
		});
	};

	toggleCreateFightConfirmationDialog = (isOpen) => () => {
		this.setState({
			isCreateFightConfirmationDialog: isOpen,
		});
	};

	toggleUpdateFightConfirmationDialog = (isOpen) => () => {
		this.setState({
			isUpdateFightConfirmationDialog: isOpen,
		});
	};

	handleCloseSnackbar = () => {
		this.setState({
			openSnackbar: false,
		});
	};

	handleCloseFixedSnackbar = () => {
		this.setState({
			openFixedSnackbar: false,
		});
	};

	handleTokenRefresh = () => {
		this.props.checkToken().then(() => {
			if (this.props.isTokenError) {
				if (this.props.tokenError === "401") {
					// if the token is already expired/invalid, refresh the token.
					this.props.handleTokenRefresh();
				} else {
					// do nothing on other errors.
				}
			} else {
				// nothing to do.
			}
		});
	}

	render() {
		return (
			<div className="container">
				<Backdrop style={{color: "#FFFFFF", zIndex: 2}} open={this.state.resultLoader}>
					<CircularProgress color="inherit" />
				</Backdrop>
				<CreateEvent
					isOpen={this.state.isCreateEventOpen}
					onClose={this.toggleCreateEventDialog}
					onCreate={this.createEvent}
					onChange={this.handleEventChange}
					error={this.state.createEventError}
					message={this.state.createEventMessage}
					videoStreamUrl={this.state.videoStreamUrl}
				/>
				<EditEvent
					isOpen={this.state.isEditEventOpen}
					onClose={this.toggleEditEventDialog}
					onEdit={this.editEvent}
					onChange={this.handleEventChange}
					error={this.state.editEventError}
					message={this.state.editEventMessage}
					videoStreamUrl={this.state.editVideoUrl}
					eventTitle={this.state.editTitle}
				/>
				<ConfirmationDialog
					isOpen={this.state.isCloseEventConfirmationDialog}
					title="Close Event"
					message="Are you sure you want to close the current event?"
					onOk={this.closeEvent}
					onClose={this.toggleCloseEventConfirmationDialog}
					error={this.state.closeEventError}
					errorMessage={this.state.closeEventMessage}
				/>
				<ResultConfirmationDialog
					isOpen={this.state.isFightResultConfirmationDialog}
					title="Fight Result"
					result={this.state.currentFightResult}
					resultText={game.FIGHT_RESULT[this.state.currentFightResult]}
					onOk={this.setFightResult}
					onClose={this.toggleFightResultConfirmationDialog}
				/>
				<ConfirmationDialog
					isOpen={this.state.isCreateFightConfirmationDialog}
					title="Start New Fight"
					message={`You are about to create Fight No. ${this.state.inputFightNumber}. Are you sure you want to continue?`}
					onOk={this.createFight}
					onClose={this.toggleCreateFightConfirmationDialog}
				/>
				<ConfirmationDialog
					isOpen={this.state.isUpdateFightConfirmationDialog}
					title="Edit Current Fight"
					message={`WARNING: You are about to update current Fight No. ${this.state.currentFightDetails.fightNo} to ${this.state.inputFightNumber}. Are you sure you want to continue?`}
					onOk={this.updateFight}
					onClose={this.toggleUpdateFightConfirmationDialog}
				/>
				<Notification
					open={this.state.openSnackbar}
					onClose={this.handleCloseSnackbar}
					message={this.state.snackbarMessage}
					severity={this.state.snackbarSeverity}
				/>
				<FixedNotification
					open={this.state.openFixedSnackbar}
					onClose={this.handleCloseFixedSnackbar}
					title={this.state.fixedSnackbarTitle}
					message={this.state.fixedSnackbarMessage}
					severity={this.state.fixedSnackbarSeverity}
				/>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Grid container spacing={0}>
							<Grid
								item
								xs={12}
								sm={12}
								md={11}
								lg={11}
								xl={10}
								className="mx-auto"
							>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Button
											variant="contained"
											className="button btn-primary"
											style={{ marginRight: 10 }}
											onClick={this.toggleCreateEventDialog(
												true
											)}
										>
											Create Event
										</Button>
										<Button
											variant="contained"
											className="button btn-danger"
											disabled={
												this.state.closeEventDisabled
											}
											onClick={this.toggleCloseEventConfirmationDialog(
												true
											)}
										>
											Close Event
										</Button>

										<Switch 
											checked={this.state.showFightVideo} 
											onChange={this.toggleVideo()} />
									</Grid>
									<Grid item xs={12} sm={12} md={7} lg={7}>
										<Grid container spacing={3}>
											<Grid item xs={12}>
												{/* {this.state.videoStreamUrl !==
												"" ? ( */}
												<VideoTitle
													isOperator={true}
													title={
														this.state
															.currentEventDetails
															? this.state
																	.currentEventDetails
																	.title
															: ""
													}
													source={JSON.stringify({
														src: this.state
															.videoStreamUrl,
														type:
															"application/x-mpegURL",
													})}
													onEdit={
														this
															.toggleEditEventDialog
													}
												/>
												<VideoStream
													id="operatorPlayer"
													source={JSON.stringify({
														src: this.state
															.videoStreamUrl,
														type:
															"application/x-mpegURL",
													})}
													showFightVideo={this.state.showFightVideo}
												/>
												{/* ) : (
													<div
														style={{
															textAlign: "center",
															width: "100%",
															height: 250,
															paddingTop: "20%",
														}}
													>
														<CircularProgress />
													</div>
												)} */}
											</Grid>
											<Grid item xs={12}>
												<BroadcastAnnouncement
													message={
														this.state.announcement
													}
													handleAnnouncement={
														this
															.broadcastAnnouncement
													}
													handleChange={
														this.handleEventChange
													}
												/>
											</Grid>
											<Grid item xs={12}>
												<LiveBetting
													betOptionPayouts={
														this.state
															.currentFightDetails
															.betOptionPayouts
													}
													showActualPlayerBets={false}
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={12} md={5} lg={5}>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<FightControls
													fightNo={
														this.state
															.inputFightNumber
													}
													fightDetails={
														this.state
															.currentFightDetails
													}
													fightResult={
														this.state
															.currentFightResultText
													}
													isCreateFightDisabled={
														this.state
															.isCreateFightDisabled
													}
													isCurrentEventNotEmpty={
														this.state.currentEventDetails !== undefined && this.state.currentEventDetails.length !== 0 &&
														!(Object.keys(this.state.currentEventDetails).length === 0 && this.state.currentEventDetails.constructor === Object)
													}
													onChange={
														this
															.handleInputFightNumberChange
													}
													createFight={
														this
															.toggleCreateFightConfirmationDialog
													}
													updateFight={
														this
															.toggleUpdateFightConfirmationDialog
													}
													setBettingStatus={
														this.setBettingStatus
													}
													setFightResult={
														this.handleFightResult
													}
												/>
											</Grid>
											<Grid item xs={12}>
												<ResultHistory
													meronCount={
														this.state.meronCount
													}
													walaCount={
														this.state.walaCount
													}
													drawCount={
														this.state.drawCount
													}
													cancelledCount={
														this.state
															.cancelledCount
													}
													fights={
														this.state
															.currentEventDetails
															.totalFights > 0
															? this.state
																	.currentEventDetails
																	.fights
															: []
													}
												/>
											</Grid>
											<Grid item xs={12}>
												<DragonTailsScoreboard
													fights={
														this.state
															.currentEventDetails
															.totalFights > 0
															? this.state
																	.currentEventDetails
																	.fights
															: []
													}
												/>
											</Grid>
											<Grid item xs={12}>
												<ResultHistoryCircles
													fights={
														this.state
															.currentEventDetails
															.totalFights > 0
															? this.state
																	.currentEventDetails
																	.fights
															: []
													}
												/>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
		);
	}
}

const mapStatetoProps = ({ gameEvent, user }) => {
	const {
		eventId,
		gameEventError,
		isGameEventError,
		currentEventDetails,
		currentFightDetails,
		videoUrl,
	} = gameEvent;
	const { isTokenError, tokenError } = user;

	return {
		eventId,
		gameEventError,
		isGameEventError,
		currentEventDetails,
		currentFightDetails,
		videoUrl,
		isTokenError,
		tokenError,
	};
};

export default compose(
	withStyles(useStyles),
	connect(mapStatetoProps, {
		createEvent,
		closeEvent,
		getEventDetails,
		getCurrentEventByGameId,
		getCurrentFightByEventId,
		getVideoURL,
		editEvent,
		checkToken,
	})
)(OperatorDashboardPage);
