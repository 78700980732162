/**
 * Events History Page
 *
 * @author EverardOnggon
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { gameId } from "../../constants/Game";
import { getEventsByGameId } from "../../actions/GameEventActions";
import { EventsList } from "../../components/EventsList";
import { Notification } from "../../components/Common";

class EventsHistoryPage extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            eventsList: [],
            page: 0,
            size: 10,
            totalCount: 0,
            eventsListMessage: '',
            error: false,
            // Snackbar
			openSnackbar: false,
			snackbarMessage: "",
			snackbarSeverity: "error",
        };
    }

    componentDidMount() {
        this._isMounted = true;

        this.getEventsListByGameId();
	}

	componentWillUnmount() {
		this._isMounted = false;
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        },
        () => {
            this.getEventsListByGameId();
        });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({
            size: event.target.value ? parseInt(event.target.value) : 10,
            page: 0,
        },
        () => {
            this.getEventsListByGameId();
        });
    };

    getEventsListByGameId = () => {
        this.props.getEventsByGameId(gameId.COCK_FIGHT, this.state.page, this.state.size).then(() => {
            if (!this.props.isGameEventError && this.props.events) {
                this.setState({
                    eventsList: this.props.events,
                    totalCount: this.props.totalCount,
                    eventsListMessage: this.props.totalCount > 0 ? "" : "No events found",
                    error: false,
                });
            } else {
                if (this.props.gameEventError === "401") {
                    this.setState({
                        snackbarMessage: "Session expired. Logging out.",
                        snackbarSeverity: "error",
                        openSnackbar: true,
                    },
                    () => {
                        setTimeout(() => {
                            this.props.handleLogout();
                        }, 1000);
                    });
                } else {
                    this.setState({
                        eventsListMessage: this.props.gameEventError ? this.props.gameEventError : "Server failed. Please try again.",
                        totalCount: 0,
                        error: true,
                    });
                }
            }
        });
    };

    render() {
        return (
            <div className="container">
                <Notification
					open={this.state.openSnackbar}
					onClose={this.handleCloseSnackbar}
					message={this.state.snackbarMessage}
					severity={this.state.snackbarSeverity}
				/>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <EventsList
                            events={this.state.eventsList}
                            totalCount={this.state.totalCount}
                            page={this.state.page}
                            size={this.state.size}
                            handleChangePage={this.handleChangePage}
                            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                            error={this.state.error}
                            eventsListMessage={this.state.eventsListMessage}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStatetoProps = ({ gameEvent }) => {
    const { events, totalCount, gameEventError, isGameEventError} = gameEvent
    
    return {
        events,
        totalCount,
        gameEventError,
        isGameEventError,
    };
};

export default connect(mapStatetoProps, { getEventsByGameId })(EventsHistoryPage);