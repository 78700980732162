/**
 * Layout: Footer
 */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core/styles";
import AppConfig from '../../constants/AppConfig';
import BanksImg from '../../assets/images/banks.png';
import PadalaImg from '../../assets/images/money-remittance.png';
import ETransfersImg from '../../assets/images/electronic-transfers.png';

const useStyles = makeStyles((theme) => ({
	footerWrapper: {
      color: "#ffffff",
   },
   footerTopWrapper: {
      borderTop: "1px solid #ffffff",
   },
   footerBottomWrapper: {
      padding: "2.1875rem 0",
   },
   footerText: {
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      color: "#ffffff",
   },
}));

const Footer = () => {
    const styles = useStyles();

   return (
        <footer className={`pt-50 ${styles.footerWrapper} bg-steel`}>
          {/* <div className={styles.footerTopWrapper}>
              <div className="container mt-10">
                  <Grid container spacing={0} className="d-flex justify-content-center align-items-center">
                      <Grid item xs={11} sm={11} md={10} lg={8} xl={7}>
                          <img src={BanksImg} alt="BanksImg"/>
                    </Grid>
                  <Grid item xs={11} sm={11} md={10} lg={8} xl={7}>
                     <img src={PadalaImg} alt="PadalaImg"/>
                  </Grid>
                  <Grid item xs={11} sm={11} md={10} lg={8} xl={7}>
                     <img src={ETransfersImg} alt="ETransfersImg"/>
                  </Grid>
               </Grid>
            </div>
         </div> */}
         <div className={styles.footerBottomWrapper}>
            <div className="container">
               <Grid container >
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                     <div className="text-center">
                        <p className={`mb-0 ${styles.footerText}`}>{AppConfig.CopyrightText}</p>
                     </div>
                  </Grid>
               </Grid>
            </div>
         </div>
      </footer>
   )
};

export default Footer;
