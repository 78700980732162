/**
 * Result History Circles Row Component
 *
 * @author EverardOnggon
 */

import React, { Component } from "react";
import { game } from "../../../../constants/Game";

export default class ResultHistoryCirclesRow extends Component {
	render() {
		return (
			<td style={{width: 35, verticalAlign: 'top'}}>
				{this.props.fights.map((row, index) => {
					let color = "#ffffff";
	
					if (row.result) {
						if (row.result.cancel) {
							color = "#ffc107";
						} else if (row.result.draw) {
							color = "#28a745";
						} else {
							if (row.result.winningBetPayout.betOption.id === game.MERON) {
								color = "#da1c1c";
							} else if (row.result.winningBetPayout.betOption.id === game.WALA) {
								color = "#1231ea";
							} else if (row.result.winningBetPayout.betOption.id === game.DRAW) {
								color = "#28a745";
							}
						}
						
						return (
							<div
								style={{
									borderRadius: '50%',
									height: 30,
									width: 30,
									fontSize: '0.65rem',
									color: '#FFFFFF',
									lineHeight: 3,
									textAlign: 'center',
									margin: "auto",
									marginBottom: 5,
									padding: 0,
									backgroundColor: `${color}`
								}}
								key={index}
							>
								{row.fightNo}
							</div>
						);
					} else {
						return null;
					}
				})}
			</td>
		);
	}
};