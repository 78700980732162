import { config } from "../config";

export const {
	API,
	API_URL,
	API_JSON_HEADER,
	API_URL_ENCODED_HEADER,
	API_JSON_FORM_HEADER,
	CLIENT_ID,
	GRANT_TYPE,
	CLIENT_SECRET,
	REFRESH_TOKEN,
} = config;

// Auth Actions
export const AUTH_LOGIN_SUCCESS = "auth_login_success";
export const AUTH_LOGIN_INVALID_CREDENTIALS = "auth_login_invalid_credentials";
export const AUTH_LOGIN_FAILED = "auth_login_failed";
export const AUTH_CHANGE_PASS_SUCCESS = "auth_change_pass_success";
export const AUTH_CHANGE_PASS_INVALID_CREDENTIALS =
	"auth_change_pass_invalid_credentials";
export const AUTH_CHANGE_PASS_INVALID_PASSWORD =
	"auth_change_pass_invalid_password";
export const AUTH_CHANGE_PASS_FAILED = "auth_change_pass_failed";
export const AUTH_LOGOUT_SUCCESS = "auth_logout_success";
export const AUTH_LOGOUT_FAILED = "auth_logout_failed";
export const AUTH_FORGOT_PASSWORD_REQUEST = "auth_forgot_password_request";
export const AUTH_FORGOT_PASSWORD_REQUEST_FAIL =
	"auth_forgot_password_request_fail";
export const AUTH_FORGOT_PASSWORD_REQUEST_INVALID_EMAIL =
	"auth_forgot_password_request_invalid_email";
export const UNEXPECTED_ERROR_FORGOT_PASSWORD =
	"unexpected_error_forgot_password";
export const AUTH_FORGOT_PASSWORD_SUCCESS = "auth_forgot_password_success";
export const AUTH_FORGOT_PASSWORD_INVALID_NEW_PASS_FORMAT =
	"auth_forgot_password_invalid_new_pass_format";
export const AUTH_FORGOT_PASSWORD_MISSING_DETAILS =
	"auth_forgot_password_missing_details";
export const AUTH_FORGOT_PASSWORD_USER_DOES_NOT_EXIST =
	"auth_forgot_password_user_does_not_exist";
export const REFRESH_TOKEN_SUCCESS = "refresh_token_success";
export const REFRESH_TOKEN_FAILED = "refresh_token_failed";

// Register Actions
export const REGISTER_SUCCESS = "register_success";
export const REGISTER_FAILED = "register_failed";
export const REGISTER_USER_EXISTS = "register_user_exists";

// Create User Actions
export const CREATE_USER_SUCCESS = "create_user_success";
export const CREATE_USER_FAILED = "create_user_failed";
export const CREATE_USER_EXISTS = "create_user_exists";
export const UPDATE_USER_SUCCESS = "update_user_success";
export const UPDATE_USER_FAILED = "update_user_failed";

// User Actions
export const GET_USER_DETAILS_SUCCESS = "get_user_details_success";
export const GET_USER_DETAILS_FAILED = "get_user_details_failed";
export const CHANGE_PASSWORD_SUCCESS = "change_password_success";
export const CHANGE_PASSWORD_FAILED = "change_password_failed";
export const TOKEN_VALID = "token_valid";
export const TOKEN_INVALID = "token_invalid";

// Game Event Actions
export const CREATE_EVENT_SUCCESS = "create_event_success";
export const CREATE_EVENT_FAILED = "create_event_failed";
export const CLOSE_EVENT_SUCCESS = "close_event_success";
export const CLOSE_EVENT_FAILED = "close_event_failed";
export const GET_EVENTS_BY_GAME_SUCCESS = "get_events_by_game_success";
export const GET_EVENTS_BY_GAME_FAILED = "get_events_by_game_failed";
export const GET_EVENT_DETAILS_SUCCESS = "get_event_details_success";
export const GET_EVENT_DETAILS_FAILED = "get_event_details_failed";
export const GET_CURRENT_EVENT_BY_GAME_SUCCESS =
	"get_current_evennt_by_game_success";
export const GET_CURRENT_EVENT_BY_GAME_FAILED =
	"get_current_evennt_by_game_failed";
export const GET_VIDEO_URL_SUCCESS = "get_video_url_success";
export const GET_VIDEO_URL_FAILED = "get_video_url_failed";
export const EDIT_EVENT_SUCCESS = "edit_event_success";
export const EDIT_EVENT_FAILED = "edit_event_failed";
export const GET_EVENT_DETAILS_BY_IDS_SUCCESS = "get_event_details_by_ids_success";
export const GET_EVENT_DETAILS_BY_IDS_FAILED = "get_event_details_by_ids_failed";
export const MANUAL_FINALIZE_FIGHT_SUCCESS = "manual_finalize_fight_success";
export const MANUAL_FINALIZE_FIGHT_FAILED = "manual_finalize_fight_failed";
export const JACK_UP_FIGHT_SUCCESS = "jack_up_fight_success";
export const JACK_UP_FIGHT_FAILED = "jack_up_fight_failed";

// Game Fight Actions
export const GET_CURRENT_FIGHT_BY_EVENT_SUCCESS =
	"get_game_fight_details_by_event_success";
export const GET_CURRENT_FIGHT_BY_EVENT_FAILED =
	"get_game_fight_details_by_event_failed";

// Player Actions
export const GET_PLAYERS_LIST_SUCCESS = "get_players_list_success";
export const GET_PLAYERS_LIST_FAILED = "get_players_list_failed";
export const GET_PLAYER_SUCCESS = "get_player_success";
export const GET_PLAYER_FAILED = "get_player_failed";
export const GET_TRANSACTION_TYPES_SUCCESS = "get_transaction_types_success";
export const GET_TRANSACTION_TYPES_FAILED = "get_transaction_types_failed";
export const MONEY_TRANSACTION_SUCCESS = "money_transaction_success";
export const MONEY_TRANSACTION_FAILED = "money_transaction_failed";
export const GET_PLAYER_BALANCE_SUCCESS = "get_player_balance_success";
export const GET_PLAYER_BALANCE_FAILED = "get_player_balance_failed";
export const GET_BET_SUMMARY_SUCCESS = "get_bet_summary_success";
export const GET_BET_SUMMARY_FAILED = "get_bet_summary_failed";
export const REACTIVATE_USER_SUCCESS = "reactivate_user_success";
export const REACTIVATE_USER_FAILED = "reactivate_user_failed";
export const DEACTIVATE_USER_SUCCESS = "deactivate_user_success";
export const DEACTIVATE_USER_FAILED = "deactivate_user_failed";
export const DELETE_USER_SUCCESS = "delete_user_success";
export const DELETE_USER_FAILED = "delete_user_failed";
export const MAKE_PLAYER_BET_SUCCESS = "make_player_bet_success";
export const MAKE_PLAYER_BET_FAILED = "make_player_bet_failed";

// Transaction Actions
export const GET_TRANSACTIONS_LIST_SUCCESS = "get_transactions_list_success";
export const GET_TRANSACTIONS_LIST_FAILED = "get_transactions_list_failed";
export const GET_MONEY_TRANSACTION_TYPES_SUCCESS = "get_money_transaction_types_success";
export const GET_MONEY_TRANSACTION_TYPES_FAILED = "get_money_transaction_types_failed";

// System Performance Actions
export const GET_SYSTEM_PROFIT_SUCCESS = "get_system_profit_success";
export const GET_SYSTEM_PROFIT_FAILED = "get_system_profit_failed";

// System Config Actions
export const GET_SYSTEM_CONFIG_SUCCESS = "get_system_config_success";
export const GET_SYSTEM_CONFIG_FAILED = "get_system_config_failed";
export const UPDATE_SYSTEM_CONFIG_SUCCESS = "update_system_config_success";
export const UPDATE_SYSTEM_CONFIG_FAILED = "update_system_config_failed";

// Reports
export const GET_REBATES_REPORT_SUCCESS = "get_rebates_report_success";
export const GET_REBATES_REPORT_FAILED = "get_rebates_report_failed";
export const GET_SYSTEM_PROFIT_REPORT_SUCCESS = "get_system_profit_report_success";
export const GET_SYSTEM_PROFIT_REPORT_FAILED = "get_system_profit_report_failed";
export const GET_BETS_REPORT_SUCCESS = "get_bets_report_success";
export const GET_BETS_REPORT_FAILED = "get_bets_report_failed";