/**
 * Authentication Actions
 *
 * @author EverardOnggon
 */

import axios from "axios";
import {
	API,
	API_URL,
	API_URL_ENCODED_HEADER,
	AUTH_LOGIN_SUCCESS,
	AUTH_LOGIN_FAILED,
	AUTH_LOGIN_INVALID_CREDENTIALS,
	AUTH_LOGOUT_SUCCESS,
	AUTH_LOGOUT_FAILED,
	REFRESH_TOKEN_SUCCESS,
	REFRESH_TOKEN_FAILED,
} from "./types";
import { store } from "../store";

export const getHeaders = async () => {
	const HEADERS = {
		headers: {
			"Content-Type": "application/json; charset=utf-8",
			"Lucky-Online-Platform": "figbaBets",
			Authorization: "Bearer " + store.getState().auth.AccessToken,
		},
	};
	return HEADERS;
};

export const getFormHeaders = async () => {
	const FORM_HEADERS = {
		headers: {
			accept: "*/*",
			"Content-Type": "multipart/form-data",
			"Lucky-Online-Platform": "figbaBets",
			Authorization: "Bearer " + store.getState().auth.AccessToken,
		},
	};
	return FORM_HEADERS;
};

export const login = (payload) => async (dispatch) => {
	try {
		const loginFormData = new URLSearchParams();
		loginFormData.append("username", payload.username);
		loginFormData.append("password", payload.password);
		loginFormData.append("client_id", payload.client_id);
		loginFormData.append("grant_type", payload.grant_type);
		loginFormData.append("client_secret", payload.client_secret);

		const response = await axios.post(`${API}/oauth/token`, loginFormData, {
			headers: API_URL_ENCODED_HEADER,
		});
		if (response.status === 200) {
			dispatch({
				type: AUTH_LOGIN_SUCCESS,
				payload: response.data,
			});
		}
	} catch (error) {
		switch (error.response.status) {
			case 400:
			case 401:
				dispatch({
					type: AUTH_LOGIN_INVALID_CREDENTIALS,
					error: "Invalid username and/or password",
				});
				break;
			default:
				dispatch({
					type: AUTH_LOGIN_FAILED,
					error: error.response.data.error_description,
				});
		}
	}
};

export const logout = () => async (dispatch) => {
	try {
		const payload = {
			accessToken: store.getState().auth.AccessToken,
		};
		const response = await axios.post(
			`${API_URL}/user/logout`,
			payload,
			await getHeaders()
		);
		if (response.status === 200 || response.status === 201) {
			dispatch({
				type: AUTH_LOGOUT_SUCCESS,
			});
		}
	} catch (error) {
		dispatch({
			type: AUTH_LOGOUT_FAILED,
			error: error.response ? error.response.data.message : '',
		});
	}
};

export const refreshToken = (payload) => async (dispatch) => {
	try {
		const refreshData = new URLSearchParams();
		refreshData.append("client_id", payload.client_id);
		refreshData.append("grant_type", payload.grant_type);
		refreshData.append("client_secret", payload.client_secret);
		refreshData.append("refresh_token", payload.refresh_token);

		const response = await axios.post(`${API}/oauth/token`, refreshData, {
			headers: API_URL_ENCODED_HEADER,
		});

		if (response.status === 200 || response.status === 201) {
			dispatch({
				type: REFRESH_TOKEN_SUCCESS,
				payload: response.data,
			});
		}
	} catch (error) {
		dispatch({
			type: REFRESH_TOKEN_FAILED,
			error: "error refresh token",
		});
	}
};
