/**
 * User Transactions Page
 *
 * @author EverardOnggon
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import {
    Grid,
} from "@material-ui/core";
import { BalanceSummary, TransactionsList, EventsListForUsersList } from "../../components";
import { Notification, HeaderWithBackButton } from "../../components/Common";
import { getTransactionsByUserId, getMoneyTransactionTypes } from "../../actions/TransactionActions";
import { getOtherPlayerById } from "../../actions/OtherPlayerActions";
import { getEventsByGameId } from "../../actions/GameEventActions";
import { gameId } from "../../constants/Game";

class UserTransactionsPage extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			totalBalance: 0,
			totalDeposits: 0,
			totalWithdrawals: 0,
			totalHolds: 0,
			totalPlayerBets: 0,
			totalShareRebates: 0,
			transactions: [],
			page: 0,
			size: 10,
			totalCount: 0,
            error: "",
            // User Info
            name: "",
            username: "",
            playerId: null,
            shareRebatePercentage: 0,
            // Event
            eventsList: [],
            eventsListError: false,
            eventsListMessage: "",
			// Snackbar
			openSnackbar: false,
			snackbarMessage: "",
            snackbarSeverity: "error",
            // Transaction Types
			transactionTypes: [],
			selectedTransactionTypes: [],
			dateFrom: "",
			dateTo: "",
		};
	}

	componentDidMount() {
		this._isMounted = true;

        this.getMoneyTransactionTypes();
        this.getOtherPlayerById();
        this.getTransactionsByUserId();
        this.getEventsListByGameId();
	}

	componentWillUnmount() {
		this._isMounted = false;
    }
    
    getOtherPlayerById = () => {
        let pathName = window.location.pathname;
        let id = pathName.split("/", 4);

        this.props.getOtherPlayerById(id[3]).then(() => {
            if (!this.props.isOtherPlayerError) {
				this.setState({
                    name: this.props.otherUserProfile.firstName + " " + this.props.otherUserProfile.lastName,
                    username: this.props.otherUserProfile.username,
                    playerId: this.props.otherUserProfile.player ? this.props.otherUserProfile.player.id : null,
                    shareRebatePercentage: this.props.otherUserProfile.shareRebatePercentage,
                });
			} else {
				if (this.props.otherPlayerError === "401") {
					this.setState(
						{
							openSnackbar: true,
							snackbarMessage: "Session expired. Logging out.",
							snackbarSeverity: "error",
						},
						() => {
							setTimeout(() => {
								this.props.handleLogout();
							}, 1000);
						}
					);
				} else {
					this.setState({
						openSnackbar: true,
						snackbarMessage: this.props.otherPlayerError
							? this.props.otherPlayerError
							: "Server error. Please try again.",
						snackbarSeverity: "error",
					});
				}
			}
        })
    }

	getTransactionsByUserId = () => {
        let pathName = window.location.pathname;
        let id = pathName.split("/", 4);

        let transactionTypes = "";
		if (this.state.selectedTransactionTypes) {
			transactionTypes = this.state.selectedTransactionTypes.join(",");
		}
        
		this.props.getTransactionsByUserId(id[3], this.state.page, this.state.size, transactionTypes, this.state.dateFrom, this.state.dateTo).then(() => {
			if (!this.props.isTransactionError && this.props.totalBalance >= 0) {
				this.setState({
					totalBalance: this.props.totalBalance,
					totalDeposits: this.props.totalDeposits,
					totalWithdrawals: this.props.totalWithdrawals,
					totalHolds: this.props.totalHolds,
					totalPlayerBets: this.props.totalPlayerBets,
					totalShareRebates: this.props.totalShareRebates,
					transactions: this.props.transactions,
					totalCount: this.props.totalCount,
					error: "",
				});
			} else {
				if (this.props.transactionError === "401") {
					this.setState({
						// Snackbar
						openSnackbar: true,
						snackbarMessage: "Session expired. Logging out.",
						snackbarSeverity: "error",
					},
					() => {
						setTimeout(() => {
							this.props.handleLogout();
						}, 1000);
					});
				} else {
					this.setState({
						totalBalance: 0,
						totalDeposits: 0,
						totalWithdrawals: 0,
						totalHolds: 0,
						totalPlayerBets: 0,
						totalShareRebates: 0,
						transactions: [],
						page: 0,
						size: 10,
						totalCount: 0,
						error: this.props.transactionError ? this.props.transactionError : "Server error. Please try again.",
					});
				}
			}
		})
    }
    
    getMoneyTransactionTypes = () => {
		this.props.getMoneyTransactionTypes().then(() => {
			if (!this.props.isMoneyTransactionTypesError) {
				this.setState({
					transactionTypes: this.props.moneyTransactionTypes,
					error: "",
				})
			} else {
				if (this.props.moneyTransactionTypesError === "401") {
					this.setState({
						// Snackbar
						openSnackbar: true,
						snackbarMessage: "Session expired. Logging out.",
						snackbarSeverity: "error",
					},
					() => {
						setTimeout(() => {
							this.props.handleLogout();
						}, 1000);
					});
				} else {
					this.setState({
						error: this.props.moneyTransactionTypesError ? this.props.moneyTransactionTypesError : "Server error. Please try again.",
					})
				}
			}
		})
	}

	handleTransactionTypesChange = (event) => {
		event.persist();
		this.setState({
			[event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value,
		});
	}

	handleDateFilterChange = (event) => {
		event.persist();
		this.setState({
			[event.target.name]: event.target.value,
		});
	}

	handleFilter = () => {
		this.setState({
			page: 0,
		}, () => {
			this.getTransactionsByUserId();
		})
	}

	handleChangePage = (event, newPage) => {
		this.setState(
			{
				page: newPage,
			},
			() => {
				this.getTransactionsByUserId();
			}
		);
	};

	handleChangeRowsPerPage = (event) => {
		this.setState(
			{
				size: event.target.value ? parseInt(event.target.value) : 10,
				page: 0,
			},
			() => {
				this.getTransactionsByUserId();
			}
		);
    };
    
    getEventsListByGameId = () => {
        this.props.getEventsByGameId(gameId.COCK_FIGHT, 0, 7).then(() => {
            if (!this.props.isGameEventError && this.props.events) {
                this.setState({
                    eventsList: this.props.events,
                    eventsListError: false,
                    eventsListMessage: "",
                });
            } else {
                if (this.props.gameEventError === "401") {
                    this.setState({
                        snackbarMessage: "Session expired. Logging out.",
                        snackbarSeverity: "error",
                        openSnackbar: true,
                    },
                    () => {
                        setTimeout(() => {
                            this.props.handleLogout();
                        }, 1000);
                    });
                } else {
                    this.setState({
                        eventsListMessage: this.props.gameEventError ? this.props.gameEventError : "Server failed. Please try again.",
                        eventsListError: true,
                    })
                }
            }
        });
    };
    
	render() {
		return (
			<div className="container">
				<Notification
					open={this.state.openSnackbar}
					onClose={this.handleCloseSnackbar}
					message={this.state.snackbarMessage}
					severity={this.state.snackbarSeverity}
				/>
				<Grid container spacing={2}>
                    <Grid item xs={12}>
						<HeaderWithBackButton
							title="User Transactions/Bets Logs"
							subTitle={this.state.name + " (" + this.state.username + ")"}
							buttonLabel="Back"
						/>
					</Grid>
					<Grid item xs={12}>
						<BalanceSummary
							totalBalance={this.state.totalBalance}
							totalDeposits={this.state.totalDeposits}
							totalWithdrawals={this.state.totalWithdrawals}
							totalHolds={this.state.totalHolds}
							totalPlayerBets={this.state.totalPlayerBets}
                            totalShareRebates={this.state.totalShareRebates}
                            shareRebatePercentage={this.state.shareRebatePercentage}
						/>
					</Grid>
                    <Grid item xs={12}>
                        <TransactionsList
                            transactions={this.state.transactions}
                            totalCount={this.state.totalCount}
                            page={this.state.page}
                            size={this.state.size}
                            handleChangePage={this.handleChangePage}
                            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                            transactionTypes={this.state.transactionTypes}
							selectedTransactionTypes={this.state.selectedTransactionTypes}
							handleTransactionTypesChange={this.handleTransactionTypesChange}
							handleDateFilterChange={this.handleDateFilterChange}
							handleFilter={this.handleFilter}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <EventsListForUsersList
                            events={this.state.eventsList}
                            error={this.state.eventsListError}
                            eventsListMessage={this.state.eventsListMessage}
                            playerId={this.state.playerId}
                        />
                    </Grid>
				</Grid>
			</div>
		);
	}
}

const mapStatetoProps = ({ transaction, otherPlayer, gameEvent }) => {
    const { totalBalance, totalDeposits, totalWithdrawals, totalHolds, totalPlayerBets, totalShareRebates, transactions, totalCount, transactionError, isTransactionError, moneyTransactionTypes, moneyTransactionTypesError, isMoneyTransactionTypesError } = transaction;
    const { otherUserProfile,isOtherPlayerError, otherPlayerError } = otherPlayer;
    const { events, gameEventError, isGameEventError } = gameEvent;

	return {
		totalBalance,
		totalDeposits,
		totalWithdrawals,
		totalHolds,
		totalPlayerBets,
		totalShareRebates,
		transactions,
		totalCount,
		transactionError,
        isTransactionError,
        otherUserProfile,
        isOtherPlayerError,
        otherPlayerError,
        events,
        gameEventError,
        isGameEventError,
        moneyTransactionTypes,
        moneyTransactionTypesError,
        isMoneyTransactionTypesError,
	};
};

export default connect(mapStatetoProps, {getTransactionsByUserId, getMoneyTransactionTypes, getOtherPlayerById, getEventsByGameId})(UserTransactionsPage);
