/**
 * Video Title Component
 *
 * @author EverardOnggon
 */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
	editButton: {
		border: "2px solid #ffffff",
		color: "#ffffff",
		padding: 3,
		margin: "3px 15px",
	},
	title: {
		backgroundColor: "#424242",
		color: "#ffffff",
		fontSize: theme.typography.h6.fontSize,
		[theme.breakpoints.down("sm")]: {
			fontSize: theme.typography.body1.fontSize,
		},
	}
}));

const VideoTitle = (props) => {
	const styles = useStyles();
	let editButton;
	// let videoUrl = "";
	if (props.isOperator) {
		editButton = (
			<IconButton
				className={styles.editButton}
				onClick={props.onEdit(true)}
			>
				<EditIcon />
			</IconButton>
		);
		// const jsonSource = JSON.parse(props.source);
		// videoUrl = "Video Source: " + jsonSource.src;
	}

	return (
		<div>
			<Typography
				className={`text-center ${styles.title}`}
			>
				{props.title}
				{editButton}
				<br />
			</Typography>
			{/* <Typography
				variant="body1"
				className="text-center"
				style={{ backgroundColor: "#424242", color: "#ffffff" }}
			>
				{videoUrl}
			</Typography> */}
		</div>
	);
};

export { VideoTitle };
