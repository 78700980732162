/**
 * Fight Details Component
 *
 * @author EverardOnggon
 */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, Typography } from "@material-ui/core";
import { game } from "../../../constants/Game";

const useStyles = makeStyles((theme) => ({
	blackPaperBackground: {
		backgroundColor: "#424242",
		color: "#ffffff",
		padding: "20px",
		borderRadius: 3,
		[theme.breakpoints.down("xs")]: {
			padding: "20px 5px",
		},
	},
	fightNumber: {
		fontWeight: "bold",
		color: "#da1c1c",
	},
}));

const FightDetails = ({ fightDetails }) => {
	const styles = useStyles();
    let status = "---";
    let result = "---";

	if (fightDetails && fightDetails.status) {
		if (fightDetails.status.id === game.FINALIZED) {
			status = "Closed";
		} else {
			status = fightDetails.status.value;
		}
    }
    
    if (fightDetails && fightDetails.result) {
        if (fightDetails.result.draw) {
            result = "Draw";
        } else if (fightDetails.result.cancel) {
            result = "Cancelled";
        } else if (fightDetails.result.winningBetPayout) {
            result = fightDetails.result.winningBetPayout.betOption.value;
        }
    }

	return (
		<Paper className={styles.blackPaperBackground}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Grid container spacing={0}>
						<Grid item xs={12}>
							<Typography
								variant="h5"
								component="h3"
								className="text-center"
								style={{
									color: "#ffffff",
									marginBottom: 10,
									paddingBottom: 5,
									borderBottom: "1px solid #ffffff",
								}}
							>
								Fight Details
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography
								variant="h5"
								component="h3"
								style={{
									color: "#ffffff",
									marginTop: "5%",
								}}
							>
								Fight #{" "}
                                <span
									id="fightNumber"
									className={styles.fightNumber}
								>
									{fightDetails !== undefined ? fightDetails.fightNo : "---"}
								</span>
							</Typography>
						</Grid>
					</Grid>
					<Grid container spacing={0}>
						<Grid item xs={12}>
							<Typography
								variant="h5"
								component="h3"
								style={{
									color: "#ffffff",
									marginTop: 15,
									marginBottom: 15,
								}}
							>
								Betting Status:{" "}
								<span
									id="bettingStatus"
									className={styles.fightNumber}
								>
									{status}
								</span>
							</Typography>
						</Grid>
					</Grid>
					<Grid container spacing={0}>
						<Grid item xs={12}>
							<Typography
								variant="h5"
								component="h3"
								style={{
									color: "#ffffff",
									marginBottom: 15,
								}}
							>
								Fight Result:{" "}
								<span
									id="fightResult"
									className={styles.fightNumber}
								>
									{result}
								</span>
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
};

export { FightDetails };
