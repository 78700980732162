/**
 * Transactions Page
 *
 * @author EverardOnggon
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { BalanceSummary, TransactionsList } from "../../components";
import { Notification } from "../../components/Common";
import { getTransactionsByUserId, getMoneyTransactionTypes } from "../../actions/TransactionActions";

class TransactionsPage extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			totalBalance: 0,
			totalDeposits: 0,
			totalWithdrawals: 0,
			totalHolds: 0,
			totalPlayerBets: 0,
			totalShareRebates: 0,
			transactions: [],
			page: 0,
			size: 10,
			totalCount: 0,
			error: "",
			// Snackbar
			openSnackbar: false,
			snackbarMessage: "",
			snackbarSeverity: "error",
			// Share Rebate %
			shareRebatePercentage: 0,
			// Transaction Types
			transactionTypes: [],
			selectedTransactionTypes: [],
			dateFrom: "",
			dateTo: "",
		};
	}

	componentDidMount() {
		this._isMounted = true;

		this.getMoneyTransactionTypes();
		this.getTransactionsByUserId();
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	getTransactionsByUserId = () => {
		let transactionTypes = "";
		if (this.state.selectedTransactionTypes) {
			transactionTypes = this.state.selectedTransactionTypes.join(",");
		}

		this.props.getTransactionsByUserId(this.props.userId, this.state.page, this.state.size, transactionTypes, this.state.dateFrom, this.state.dateTo).then(() => {
			if (!this.props.isTransactionError && this.props.totalBalance >= 0) {
				this.setState({
					totalBalance: this.props.totalBalance,
					totalDeposits: this.props.totalDeposits,
					totalWithdrawals: this.props.totalWithdrawals,
					totalHolds: this.props.totalHolds,
					totalPlayerBets: this.props.totalPlayerBets,
					totalShareRebates: this.props.totalShareRebates,
					transactions: this.props.transactions,
					totalCount: this.props.totalCount,
					error: "",
					shareRebatePercentage: this.props.shareRebatePercentage,
				});
			} else {
				if (this.props.transactionError === "401") {
					this.setState({
						// Snackbar
						openSnackbar: true,
						snackbarMessage: "Session expired. Logging out.",
						snackbarSeverity: "error",
					},
					() => {
						setTimeout(() => {
							this.props.handleLogout();
						}, 1000);
					});
				} else {
					this.setState({
						totalBalance: 0,
						totalDeposits: 0,
						totalWithdrawals: 0,
						totalHolds: 0,
						totalPlayerBets: 0,
						totalShareRebates: 0,
						transactions: [],
						page: 0,
						size: 10,
						totalCount: 0,
						error: this.props.transactionError ? this.props.transactionError : "Server error. Please try again.",
						shareRebatePercentage: 0,
					});
				}
			}
		})
	}

	getMoneyTransactionTypes = () => {
		this.props.getMoneyTransactionTypes().then(() => {
			if (!this.props.isMoneyTransactionTypesError) {
				this.setState({
					transactionTypes: this.props.moneyTransactionTypes,
					error: "",
				})
			} else {
				if (this.props.moneyTransactionTypesError === "401") {
					this.setState({
						// Snackbar
						openSnackbar: true,
						snackbarMessage: "Session expired. Logging out.",
						snackbarSeverity: "error",
					},
					() => {
						setTimeout(() => {
							this.props.handleLogout();
						}, 1000);
					});
				} else {
					this.setState({
						error: this.props.moneyTransactionTypesError ? this.props.moneyTransactionTypesError : "Server error. Please try again.",
					})
				}
			}
		})
	}

	handleTransactionTypesChange = (event) => {
		event.persist();
		this.setState({
			[event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value,
		});
	}

	handleDateFilterChange = (event) => {
		event.persist();
		this.setState({
			[event.target.name]: event.target.value,
		});
	}

	handleFilter = () => {
		this.setState({
			page: 0,
		}, () => {
			this.getTransactionsByUserId();
		})
	}

	handleChangePage = (event, newPage) => {
		this.setState(
			{
				page: newPage,
			},
			() => {
				this.getTransactionsByUserId();
			}
		);
	};

	handleChangeRowsPerPage = (event) => {
		this.setState(
			{
				size: event.target.value ? parseInt(event.target.value) : 10,
				page: 0,
			},
			() => {
				this.getTransactionsByUserId();
			}
		);
	};
    
	render() {
		return (
			<div className="container">
				<Notification
					open={this.state.openSnackbar}
					onClose={this.handleCloseSnackbar}
					message={this.state.snackbarMessage}
					severity={this.state.snackbarSeverity}
				/>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<BalanceSummary
							totalBalance={this.state.totalBalance}
							totalDeposits={this.state.totalDeposits}
							totalWithdrawals={this.state.totalWithdrawals}
							totalHolds={this.state.totalHolds}
							totalPlayerBets={this.state.totalPlayerBets}
							totalShareRebates={this.state.totalShareRebates}
							shareRebatePercentage={this.state.shareRebatePercentage}
						/>
					</Grid>
                    <Grid item xs={12}>
                        <TransactionsList
                            transactions={this.state.transactions}
                            totalCount={this.state.totalCount}
                            page={this.state.page}
                            size={this.state.size}
                            handleChangePage={this.handleChangePage}
							handleChangeRowsPerPage={this.handleChangeRowsPerPage}
							transactionTypes={this.state.transactionTypes}
							selectedTransactionTypes={this.state.selectedTransactionTypes}
							handleTransactionTypesChange={this.handleTransactionTypesChange}
							handleDateFilterChange={this.handleDateFilterChange}
							handleFilter={this.handleFilter}
                        />
                    </Grid>
				</Grid>
			</div>
		);
	}
}

const mapStatetoProps = ({ user, transaction }) => {
	const { userId, shareRebatePercentage } = user;
	const { totalBalance, totalDeposits, totalWithdrawals, totalHolds, totalPlayerBets, totalShareRebates, transactions, totalCount, transactionError, isTransactionError, moneyTransactionTypes, moneyTransactionTypesError, isMoneyTransactionTypesError } = transaction;

	return {
		userId,
		shareRebatePercentage,
		totalBalance,
		totalDeposits,
		totalWithdrawals,
		totalHolds,
		totalPlayerBets,
		totalShareRebates,
		transactions,
		totalCount,
		transactionError,
		isTransactionError,
		moneyTransactionTypes,
		moneyTransactionTypesError,
		isMoneyTransactionTypesError,
	};
};

export default connect(mapStatetoProps, {getTransactionsByUserId, getMoneyTransactionTypes})(TransactionsPage);
