/**
 * Game Event Actions
 *
 * @author EverardOnggon
 */

import axios from "axios";
import {
	API_URL,
	CREATE_EVENT_SUCCESS,
	CREATE_EVENT_FAILED,
	CLOSE_EVENT_SUCCESS,
	CLOSE_EVENT_FAILED,
	GET_EVENTS_BY_GAME_SUCCESS,
	GET_EVENTS_BY_GAME_FAILED,
	GET_EVENT_DETAILS_SUCCESS,
	GET_EVENT_DETAILS_FAILED,
	GET_CURRENT_EVENT_BY_GAME_SUCCESS,
	GET_CURRENT_EVENT_BY_GAME_FAILED,
	GET_CURRENT_FIGHT_BY_EVENT_SUCCESS,
	GET_CURRENT_FIGHT_BY_EVENT_FAILED,
	GET_VIDEO_URL_SUCCESS,
	GET_VIDEO_URL_FAILED,
	EDIT_EVENT_SUCCESS,
	EDIT_EVENT_FAILED,
	MANUAL_FINALIZE_FIGHT_SUCCESS,
	MANUAL_FINALIZE_FIGHT_FAILED,
	JACK_UP_FIGHT_SUCCESS,
	JACK_UP_FIGHT_FAILED,
} from "./types";
import { getHeaders } from "./AuthActions";

export const createEvent = (payload) => async (dispatch) => {
	try {
		const response = await axios.post(
			`${API_URL}/game/event`,
			payload,
			await getHeaders()
		);
		if (response.status === 201 || response.status === 200) {
			dispatch({
				type: CREATE_EVENT_SUCCESS,
				data: response.data,
			});
		}
	} catch (error) {
		switch (error.response.status) {
			case 401:
				dispatch({
					type: CREATE_EVENT_FAILED,
					error: error.response.status.toString(),
				});
				break;
			case 400:
			case 403:
			case 404:
				dispatch({
					type: CREATE_EVENT_FAILED,
					error: error.response.data.error_description ? error.response.data.error_description : error.response.data.message,
				});
				break;
			default:
				dispatch({
					type: CREATE_EVENT_FAILED,
					error: error.response.data.message,
				});
		}
	}
};

export const editEvent = (payload, eventId) => async (dispatch) => {
	try {
		const response = await axios.put(
			`${API_URL}/game/event/${eventId}`,
			payload,
			await getHeaders()
		);
		if (response.status === 201 || response.status === 200) {
			dispatch({
				type: EDIT_EVENT_SUCCESS,
				data: response.data,
			});
		}
	} catch (error) {
		switch (error.response.status) {
			case 401:
				dispatch({
					type: EDIT_EVENT_FAILED,
					error: error.response.status.toString(),
				});
				break;
			default:
				dispatch({
					type: EDIT_EVENT_FAILED,
					error: error.response.data.message,
				});
		}
	}
};

export const closeEvent = (eventId) => async (dispatch) => {
	try {
		const response = await axios.post(
			`${API_URL}/game/event/${eventId}`,
			{},
			await getHeaders()
		);
		if (response.status === 200 || response.status === 204) {
			dispatch({
				type: CLOSE_EVENT_SUCCESS,
				data: response.data,
			});
		}
	} catch (error) {
		switch (error.response.status) {
			case 401:
				dispatch({
					type: CLOSE_EVENT_FAILED,
					error: error.response.status.toString(),
				});
				break;
			case 403:
				dispatch({
					type: CLOSE_EVENT_FAILED,
					error: error.response.data.error_description,
				});
				break;
			default:
				dispatch({
					type: CLOSE_EVENT_FAILED,
					error: error.response.data.message,
				});
		}
	}
};

export const getEventDetails = (eventId) => async (dispatch) => {
	try {
		const response = await axios.get(
			`${API_URL}/game/event/${eventId}`,
			await getHeaders()
		);
		if (response.status === 200) {
			dispatch({
				type: GET_EVENT_DETAILS_SUCCESS,
				data: response.data,
			});
		}
	} catch (error) {
		switch (error.response.status) {
			case 401:
				dispatch({
					type: GET_EVENT_DETAILS_FAILED,
					error: error.response.status.toString(),
				});
				break;
			default:
				dispatch({
					type: GET_EVENT_DETAILS_FAILED,
					error: error.response.data.message,
				});
		}
	}
};

export const getEventsByGameId = (gameId, page, size) => async (dispatch) => {
	try {
		const response = await axios.get(`${API_URL}/game/event/under/${gameId}?page=${page}&size=${size}`, await getHeaders());
		if (response.status === 200) {
			dispatch({
				type: GET_EVENTS_BY_GAME_SUCCESS,
				data: response.data,
			});
		}
	} catch (error) {
		switch (error.response.status) {
			case 401:
				dispatch({
					type: GET_EVENTS_BY_GAME_FAILED,
					error: error.response.status.toString(),
				});
				break;
			default:
				dispatch({
					type: GET_EVENTS_BY_GAME_FAILED,
					error: error.response.data.message,
				});
		}
	}
};

export const getCurrentEventByGameId = (gameId) => async (dispatch) => {
	try {
		const response = await axios.get(
			`${API_URL}/game/event/latest/active/under/${gameId}`,
			await getHeaders()
		);
		if (response.status === 200) {
			dispatch({
				type: GET_CURRENT_EVENT_BY_GAME_SUCCESS,
				data: response.data,
			});
		}
	} catch (error) {
		switch (error.response.status) {
			case 401:
				dispatch({
					type: GET_CURRENT_EVENT_BY_GAME_FAILED,
					error: error.response.status.toString(),
				});
				break;
			default:
				dispatch({
					type: GET_CURRENT_EVENT_BY_GAME_FAILED,
					error: error.response.data.message,
				});
		}
	}
};

export const getCurrentFightByEventId = (eventId) => async (dispatch) => {
	try {
		const response = await axios.get(
			`${API_URL}/game/event/fight/current/${eventId}`,
			await getHeaders()
		);
		if (response.status === 200) {
			dispatch({
				type: GET_CURRENT_FIGHT_BY_EVENT_SUCCESS,
				data: response.data,
			});
		}
	} catch (error) {
		switch (error.response.status) {
			case 401:
				dispatch({
					type: GET_CURRENT_FIGHT_BY_EVENT_FAILED,
					error: error.response.status.toString(),
				});
				break;
			default:
				dispatch({
					type: GET_CURRENT_FIGHT_BY_EVENT_FAILED,
					error: error.response.data.message,
				});
		}
	}
};

export const getVideoURL = (gameId) => async (dispatch) => {
	try {
		const response = await axios.get(`${API_URL}/game/event/latest/video/url/${gameId}`, await getHeaders());
		if (response.status === 200 || response.status === 201) {
			dispatch({
				type: GET_VIDEO_URL_SUCCESS,
				data: response.data,
			});
		}
	} catch (error) {
		switch (error.response.status) {
			case 401:
				dispatch({
					type: GET_VIDEO_URL_FAILED,
					error: error.response.status.toString(),
				});
				break;
			default:
				dispatch({
					type: GET_VIDEO_URL_FAILED,
					error: error.response.data.message,
				});
		}
	}
};

export const manuallyFinalizeFight = (gameFightId, payload) => async (dispatch) => {
	try {
		const response = await axios.post(`${API_URL}/game/event/fight/manual/finalize/${gameFightId}`, payload, await getHeaders()
		);
		if (response.status === 200 || response.status === 204) {
			dispatch({
				type: MANUAL_FINALIZE_FIGHT_SUCCESS,
			});
		}
	} catch (error) {
		switch (error.response.status) {
			case 401:
				dispatch({
					type: MANUAL_FINALIZE_FIGHT_FAILED,
					error: error.response.status.toString(),
				});
				break;
			default:
				dispatch({
					type: MANUAL_FINALIZE_FIGHT_FAILED,
					error: error.response.data.message,
				});
		}
	}
};

export const jackUpFight = (gameFightId) => async (dispatch) => {
	try {
		const response = await axios.get(`${API_URL}/game/event/fight/jack-up/${gameFightId}`, await getHeaders());
		if (response.status === 200 || response.status === 204) {
			dispatch({
				type: JACK_UP_FIGHT_SUCCESS,
			});
		}
	} catch (error) {
		switch (error.response.status) {
			case 401:
				dispatch({
					type: JACK_UP_FIGHT_FAILED,
					error: error.response.status.toString(),
				});
				break;
			default:
				dispatch({
					type: JACK_UP_FIGHT_FAILED,
					error: error.response.data.message,
				});
		}
	}
};

