/**
 * Player Reducer
 *
 * @author EverardOnggon
 */

import {
	AUTH_LOGOUT_SUCCESS,
	AUTH_LOGOUT_FAILED,
	GET_PLAYERS_LIST_SUCCESS,
	GET_PLAYERS_LIST_FAILED,
	GET_PLAYER_BALANCE_SUCCESS,
	GET_PLAYER_BALANCE_FAILED,
	GET_BET_SUMMARY_SUCCESS,
	GET_BET_SUMMARY_FAILED,
	MAKE_PLAYER_BET_SUCCESS,
	MAKE_PLAYER_BET_FAILED
} from "../actions/types";

const INITIAL_STATE = {
	players: [],
	totalCount: 0,
	playerError: "",
	isPlayerError: false,
	balanceSummary: {},
	bets: [],
	newHoldsFromBets: 0
};

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case AUTH_LOGOUT_SUCCESS:
		case AUTH_LOGOUT_FAILED:
			return {
				state: undefined,
			};
		case GET_PLAYERS_LIST_SUCCESS:
			return {
				...state,
				players: action.data.content,
				totalCount: action.data.totalElements,
				playerError: "",
				isPlayerError: false,
			};
		case GET_PLAYERS_LIST_FAILED:
			return {
				...state,
				playerError: action.error,
				isPlayerError: true,
			};
		case GET_PLAYER_BALANCE_SUCCESS:
			return {
				...state,
				playerError: "",
				isPlayerError: false,
				balanceSummary: action.data.balanceSummary,
			};
		case GET_PLAYER_BALANCE_FAILED:
			return {
				...state,
				playerError: action.error,
				isPlayerError: true,
				balanceSummary: {},
			};
		case GET_BET_SUMMARY_SUCCESS:
			return {
				...state,
				playerError: "",
				bets: action.data.bets,
			};
		case GET_BET_SUMMARY_FAILED:
			return {
				...state,
				playerError: action.error,
				bets: [],
			};
		case MAKE_PLAYER_BET_SUCCESS:
			return {
				...state,
				playerError: "",
				isPlayerError: false,
				bets: action.data.bets,
				newHoldsFromBets: action.data.totalHoldAmount
			};
		case MAKE_PLAYER_BET_FAILED:
			return {
				...state,
				playerError: action.error,
				isPlayerError: true,
				balanceSummary: {},
				bets: []
			};
		default:
			return state;
	}
}
