/**
 *  Sidebar Menus
 */
/* eslint-disable */
export default [
    {
        "id": 1,
        "icon": "home",
        "title": "Principal (Home)",
        "path": "/dashboard",
        "child_routes": null
    },
    {
        "id": 2,
        "icon": "history",
        "title": "Historial (History)",
        "path": "/history",
        "child_routes": null
    },
    {
        "id": 3,
        "icon": "group",
        "title": "My Players",
        "path": "/my-players",
        "child_routes": null
    },
    {
        "id": 4,
        "icon": "person_search",
        "title": "View Users",
        "path": "/view-users",
        "child_routes": null
    },
    {
        "id": 5,
        "icon": "money",
        "title": "Transacciones (Transactions)",
        "path": "/transactions",
        "child_routes": null
    },
    {
        "id": 6,
        "icon": "assessment",
        "title": "Reports",
        "path": "/reports",
        "child_routes": null
    },
    {
        "id": 7,
        "icon": "request_quote",
        "title": "System Profit",
        "path": "/system-profit",
        "child_routes": null
    },
    {
        "id": 8,
        "icon": "build",
        "title": "Support Tools",
        "path": "/support-tools",
        "child_routes": null
    },
    {
        "id": 9,
        "icon": "vpn_key",
        "title": "Cambiar Contraseña (Change Password)",
        "path": "",
        "child_routes": null
    },
    {
        "id": 10,
        "icon": "exit_to_app",
        "title": "Cerrar Sesión (Logout)",
        "path": "",
        "child_routes": null
    },
]