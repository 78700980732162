/**
 * User Reducer
 *
 * @author EverardOnggon
 */

import {
	AUTH_LOGOUT_SUCCESS,
	AUTH_LOGOUT_FAILED,
	GET_USER_DETAILS_SUCCESS,
	GET_USER_DETAILS_FAILED,
	CHANGE_PASSWORD_SUCCESS,
	CHANGE_PASSWORD_FAILED,
	TOKEN_VALID,
	TOKEN_INVALID,
} from "../actions/types";

const INITIAL_STATE = {
	userId: "",
	firstName: "",
	lastName: "",
	roleId: 0,
	code: "",
	totalBalance: 0,
	totalHolds: 0,
	shareRebatePercentage: 0,
	balanceSummary: {},
	playerId: null,
	userError: "",
	isUserError: false,
	agent: {},
	// for token
	isTokenError: false,
	tokenError: "",
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case AUTH_LOGOUT_SUCCESS:
		case AUTH_LOGOUT_FAILED:
			return {
				state: undefined,
			};
		case GET_USER_DETAILS_SUCCESS:
			return {
				...state,
				userId: action.data.id,
				firstName: action.data.firstName,
				lastName: action.data.lastName,
				balanceSummary: action.data.player
					? action.data.player.balanceSummary
					: {},
				totalBalance: action.data.player
					? action.data.player.balanceSummary.totalBalance
					: 0,
				totalHolds: action.data.player
					? action.data.player.balanceSummary.totalHolds
					: 0,
				shareRebatePercentage: action.data.shareRebatePercentage,
				roleId: action.data.roles[0].id,
				code: action.data.code,
				userError: "",
				isUserError: false,
				playerId: action.data.player ? action.data.player.id : null,
				agent: action.data.player && action.data.player.agent ? action.data.player.agent : {},
			};
		case GET_USER_DETAILS_FAILED:
		case CHANGE_PASSWORD_FAILED:
			return {
				...state,
				userError: action.error,
				isUserError: true,
			};
		case CHANGE_PASSWORD_SUCCESS:
			return {
				...state,
				userError: "",
				isUserError: false,
			};
		case TOKEN_VALID:
			return {
				...state,
				isTokenError: false,
				tokenError: "",
			};
		case TOKEN_INVALID:
			return {
				...state,
				isTokenError: true,
				tokenError: action.error,
			};
		default:
			return state;
	}
}
