/**
 * Reports Page
 *
 * @author EverardOnggon
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { Notification } from "../../components/Common";
import { getRebatesReport, getSystemProfitReport, getBetsReport } from "../../actions/ReportsActions";
import { getUsersList } from "../../actions/PlayerActions";
import { ReportsGraph } from "../../components/Graphs/ReportsGraph";
import { ReportFilter } from "../../components/Graphs/ReportFilter";

class ReportsPage extends Component {
    _isMounted = false;
    today = "";
    lastWeek = "";

	constructor(props) {
        super(props);
        
        // get dates
        const currentDate = new Date();
        let yyyy = currentDate.getFullYear();
        let mm = currentDate.getMonth() + 1;
        let dd = currentDate.getDate();

        mm = mm < 10 ? "0" + mm : mm;
        dd = dd < 10 ? "0" + dd : dd;
        this.today = yyyy + "-" + mm + "-" + dd;

        let weekBefore = new Date();
        weekBefore.setDate(weekBefore.getDate() - 7);
        let wbyyyy = weekBefore.getFullYear();
        let wbmm = weekBefore.getMonth() + 1;
        let wbdd = weekBefore.getDate();

        wbmm = wbmm < 10 ? "0" + wbmm : wbmm;
        wbdd = wbdd < 10 ? "0" + wbdd : wbdd;
        this.lastWeek = wbyyyy + "-" + wbmm + "-" + wbdd;

		this.state = {
            // Filter
            dateFrom: null,
            dateTo: null,
            excludedPlayers: [],
            // Users List
            users: [],
            // Data
            systemProfit: [],
            rebates: [],
            bets: [],
			// Snackbar
			openSnackbar: false,
			snackbarMessage: "",
			snackbarSeverity: "error",
		};
	}

	componentDidMount() {
        this._isMounted = true;

        this.getUsersList();
        this.getRebatesReport();
        this.getSystemProfitReport();
        this.getBetsReport();
	}

	componentWillUnmount() {
		this._isMounted = false;
    }

    getUsersList = () => {
		this.props.getUsersList(this.props.userId, 0, 99999, null).then(() => {
			if (!this.props.isPlayerError) {
				this.setState({
					users: this.props.players,
				});
			} else {
				if (this.props.playerError === "401") {
					this.setState({
						openSnackbar: true,
						snackbarMessage: "Session expired. Logging out.",
						snackbarSeverity: "error",
					}, () => {
						setTimeout(() => {
							this.props.handleLogout();
						}, 1000);
					});
                } else {
                    this.setState({
                        openSnackbar: true,
                        snackbarMessage: this.props.playerError,
                        snackbarSeverity: "error",
                        users: [],
                    });
                }
	    	}
		});
	};

    getRebatesReport = () => {
        let excludedUsernames = [];
        this.state.excludedPlayers.forEach(function(item) {
            excludedUsernames.push(item.userProfile.username);
        });

        const payload = {
            "dateFrom": this.state.dateFrom ? this.state.dateFrom : this.lastWeek,
            "dateTo": this.state.dateTo ? this.state.dateTo : this.today,
            "exludedPlayerUsernames": excludedUsernames,
        };

        this.props.getRebatesReport(payload).then(() => {
            if (!this.props.isRebateReportsError) {
				this.setState({
					rebates: this.props.rebatesReport,
                });
			} else {
				if (this.props.rebateReportsError === "401") {
					this.setState({
						// Snackbar
						openSnackbar: true,
						snackbarMessage: "Session expired. Logging out.",
						snackbarSeverity: "error",
					},
					() => {
						setTimeout(() => {
							this.props.handleLogout();
						}, 1000);
					});
				} else {
					this.setState({
						// Snackbar
						openSnackbar: true,
						snackbarMessage: this.props.rebateReportsError,
						snackbarSeverity: "error",
						rebates: [],
					});
				}
			}
        })
    }

    getSystemProfitReport = () => {
        let excludedUsernames = [];
        this.state.excludedPlayers.forEach(function(item) {
            excludedUsernames.push(item.userProfile.username);
        });

        const payload = {
            "dateFrom": this.state.dateFrom ? this.state.dateFrom : this.lastWeek,
            "dateTo": this.state.dateTo ? this.state.dateTo : this.today,
            "exludedPlayerUsernames": excludedUsernames,
        };

        this.props.getSystemProfitReport(payload).then(() => {
            if (!this.props.isSystemProfitReportsError) {
				this.setState({
					systemProfit: this.props.systemProfitReport,
                });
			} else {
				if (this.props.systemProfitReportsError === "401") {
					this.setState({
						// Snackbar
						openSnackbar: true,
						snackbarMessage: "Session expired. Logging out.",
						snackbarSeverity: "error",
					},
					() => {
						setTimeout(() => {
							this.props.handleLogout();
						}, 1000);
					});
				} else {
					this.setState({
						// Snackbar
						openSnackbar: true,
						snackbarMessage: this.props.systemProfitReportsError,
						snackbarSeverity: "error",
						systemProfit: [],
					});
				}
			}
        })
    }

    getBetsReport = () => {
        let excludedUsernames = [];
        this.state.excludedPlayers.forEach(function(item) {
            excludedUsernames.push(item.userProfile.username);
        });

        const payload = {
            "dateFrom": this.state.dateFrom ? this.state.dateFrom : this.lastWeek,
            "dateTo": this.state.dateTo ? this.state.dateTo : this.today,
            "exludedPlayerUsernames": excludedUsernames,
        };

        this.props.getBetsReport(payload).then(() => {
            if (!this.props.isBetsReportsError) {
				this.setState({
					bets: this.props.betsReport,
                });
			} else {
				if (this.props.betsReportsError === "401") {
					this.setState({
						// Snackbar
						openSnackbar: true,
						snackbarMessage: "Session expired. Logging out.",
						snackbarSeverity: "error",
					},
					() => {
						setTimeout(() => {
							this.props.handleLogout();
						}, 1000);
					});
				} else {
					this.setState({
						// Snackbar
						openSnackbar: true,
						snackbarMessage: this.props.betsReportsError,
						snackbarSeverity: "error",
						bets: [],
					});
				}
			}
        })
    }

    handleDateFilterChange = (event) => {
		event.persist();
		this.setState({
			[event.target.name]: event.target.value,
		});
    }

    handleUsernamesChange = (event, values) => {
        event.persist();

        let usernames = [];
        values.forEach(function(value) {
            usernames.push(value);
        });

		this.setState({
            // [event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value,
            excludedPlayers: usernames,
		});
	}
    
    handleFilter = () => {
        this.getRebatesReport();
        this.getSystemProfitReport();
        this.getBetsReport();
    }

	handleCloseSnackbar = () => {
		this.setState({
			openSnackbar: false,
		});
	};

	render() {
		return (
			<div className="container">
				<Notification
					open={this.state.openSnackbar}
					onClose={this.handleCloseSnackbar}
					message={this.state.snackbarMessage}
					severity={this.state.snackbarSeverity}
				/>
				<Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ReportFilter
                            handleDateFilterChange={this.handleDateFilterChange}
                            handleFilter={this.handleFilter}
                            users={this.state.users}
                            excludedPlayers={this.state.excludedPlayers}
                            handleUsernamesChange={this.handleUsernamesChange}
                        />
                    </Grid>
					<Grid item xs={12} md={4}>
                        <ReportsGraph
                            data={this.state.rebates}
                            reportName="Rebates"
                            color="#da1c1c"
                        />
					</Grid>
                    <Grid item xs={12} md={4}>
                        <ReportsGraph
                            data={this.state.systemProfit}
                            reportName="System Profit"
                            color="#1231ea"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <ReportsGraph
                            data={this.state.bets}
                            reportName="Bets"
                            color="#28a745"
                        />
                    </Grid>
				</Grid>
			</div>
		);
	}
}

const mapStatetoProps = ({ reports, player, user }) => {
    const { rebatesReport, rebateReportsError, isRebateReportsError, systemProfitReport, systemProfitReportsError, isSystemProfitReportsError, betsReport, betsReportsError, isBetsReportsError } = reports;
    const { players, totalCount, playerError, isPlayerError } = player;
    const { userId, roleId } = user;
    return {
        // reports
        rebatesReport,
        rebateReportsError,
        isRebateReportsError,
        systemProfitReport,
        systemProfitReportsError,
        isSystemProfitReportsError,
        betsReport,
        betsReportsError,
        isBetsReportsError,
        // player
        players,
        totalCount,
        playerError,
        isPlayerError,
        // user
        userId,
        roleId,
    };
};

export default connect(mapStatetoProps, { getRebatesReport, getSystemProfitReport, getBetsReport, getUsersList })(ReportsPage);
