/**
 * User Constants
 */

export const role = {
	ADMIN: 1,
	OPERATOR: 2,
	AGENT: 3,
	SUB_AGENT: 4,
	PLAYER: 5,
	SUPER_AGENT: 6,
	TECHNICAL_SUPPORT: 7,
	SUPER_SUB_AGENT: 8,
	EQUALIZER: 9,
	ROLE: ["", "Admin", "Operator", "Agent", "Sub Agent", "Player", "Master Agent", "Technical Support", "Master-Sub Agent", "Equalizer"],
};
