/**
 * Loading Form Component
 *
 * @author EverardOnggon
 */

import React from "react";
import {
	Button,
	TextField,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	CircularProgress,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";

const useStyles = makeStyles((theme) => ({
	root: {
		"& > *": {
			margin: theme.spacing(0.25),
		},
	},
}));

const ValidationTextField = withStyles({
	root: {
		width: "100%",
		marginBottom: "0.75rem !important",
		"& input:focus:valid + fieldset": {
			borderColor: "green",
			borderWidth: 2,
		},
		"& input:focus:invalid + fieldset": {
			borderColor: "red",
			borderWidth: 2,
		},
		"& input:valid:focus + fieldset": {
			padding: "4px !important",
		},
	},
})(TextField);

const LoadingForm = ({
	transactionTypes,
	name,
	message,
	error,
	handleLoading,
	handleChange,
	onClose,
	isLoading,
}) => {
	const classes = useStyles();

	return (
		<div>
			<h5>Transaction for {name}</h5>
			{error && <Alert severity="error">{message}</Alert>}
			{!error && message && <Alert severity="success">{message}</Alert>}
			<br></br>
			<form onSubmit={handleLoading}>
				<div>
					<FormControl
						style={{ width: "100%", marginBottom: "0.75rem" }}
					>
						<InputLabel
							id="label-type-id"
							style={{ marginLeft: 14 }}
						>
							Transaction Type*
						</InputLabel>
						<Select
							variant="outlined"
							labelId="label-type-id"
							id="transactionTypeId"
							name="transactionTypeId"
							required
							onChange={handleChange}
						>
							{transactionTypes.map((transaction, index) => (
								<MenuItem value={transaction.id} key={index}>
									{transaction.value}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
				<div>
					<CurrencyTextField
						variant="outlined"
						required
						id="loadAmount"
						label="Amount"
						className="iron-form-input-wrap"
						name="loadAmount"
						defaultValue="0"
						onChange={handleChange}
						outputFormat="number"
						decimalCharacter="."
						digitGroupSeparator=","
						currencySymbol=""
						textAlign="left"
						style={{
							width: "100%",
							marginBottom: "0.75rem",
						}}
						inputProps={{ inputMode: 'numeric' }}
					/>
				</div>
				<div>
					<ValidationTextField
						variant="outlined"
						id="loadingRemarks"
						label="Remarks"
						className="iron-form-input-wrap"
						type="text"
						name="loadingRemarks"
						onChange={handleChange}
					/>
				</div>
				<div>
					<ValidationTextField
						variant="outlined"
						required
						id="loadingPassword"
						label="Password"
						className="iron-form-input-wrap"
						type="password"
						name="loadingPassword"
						onChange={handleChange}
					/>
				</div>
				<div className={classes.root}>
					<Button
						type="submit"
						variant="contained"
						color="primary"
						size="medium"
						className="button btn-primary"
						disabled={isLoading}
					>
						{isLoading ? (<CircularProgress color="inherit" size="1.5rem" />) : "Submit"}
					</Button>
					<Button
						variant="contained"
						color="secondary"
						size="medium"
						onClick={onClose(false)}
						className="button btn-base"
					>
						Cancel
					</Button>
				</div>
			</form>
		</div>
	);
};

export { LoadingForm };
