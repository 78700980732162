/**
 * Result History Component
 *
 * @author EverardOnggon
 */

import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
	Paper,
	Grid,
	Toolbar,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
} from "@material-ui/core";
import HistoryIcon from "@material-ui/icons/History";
import { game } from "../../../constants/Game";

const StyledTableCell = withStyles((theme) => ({
	root: {
		border: "none",
	},
	head: {
		backgroundColor: "#000", //"#fb791b",
		color: "#ffffff",
		fontSize: 14,
		fontWeight: 800,
		width: "25%",
		textAlign: "center",
		padding: 5,
	},
	body: {
		fontSize: 16,
		fontWeight: 800,
		textAlign: "center",
		padding: 5,
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		backgroundColor: "#181818",
		"&:nth-of-type(odd)": {
			backgroundColor: "#3d3d3d",
		},
	},
}))(TableRow);

const useStyles = makeStyles((theme) => ({
	toolbar: {
		backgroundColor: "#000",
		color: "#ffffff",
		fontSize: theme.typography.h6.fontSize,
		fontWeight: theme.typography.h6.fontWeight,
		minHeight: "auto",
		padding: 10,
	},
	blackPaperBackground: {
		backgroundColor: "#000",
		color: "#ffffff",
		padding: "20px",
		borderRadius: 3,
	},
	table: {
		width: "100%",
	},
	// tableContainer: {
	// 	height: 200,
	// },
}));

const ResultHistory = ({
	meronCount,
	walaCount,
	drawCount,
	cancelledCount,
	fights,
	tableHeight,
}) => {
	const styles = useStyles();

	return (
		<div>
			<Toolbar className={styles.toolbar}>
				<HistoryIcon />
				&nbsp;HISTORIAL DE RESULTADOS
			</Toolbar>
			<Paper className={styles.blackPaperBackground}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TableContainer>
							<Table className={styles.table}>
								<TableHead>
									<TableRow>
										<StyledTableCell>ROJO</StyledTableCell>
										<StyledTableCell>AZUL</StyledTableCell>
										<StyledTableCell>EMPATE</StyledTableCell>
										<StyledTableCell>
											CANCELADO
										</StyledTableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										<StyledTableCell
											className={styles.tableCellBold}
											style={{
												backgroundColor: "#da1c1c",
												color: "#ffffff",
											}}
										>
											{meronCount}
										</StyledTableCell>
										<StyledTableCell
											className={styles.tableCellBold}
											style={{
												backgroundColor: "#1231ea",
												color: "#ffffff",
											}}
										>
											{walaCount}
										</StyledTableCell>
										<StyledTableCell
											className={styles.tableCellBold}
											style={{
												backgroundColor: "#28a745",
												color: "#ffffff",
											}}
										>
											{drawCount}
										</StyledTableCell>
										<StyledTableCell
											className={styles.tableCellBold}
											style={{
												backgroundColor: "#ffc107",
												color: "#ffffff",
											}}
										>
											{cancelledCount}
										</StyledTableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
					<Grid item xs={12}>
						<TableContainer style={{height: !tableHeight ? 200 : tableHeight}}>
							<Table stickyHeader className={styles.table}>
								<TableHead>
									<TableRow>
										<StyledTableCell>
											PELEA#
										</StyledTableCell>
										<StyledTableCell>
											RESULTADO
										</StyledTableCell>
										<StyledTableCell>
											PAGO
										</StyledTableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{[...fights].reverse().map((row, index) => {
										let result = "";
										let color = "#ffffff";
										let payout = 0;

										if (row.result) {
											if (row.result.winningBetPayout) {
												result = game.FIGHT_RESULT[row.result.winningBetPayout.betOption.id];
												if (row.result.winningBetPayout.betOption.id === game.MERON) {
													color = "#da1c1c";
													payout = row.result.winningBetPayout.totalPayout;
												} else if (row.result.winningBetPayout.betOption.id === game.WALA) {
													color = "#1231ea";
													payout = row.result.winningBetPayout.totalPayout;
												} else if (row.result.winningBetPayout.betOption.id === game.DRAW) {
													color = "#28a745";
													payout = "x8";
												}
											} else if (row.result.cancel) {
												result =
													game.FIGHT_RESULT[
														game.CANCELLED
													];
												color = "#ffc107";
												payout = 0;
											} else if (row.result.draw) {
												result =
													game.FIGHT_RESULT[
														game.DRAW
													];
												color = "#28a745";
												payout = 0;
											}

											if(result == "MERON")
												result = "ROJO";
											else if(result == "WALA")
												result = "AZUL";
											else if(result == "DRAW")
												result = "EMPATE";
											return (
												<StyledTableRow key={index}>
													<StyledTableCell
														style={{
															color: "#ffffff",
														}}
													>
														{row.fightNo}
													</StyledTableCell>
													<StyledTableCell
														style={{
															backgroundColor: `${color}`,
															color: "#ffffff",
														}}
													>
														{result}
													</StyledTableCell>
													<StyledTableCell
														style={{
															color: "#ffffff",
														}}
													>
														{payout}
													</StyledTableCell>
												</StyledTableRow>
											);
										} else {
											return null;
										}
									})}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				</Grid>
			</Paper>
		</div>
	);
};

export { ResultHistory };
