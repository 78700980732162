/**
 * Reports Graph Component
 *
 * @author EverardOnggon
 */

import React from "react";
import { Grid, Paper, } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Line } from 'react-chartjs-2';

const useStyles = makeStyles((theme) => ({
    paperBackground: {
        padding: "20px",
        borderRadius: 0,
    },
}));

const ReportsGraph = ({data, reportName, color}) => {
    const styles = useStyles();

    let labels = [];
    let values = [];
    data.forEach(row => {
        labels.push(row.date);
        values.push(row.value);
    });

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        }
    };
    const graphData ={
        labels: labels,
        datasets: [{
            label: `${reportName} Amount`,
            fill: false,
            backgroundColor: color,
            borderColor: color,
            pointBorderWidth: 1,
            data: values,
        }],
    };

	return (
		<div>
            <Paper className={styles.paperBackground}>
				<Grid
					container
					spacing={1}
					className="d-flex justify-content-center align-items-center"
				>
					<Grid item xs={12}>
						<h4>{reportName}</h4>
					</Grid>
					<Grid item xs={12}>
                        <Line
                            data={graphData}
                            options={options}
                        />
					</Grid>
				</Grid>
            </Paper>
		</div>
	);
};

export { ReportsGraph };
