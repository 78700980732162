/**
 * Notification Component
 * 
 * @author EverardOnggon
 */

import React from 'react'
import { Snackbar } from '@material-ui/core';
import MuiAlert from "@material-ui/lab/Alert";

const Alert = (props) => {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Notification = ({open, onClose, message, severity}) => {
    return (
        <Snackbar
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			open={open}
			autoHideDuration={5000}
			onClose={onClose}
		>
			<Alert severity={severity}>{message}</Alert>
		</Snackbar>
    )
}

export { Notification };