import { combineReducers } from "redux";
import AuthReducers from "./AuthReducers";
import RegisterReducers from "./RegisterReducers";
import UserReducers from "./UserReducers";
import GameEventReducers from "./GameEventReducers";
import PlayerReducers from "./PlayerReducers";
import OtherPlayerReducers from "./OtherPlayerReducers";
import EventReducers from "./EventReducers";
import TransactionReducers from "./TransactionReducers";
import SystemPerformanceReducers from "./SystemPerformanceReducers";
import SystemConfigReducers from "./SystemConfigReducers";
import ReportsReducers from "./ReportsReducers";

export default combineReducers({
	auth: AuthReducers,
	register: RegisterReducers,
	user: UserReducers,
	gameEvent: GameEventReducers,
	player: PlayerReducers,
	otherPlayer: OtherPlayerReducers,
	event: EventReducers,
	transaction: TransactionReducers,
	systemPerformance: SystemPerformanceReducers,
	systemConfig: SystemConfigReducers,
	reports: ReportsReducers,
});
