/**
 * Events List Component
 * 
 * @author EverardOnggon
 */

import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { Paper, Grid, Toolbar, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TableFooter, TablePagination, Button, IconButton } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import HistoryIcon from '@material-ui/icons/History';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

const StyledTableCell = withStyles((theme) => ({
    root: {
        border: "1px solid #000000",
    },
    head: {
        backgroundColor: "#424242",
        color: "#ffffff",
        fontSize: 14,
        fontWeight: 800,
        textAlign: "center",
        padding: 5,
    },
    body: {
        fontSize: 16,
        fontWeight: 800,
        padding: 5,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        backgroundColor: "#ffffff",
        '&:nth-of-type(odd)': {
            backgroundColor: "#f1f1f1",
        }
    },
}))(TableRow);

const StyledTablePagination = withStyles((theme) => ({
    root: {
        color: "#ffffff",
    },
    selectIcon: {
        color: "#ffffff",
    },
    actions: {
        color: "#ffffff",
    },
}))(TablePagination);

const useStyles = makeStyles((theme) => ({
    toolbar: {
        backgroundColor: "#424242",
        color: "#ffffff",
        fontSize: theme.typography.h6.fontSize,
        fontWeight: theme.typography.h6.fontWeight,
        minHeight: "auto",
        padding: 10,
    },
	blackPaperBackground: {
		backgroundColor: "#000000",
		color: "#ffffff",
        padding: "20px",
        borderRadius: 0,
    },
    table: {
        width: "100%",
    },
    tableContainer: {
        height: 200,
    }
}));

const usePaginationStyles = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    iconButton: {
        color: "#FFFFFF",
    },
    disabledButton: {
        opacity: "0.6",
    },
}));

function TablePaginationActions(props) {
    const classes = usePaginationStyles();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;
  
    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                classes={{label: classes.iconButton, disabled: classes.disabledButton}}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                classes={{label: classes.iconButton, disabled: classes.disabledButton}}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                classes={{label: classes.iconButton, disabled: classes.disabledButton}}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                classes={{label: classes.iconButton, disabled: classes.disabledButton}}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
  }

const EventsList = ( { events, totalCount, page, size, handleChangePage, handleChangeRowsPerPage, error, eventsListMessage } ) => {
    const styles = useStyles();

    return (
        <div>
            <Toolbar className={styles.toolbar}>
                <HistoryIcon />&nbsp;Events History
            </Toolbar>
            <Paper className={styles.blackPaperBackground}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {events.length === 0 ?
                        (
                            <Alert severity={error ? "error" : "warning"}>{eventsListMessage}</Alert>
                        ) :
                        (
                            <TableContainer>
                                <Table className={styles.table}>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell style={{width: "80%"}}>Event Details</StyledTableCell>
                                            <StyledTableCell style={{width: "20%"}}>Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {events.map((row, index) => (
                                            <StyledTableRow key={row.id}>
                                                <StyledTableCell>
                                                    {row.title}<br />
                                                    <Moment format="YYYY-MM-DD hh:mm A" style={{fontSize: "0.8rem", fontWeight: "normal"}}>
                                                        {row.createDate}
                                                    </Moment>
                                                </StyledTableCell>
                                                <StyledTableCell style={{textAlign: "center",}}>
                                                    <Link to={`/history/details/${row.id}`}>
                                                        <Button
                                                            variant="contained"
                                                            className="button btn-primary"
                                                        >
                                                            View
                                                        </Button>
                                                    </Link>
                                                    &nbsp;
                                                    <Link to={`/history/results/${row.id}`}>
                                                        <Button
                                                            variant="contained"
                                                            className="button btn-primary"
                                                        >
                                                            Results
                                                        </Button>
                                                    </Link>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <StyledTablePagination
                                                rowsPerPageOptions={[5, 10, 25]}
                                                colSpan={2}
                                                count={totalCount}
                                                rowsPerPage={size}
                                                page={page}
                                                onChangePage={handleChangePage}
                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        )
                        }
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
};

export { EventsList };