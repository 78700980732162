/**
 * Register Page
 *
 * @author EverardOnggon
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { Register } from "../../components";
import { register } from "../../actions/RegisterActions";
import BgImg from "../../assets/images/steel.jpg";

class RegisterPage extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			firstName: "",
			middleName: "",
			lastName: "",
			birthDate: "",
			address: "",
			emailAddress: "",
			mobileNumber: "",
			uploadId: "",
			// agentCode: '',
			username: "",
			password: "",
			confirmPassword: "",
			message: "",
			error: false,
		};
	}

	componentDidMount() {
		this._isMounted = true;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleChange = (event) => {
		const { name, value } = event.target;
		this.setState({
			[name]: value,
		});
	};

	handleFileUpload = (event) => {
		if (this._isMounted) {
			if (event.target.files[0]) {
				this.setState({
					uploadId: event.target.files[0],
				});
			}
		}
	};

	handleRegister = (e) => {
		e.preventDefault();

		const payload = {
			firstName: this.state.firstName,
			middleName: this.state.middleName,
			lastName: this.state.lastName,
			birthDate: this.state.birthDate,
			address: this.state.address,
			emailAddress: this.state.emailAddress,
			mobileNumber: this.state.mobileNumber,
			file: this.state.uploadId,
			agentReferralCode: this.props.match.params.id
				? this.props.match.params.id
				: "",
			username: this.state.username,
			password: this.state.password,
		};

		if (
			payload.firstName.trim() === "" ||
			payload.lastName.trim() === "" ||
			payload.birthDate.trim() === "" ||
			payload.address.trim() === "" ||
			payload.mobileNumber.trim() === "" ||
			payload.username.trim() === "" ||
			payload.password.trim() === "" ||
			this.state.confirmPassword.trim() === ""
		) {
			this.setState({
				message: "Please fill up all required fields",
				error: true,
			});
		} else if (payload.password !== this.state.confirmPassword) {
			// password mismatched error
			this.setState({
				message: "Password mismatched",
				error: true,
			});
		} else if (payload.agentReferralCode.trim() === "") {
			this.setState({
				message:
					"Cannot find the referral code. Make sure you got the correct link from an agent.",
				error: true,
			});
		} else {
			this.props.register(payload).then(() => {
				if (!this.props.isRegisterError) {
					this.setState(
						{
							message: "Registration successful",
							error: false,
						},
						() => {
							setTimeout(() => {
								this.props.history.push("/");
							}, 700);
						}
					);
				} else {
					this.setState({
						message: this.props.registerError
							? this.props.registerError
							: "Server error. Please try again.",
						error: true,
					});
				}
			});
		}
	};

	render() {
		return (
			<div>
				<div className="section-pad bg-steel"
						style={{ backgroundImage: `url(${BgImg})` }}>
					<div className="container">
						<Grid container spacing={0}>
							<Grid
								item
								xs={12}
								sm={12}
								md={10}
								lg={9}
								className="mx-auto"
							>
								<Grid
									container
									spacing={0}
									className="d-flex justify-content-center align-items-center"
								>
									<Grid item xs={12} sm={12} md={6} lg={6}>
										<div className="form-margin bg-base p-sm-25 py-20 px-15 rounded">
											<Register
												uploadId={this.state.uploadId}
												handleRegister={
													this.handleRegister
												}
												handleChange={this.handleChange}
												handleFileUpload={
													this.handleFileUpload
												}
												message={this.state.message}
												error={this.state.error}
												isFromLink={true}
												roleIdToCreate={0}
											/>
										</div>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</div>
				</div>
			</div>
		);
	}
}

const mapStatetoProps = ({ register }) => {
	const { registerError, isRegisterError } = register;

	return {
		registerError,
		isRegisterError,
	};
};

export default connect(mapStatetoProps, { register })(RegisterPage);
