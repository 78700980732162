/**
 * Result History Circles Component
 *
 * @author EverardOnggon
 */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
	Paper,
	Grid,
} from "@material-ui/core";
import ResultHistoryCirclesRow from "./ResultHistoryCirclesRow";

const useStyles = makeStyles((theme) => ({
	blackPaperBackground: {
		backgroundColor: "#000",
		color: "#ffffff",
		borderRadius: 3,
		maxWidth: "calc(100vw - 30px)",
		overflowX: "scroll",
	},
	table: {
		padding: 5,
		height: 180,
		maxWidth: "100%",
	},
}));

const ResultHistoryCircles = ({ fights }) => {
    const styles = useStyles();
    
    let results = [];
    let row = 0;
    for (let i = 0; i < fights.length; i++) {
		results[row] = [];
		let limit = parseInt(fights.length - i) >= 5 ? 5 : fights.length - i;
		if (limit > 0) {
			for (let col = 0; col < limit; col++) {
				results[row][col] = fights[i];
				if (col < 4) {
					i++;
				}
			}
			row++;
		}
    }

    return (
		<Paper className={styles.blackPaperBackground}>
			<Grid container>
				<Grid item xs={12}>
					<table className={styles.table}>
						<tbody>
							<tr>
								{results.map((item, index) => {
									return (
										<ResultHistoryCirclesRow
											fights={item}
											key={index}
										/>
									)
								})}
							</tr>
						</tbody>
					</table>
				</Grid>
			</Grid>
		</Paper>
	);
};

export { ResultHistoryCircles };
