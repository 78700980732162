/**
 * Announcement Component
 *
 * @author EverardOnggon
 */

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import Marquee from "react-smooth-marquee";

const StyledAlert = withStyles((theme) => ({
	root: {
		maxWidth: "90vw",
		width: "100%",
		// margin: "0 auto",
		overflow: "hidden",
		backgroundColor: "#ffc107",
		color: "#000",
		fontSize: theme.typography.h5.fontSize,
		[theme.breakpoints.down("sm")]: {
			fontSize: theme.typography.body1.fontSize,
		},
	},
	message: {
		// width: "100%",
		maxHeight: 50,
		textAlign: "right",
		whiteSpace: "nowrap",
		[theme.breakpoints.down("xs")]: {
			maxHeight: 40,
		},
	},
}))(Alert);

const Announcement = ({ message }) => {
	return (
		<div>
			{message && (
				<StyledAlert icon={false} severity="error">
					<Marquee>{message}</Marquee>
				</StyledAlert>
			)}
		</div>
	);
};

export { Announcement };
