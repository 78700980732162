/**
 * Authentication Reducer
 *
 * @author EverardOnggon
 */

import {
	AUTH_LOGIN_SUCCESS,
	AUTH_LOGIN_FAILED,
	AUTH_LOGIN_INVALID_CREDENTIALS,
	AUTH_LOGOUT_SUCCESS,
	AUTH_LOGOUT_FAILED,
	REFRESH_TOKEN_SUCCESS,
	REFRESH_TOKEN_FAILED,
} from "../actions/types";

const INITIAL_STATE = {
	AccessToken: "",
	TokenExpiration: "",
	RefreshToken: "",
	error: "",
	isError: false,
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case AUTH_LOGIN_SUCCESS:
		case REFRESH_TOKEN_SUCCESS:
			return {
				...state,
				AccessToken: action.payload.access_token,
				TokenExpiration: action.payload.expires_in,
				RefreshToken: action.payload.refresh_token,
				error: "",
				isError: false,
			};
		case AUTH_LOGIN_FAILED:
		case REFRESH_TOKEN_FAILED:
			return {
				...state,
				AccessToken: "",
				TokenExpiration: "",
				RefreshToken: "",
				error: action.error,
				isError: true,
			};
		case AUTH_LOGIN_INVALID_CREDENTIALS:
			return {
				...state,
				AccessToken: "",
				TokenExpiration: "",
				RefreshToken: "",
				error: action.error,
				isError: true,
			};
		case AUTH_LOGOUT_SUCCESS:
		case AUTH_LOGOUT_FAILED:
			return {
				state: undefined,
			};
		default:
			return state;
	}
}
