/**
 * System Profit Page
 *
 * @author EverardOnggon
 */

 import React, { Component } from "react";
 import { connect } from "react-redux";
 import { Grid } from "@material-ui/core";
 import { SystemProfitSummary, SystemProfitsList } from "../../components";
 import { Notification } from "../../components/Common";
 import { getSystemPerformanceByGameId } from "../../actions/SystemPerformanceActions";
 import { gameId } from "../../constants/Game";
 
 class SystemProfitPage extends Component {
	 _isMounted = false;
 
	 constructor(props) {
		 super(props);
 
		 this.state = {
			 systemTotalProfit: 0,
			 systemProfitOddsAmountToCancel: 0,
			 systemTotalFundsForOddsExcemptedBets: 0,
			 systemProfits: [],
			 page: 0,
			 size: 10,
			 totalCount: 0,
			 // Snackbar
			 openSnackbar: false,
			 snackbarMessage: "",
			 snackbarSeverity: "error",
		 };
	 }
 
	 componentDidMount() {
		 this._isMounted = true;
 
		 this.getSystemPerformanceByGameId();
	 }
 
	 componentWillUnmount() {
		 this._isMounted = false;
	 }
 
	 getSystemPerformanceByGameId = () => {
		 this.props.getSystemPerformanceByGameId(gameId.COCK_FIGHT, this.state.page, this.state.size).then(() => {
			 if (!this.props.isSystemProfitError) {
				 this.setState({
					 systemTotalProfit: this.props.systemTotalProfit,
					 systemProfitOddsAmountToCancel: this.props.systemProfitOddsAmountToCancel,
					 systemTotalFundsForOddsExcemptedBets: this.props.systemTotalFundsForOddsExcemptedBets,
					 systemProfits: this.props.systemProfitFights.content,
					 totalCount: this.props.systemProfitFights ? this.props.systemProfitFights.totalElements : 0,
				 });
			 } else {
				 if (this.props.systemProfitError === "401") {
					 this.setState({
						 // Snackbar
						 openSnackbar: true,
						 snackbarMessage: "Session expired. Logging out.",
						 snackbarSeverity: "error",
					 },
					 () => {
						 setTimeout(() => {
							 this.props.handleLogout();
						 }, 1000);
					 });
				 } else {
					 this.setState({
						 systemTotalProfit: 0,
						 systemProfitOddsAmountToCancel: 0,
						 systemTotalFundsForOddsExcemptedBets: 0,
						 systemProfits: [],
						 page: 0,
						 size: 10,
						 totalCount: 0,
					 });
				 }
			 }
		 })
	 }
 
	 handleChangePage = (event, newPage) => {
		 this.setState(
			 {
				 page: newPage,
			 },
			 () => {
				 this.getSystemPerformanceByGameId();
			 }
		 );
	 };
 
	 handleChangeRowsPerPage = (event) => {
		 this.setState(
			 {
				 size: event.target.value ? parseInt(event.target.value) : 10,
				 page: 0,
			 },
			 () => {
				 this.getSystemPerformanceByGameId();
			 }
		 );
	 };
	 
	 openTransactionForm = () => () => {
		 this.props.getOtherPlayerById().then(() => {
			 if (!this.props.isOtherPlayerError) {
				 this.setState({
					 userIdToLoad: null,
					 openLoadingForm: true,
				 });
			 } else {
				 if (this.props.otherPlayerError === "401") {
					 this.setState(
						 {
							 openSnackbar: true,
							 snackbarMessage: "Session expired. Logging out.",
							 snackbarSeverity: "error",
						 },
						 () => {
							 setTimeout(() => {
								 this.props.handleLogout();
							 }, 1000);
						 }
					 );
				 } else {
					 this.setState({
						 openSnackbar: true,
						 snackbarMessage: this.props.otherPlayerError
							 ? this.props.otherPlayerError
							 : "Server error. Please try again.",
						 snackbarSeverity: "error",
					 });
				 }
			 }
		 });
	 };
 
	 render() {
		 return (
			 <div className="container">
				 <Notification
					 open={this.state.openSnackbar}
					 onClose={this.handleCloseSnackbar}
					 message={this.state.snackbarMessage}
					 severity={this.state.snackbarSeverity}
				 />
				 <Grid container spacing={2}>
					 <Grid item xs={12}>
						 <SystemProfitSummary
							 systemTotalProfit={this.state.systemTotalProfit}
							 systemProfitOddsAmountToCancel={this.state.systemProfitOddsAmountToCancel}
							 systemTotalFundsForOddsExcemptedBets={this.state.systemTotalFundsForOddsExcemptedBets}
						 />
					 </Grid>
					 <Grid item xs={12}>
						 <SystemProfitsList
							 systemProfits={this.state.systemProfits}
							 totalCount={this.state.totalCount}
							 page={this.state.page}
							 size={this.state.size}
							 handleChangePage={this.handleChangePage}
							 handleChangeRowsPerPage={this.handleChangeRowsPerPage}
						 />
					 </Grid>
				 </Grid>
			 </div>
		 );
	 }
 }
 
 const mapStatetoProps = ({ systemPerformance }) => {
	 const { systemTotalProfit, systemTotalFundsForOddsExcemptedBets, systemProfitFights, systemProfitOddsAmountToCancel, systemProfitError, isSystemProfitError } = systemPerformance;
 
	 return {
		 systemTotalProfit,
		 systemTotalFundsForOddsExcemptedBets,
		 systemProfitFights,
		 systemProfitOddsAmountToCancel,
		 systemProfitError,
		 isSystemProfitError,
	 };
 };
 
 export default connect(mapStatetoProps, {getSystemPerformanceByGameId})(SystemProfitPage);
 