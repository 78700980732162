/**
 * Other Player Actions
 *
 * @author EverardOnggon
 */

import axios from "axios";
import {
	API_URL,
	GET_PLAYER_SUCCESS,
    GET_PLAYER_FAILED,
    GET_TRANSACTION_TYPES_SUCCESS,
    GET_TRANSACTION_TYPES_FAILED,
    MONEY_TRANSACTION_SUCCESS,
    MONEY_TRANSACTION_FAILED,
    REACTIVATE_USER_SUCCESS,
    REACTIVATE_USER_FAILED,
    DEACTIVATE_USER_SUCCESS,
    DEACTIVATE_USER_FAILED,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILED,
} from "./types";
import { getHeaders } from "./AuthActions";

export const getOtherPlayerById = (id) => async (dispatch) => {
    try {
        const response = await axios.get(`${API_URL}/player/${id}`, await getHeaders());
		if (response.status === 200 || response.status === 201) {
			dispatch({
                type: GET_PLAYER_SUCCESS,
                data: response.data,
			});
		}
    } catch (error) {
		switch (error.response.status) {
            case 401:
				dispatch({
					type: GET_PLAYER_FAILED,
					error: error.response.status.toString(),
				});
                break;
            default:
                dispatch({
                    type: GET_PLAYER_FAILED,
                    error: error.response.data.message,
                });
        }
	}
};

export const getTransactionTypes = () => async (dispatch) => {
    try {
        const response = await axios.get(`${API_URL}/txn/money/types`, await getHeaders());
        if (response.status === 200 || response.status === 201) {
			dispatch({
                type: GET_TRANSACTION_TYPES_SUCCESS,
                data: response.data,
			});
		}
    } catch (error) {
        switch (error.response.status) {
            case 401:
				dispatch({
					type: GET_TRANSACTION_TYPES_FAILED,
					error: error.response.status.toString(),
				});
                break;
            default:
                dispatch({
                    type: GET_TRANSACTION_TYPES_FAILED,
                    error: error.response.data.message,
                });
        }
    }
};

export const moneyTransaction = (payload) => async (dispatch) => {
    try {
        const response = await axios.post(`${API_URL}/txn/money`, payload, await getHeaders());
        if (response.status === 200 || response.status === 201) {
			dispatch({
                type: MONEY_TRANSACTION_SUCCESS,
			});
		}
    } catch (error) {
        switch (error.response.status) {
            case 401:
				dispatch({
					type: MONEY_TRANSACTION_FAILED,
					error: error.response.status.toString(),
				});
                break;
            default:
                dispatch({
                    type: MONEY_TRANSACTION_FAILED,
                    error: error.response.data.message,
                });
        }
    }
};

export const reactivateUser = (id) => async (dispatch) => {
    try {
        const response = await axios.post(`${API_URL}/user/reactivate/${id}`, {}, await getHeaders());
        if (response.status === 200 || response.status === 201) {
            dispatch({
                type: REACTIVATE_USER_SUCCESS,
			});
        }
    } catch (error) {
        switch (error.response.status) {
            case 401:
				dispatch({
					type: REACTIVATE_USER_FAILED,
					error: error.response.status.toString(),
				});
                break;
            default:
                dispatch({
                    type: REACTIVATE_USER_FAILED,
                    error: error.response.data.message,
                });
        }
    }
};

export const deactivateUser = (id) => async (dispatch) => {
    try {
        const response = await axios.post(`${API_URL}/user/deactivate/${id}`, {}, await getHeaders());
        if (response.status === 200 || response.status === 201) {
            dispatch({
                type: DEACTIVATE_USER_SUCCESS,
			});
        }
    } catch (error) {
        switch (error.response.status) {
            case 401:
				dispatch({
					type: DEACTIVATE_USER_FAILED,
					error: error.response.status.toString(),
				});
                break;
            default:
                dispatch({
                    type: DEACTIVATE_USER_FAILED,
                    error: error.response.data.message,
                });
        }
    }
};

export const deleteUser = (id) => async (dispatch) => {
    try {
        const response = await axios.post(`${API_URL}/user/delete/${id}`, {}, await getHeaders());
        if (response.status === 200 || response.status === 201) {
            dispatch({
                type: DELETE_USER_SUCCESS,
			});
        }
    } catch (error) {
        switch (error.response.status) {
            case 401:
				dispatch({
					type: DELETE_USER_FAILED,
					error: error.response.status.toString(),
				});
                break;
            default:
                dispatch({
                    type: DELETE_USER_FAILED,
                    error: error.response.data.message,
                });
        }
    }
};