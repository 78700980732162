/**
 * Transaction Actions
 *
 * @author EverardOnggon
 */

import axios from "axios";
import {
	API_URL,
	GET_TRANSACTIONS_LIST_SUCCESS,
    GET_TRANSACTIONS_LIST_FAILED,
	GET_MONEY_TRANSACTION_TYPES_SUCCESS,
	GET_MONEY_TRANSACTION_TYPES_FAILED,
} from "./types";
import { getHeaders } from "./AuthActions";

export const getTransactionsByUserId = (userId, page, size, transactionTypes, dateFrom, dateTo) => async (dispatch) => {
    try {
		let endpointUrl = `${API_URL}/txn/money/summary/${userId}?page=${page}&size=${size}`;
		if (transactionTypes) {
			endpointUrl = endpointUrl + `&typeIds=${transactionTypes}`;
		}
		if (dateFrom) {
			endpointUrl = endpointUrl + `&dateFrom=${dateFrom}`;
		}
		if (dateTo) {
			endpointUrl = endpointUrl + `&dateTo=${dateTo}`;
		}
        const response = await axios.get(endpointUrl, await getHeaders());
		if (response.status === 200) {
			dispatch({
                type: GET_TRANSACTIONS_LIST_SUCCESS,
                data: response.data,
			});
		}
    } catch (error) {
		switch (error.response.status) {
			case 401:
				dispatch({
					type: GET_TRANSACTIONS_LIST_FAILED,
					error: error.response.status.toString(),
				});
				break;
			default:
				dispatch({
					type: GET_TRANSACTIONS_LIST_FAILED,
					error: error.response.data.message,
				});
		}
	}
};

export const getMoneyTransactionTypes = () => async (dispatch) => {
	try {
		const response = await axios.get(`${API_URL}/txn/money/types/filter`, await getHeaders());
		if (response.status === 200) {
			dispatch({
				type: GET_MONEY_TRANSACTION_TYPES_SUCCESS,
				data: response.data,
			});
		}
	} catch (error) {
		switch (error.response.status) {
			case 401:
				dispatch({
					type: GET_MONEY_TRANSACTION_TYPES_FAILED,
					error: error.response.status.toString(),
				});
				break;
			default:
				dispatch({
					type: GET_MONEY_TRANSACTION_TYPES_FAILED,
					error: error.response.data.message,
				});
		}
	}
};