export const config = {
	API: process.env.REACT_APP_FIGBA_BET_API_BASE,
	API_URL: process.env.REACT_APP_FIGBA_BET_API_URL,
	API_JSON_HEADER: {
		"Content-Type": "application/json; charset=utf-8",
		"Lucky-Online-Platform": "figbaBets",
	},
	API_URL_ENCODED_HEADER: {
		"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
		"Lucky-Online-Platform": "figbaBets",
	},
	API_JSON_FORM_HEADER: {
		"accept": "*/*",
		"Content-Type": "multipart/form-data",
		"Lucky-Online-Platform": "figbaBets",
	},
	POST: { method: "POST" },
	CLIENT_ID: "lucky-online",
	GRANT_TYPE: "password",
	CLIENT_SECRET: "lucky123",
	REFRESH_TOKEN: "refresh_token",
};
