/**
 * Fight Control Component
 *
 * @author EverardOnggon
 */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, Typography, InputBase, Button } from "@material-ui/core";
import { game } from "../../../constants/Game";

const useStyles = makeStyles((theme) => ({
	blackPaperBackground: {
		backgroundColor: "#424242",
		color: "#ffffff",
		padding: "20px",
		[theme.breakpoints.down("xs")]: {
			padding: "20px 5px",
		},
	},
	input: {
		fontSize: theme.typography.h5.fontSize,
		fontWeight: theme.typography.h5.fontWeight,
		padding: 5,
		backgroundColor: "#ffffff",
		width: "100%",
	},
	sideButton: {
		width: "100%",
		height: "100%",
		borderRadius: 0,
	},
	bettingStatusButton: {
		margin: 0,
		width: "25%",
		fontSize: theme.typography.body1.fontSize,
		fontWeight: theme.typography.h6.fontWeight,
		color: "#ffffff",
		borderRadius: 0,
		[theme.breakpoints.down("xs")]: {
			width: "45%",
		},
	},
	fightNumber: {
		fontWeight: "bold",
		color: "#da1c1c",
	},
	disabledButton: {
		opacity: "0.8",
	}
}));

const FightControls = ({
	fightNo,
	fightDetails,
	fightResult,
	isCreateFightDisabled,
	onChange,
	createFight,
	updateFight,
	setBettingStatus,
	setFightResult,
}) => {
	const styles = useStyles();
	let status = "---";
	let isResultButtonDisabled = true;
	let isBettingStatusButtonDisabled = false;
	let isClosedButtonDisabled = true;

	if (fightDetails && fightDetails.status) {
		if (fightDetails.status.id === game.FINALIZED) {
			status = "Closed";
			isResultButtonDisabled = true;
			isBettingStatusButtonDisabled = true;
			isClosedButtonDisabled = true;
		} else {
			status = fightDetails.status.value;

			if (fightDetails.status.id === game.CLOSED) {
				isResultButtonDisabled = false;
			} else {
				isResultButtonDisabled = true;
			}

			if (fightDetails.status.id === game.LAST_CALL) {
				isClosedButtonDisabled = false;
			} else {
				isClosedButtonDisabled = true;
			}
		}
	}

	return (
		<Paper className={styles.blackPaperBackground}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Grid container spacing={0}>
						<Grid item xs={12}>
							<Typography
								variant="h5"
								component="h3"
								className="text-center"
								style={{
									color: "#ffffff",
									marginBottom: 10,
									paddingBottom: 5,
									borderBottom: "1px solid #ffffff",
								}}
							>
								Fight Details
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography
								variant="h5"
								component="h3"
								style={{
									color: "#ffffff",
									marginTop: "5%",
								}}
							>
								Fight #
							</Typography>
						</Grid>
						<Grid item xs={5}>
							<InputBase
								type="number"
								variant="filled"
								value={fightNo ? fightNo : 0}
								className={styles.input}
								inputProps={{
									style: {
										textAlign: "center",
									},
								}}
								onChange={onChange}
							/>
						</Grid>
						<Grid item xs={3}>
							{ isCreateFightDisabled ? (
								<Button
								variant="contained"
								className={`button btn-warning ${styles.sideButton}`}
								classes={{ disabled: styles.disabledButton }}
								onClick={updateFight(true)}
								disabled={!isCreateFightDisabled}
								>
									EDIT
								</Button>
							) : (
								<Button
								variant="contained"
								className={`button btn-primary ${styles.sideButton}`}
								classes={{ disabled: styles.disabledButton }}
								onClick={createFight(true)}
								disabled={isCreateFightDisabled}
								>
									OK
								</Button>
							)}
						</Grid>
					</Grid>
					<Grid container spacing={0}>
						<Grid item xs={12}>
							<Typography
								variant="h5"
								component="h3"
								style={{
									color: "#ffffff",
									marginTop: 15,
									marginBottom: 15,
								}}
							>
								Betting Status:{" "}
								<span
									id="bettingStatus"
									className={styles.fightNumber}
								>
									{status}
								</span>
							</Typography>
						</Grid>
					</Grid>
					<Grid container spacing={0}>
						<Grid
							item
							xs={12}
							style={{
								textAlign: "center",
							}}
						>
							<Button
								variant="contained"
								className={styles.bettingStatusButton}
								style={{
									backgroundColor: "#1231ea",
									fontSize: "0.875rem",
								}}
								classes={{ disabled: styles.disabledButton }}
								onClick={setBettingStatus("CREATING")}
								disabled={isBettingStatusButtonDisabled}
							>
								Stand By
							</Button>
							<Button
								variant="contained"
								className={styles.bettingStatusButton}
								style={{
									backgroundColor: "#28a745",
									fontSize: "0.875rem",
								}}
								onClick={setBettingStatus("OPENING")}
								disabled={isBettingStatusButtonDisabled}
							>
								Open
							</Button>
							<Button
								variant="contained"
								className={styles.bettingStatusButton}
								style={{
									backgroundColor: "#ffc107",
									fontSize: "0.875rem",
								}}
								onClick={setBettingStatus("LASTCALLING")}
								disabled={isBettingStatusButtonDisabled}
							>
								Last Call
							</Button>
							<Button
								variant="contained"
								className={styles.bettingStatusButton}
								style={{
									backgroundColor: "#da1c1c",
									fontSize: "0.875rem",
								}}
								onClick={setBettingStatus("CLOSING")}
								disabled={
									isBettingStatusButtonDisabled ||
									isClosedButtonDisabled
								}
							>
								Close
							</Button>
						</Grid>
					</Grid>
					<Grid container spacing={0}>
						<Grid item xs={12}>
							<Typography
								variant="h5"
								component="h3"
								style={{
									color: "#ffffff",
									marginTop: 15,
									marginBottom: 15,
									paddingTop: 15,
									borderTop: "1px solid #ffffff",
								}}
							>
								Fight Result:{" "}
								<span
									id="fightResult"
									className={styles.fightNumber}
								>
									{fightResult}
								</span>
							</Typography>
						</Grid>
					</Grid>
					<Grid container spacing={0}>
						<Grid
							item
							xs={12}
							style={{
								textAlign: "center",
							}}
						>
							<Button
								variant="contained"
								className={styles.bettingStatusButton}
								style={{
									backgroundColor: "#da1c1c",
								}}
								classes={{ disabled: styles.disabledButton }}
								onClick={setFightResult(game.MERON)}
								disabled={isResultButtonDisabled}
							>
								Red
							</Button>
							<Button
								variant="contained"
								className={styles.bettingStatusButton}
								style={{
									backgroundColor: "#1231ea",
								}}
								classes={{ disabled: styles.disabledButton }}
								onClick={setFightResult(game.WALA)}
								disabled={isResultButtonDisabled}
							>
								Blue
							</Button>
							<Button
								variant="contained"
								className={styles.bettingStatusButton}
								style={{
									backgroundColor: "#28a745",
								}}
								classes={{ disabled: styles.disabledButton }}
								onClick={setFightResult(game.DRAW)}
								disabled={isResultButtonDisabled}
							>
								Draw
							</Button>
							<Button
								variant="contained"
								className={styles.bettingStatusButton}
								style={{
									backgroundColor: "#ffc107",
								}}
								classes={{ disabled: styles.disabledButton }}
								onClick={setFightResult(game.CANCELLED)}
								disabled={isResultButtonDisabled}
							>
								Cancelled
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
};

export { FightControls };
