import React, { Component } from "react";
import { withRouter, Switch, Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import compose from "recompose/compose";
import NumberFormat from "react-number-format";
import {
	withStyles,
	Drawer,
	AppBar,
	Toolbar,
	List,
	IconButton,
	Typography,
	Divider,
	CssBaseline,
	Hidden,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { grey } from "@material-ui/core/colors";
import { CLIENT_ID, GRANT_TYPE, CLIENT_SECRET, REFRESH_TOKEN } from "./actions/types";
import NavLinks from "./constants/NavLinks";
import { NavItemLink } from "./components/NavItemLink";
import { ChangePasswordDialog } from "./components/ChangePassword";
import LoginPage from "./containers/LoginPage";
import DashboardPage from "./containers/DashboardPage";
import OperatorDashboardPage from "./containers/OperatorDashboardPage";
import MyPlayersPage from "./containers/MyPlayersPage";
import EventsHistoryPage from "./containers/EventsHistoryPage";
import TransactionsPage from "./containers/TransactionsPage";
import UserDetailsPage from "./containers/UserDetailsPage";
import EventDetailsPage from "./containers/EventDetailsPage";
import SystemProfitPage from "./containers/SystemProfitPage";
import ResultsPage from "./containers/ResultsPage";
import SupportToolsPage from "./containers/SupportToolsPage";
import ViewUsersPage from "./containers/ViewUsersPage";
import UserTransactionsPage from "./containers/UserTransactionsPage";
import ReportsPage from "./containers/ReportsPage";
import { login, logout, refreshToken } from "./actions/AuthActions";
import { getUserDetails, changePassword } from "./actions/UserActions";
import { getBalanceByPlayerId } from "./actions/PlayerActions";
import { role } from "./constants/User";
import "./App.css";

// Add CSS styles
import "./assets/styles/CssStyles";

// import LogoImg from "./assets/images/PitMasters_logo.jpg";
// import LogoImg from "./assets/images/SabongAsia.jpg";
import LogoImg from "./assets/images/sabungan_logo.png";
import BgImg from "./assets/images/steel.jpg";
// import SubLoginImg from "./assets/images/bakbakan.png";

// Add CSS styles
const useStyles = (theme) => ({
	root: {
		display: "flex",
		// backgroundColor: "#000000",
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		background: "#424242",
	},
	drawer: {
		color: "#FFF",
		background: "#1f1e23",
	},
	toolbar: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		paddingTop: theme.spacing(12),
		paddingBottom: theme.spacing(12),
		[theme.breakpoints.down("sm")]: {
			paddingLeft: 0,
			paddingRight: 0,
		},
	},
	logoImage: {
		width: 200,
		margin: "0 auto",
	},
	menuImage: {
		width: 200,
		margin: "0 auto",
	},
	nav: {
		color: "#000",
		background: "#000"
	},
	availableBalance: {
		position: "absolute",
		right: "20px",
		color: "#ffffff",
		fontSize: theme.typography.h6.fontSize,
		[theme.breakpoints.down("sm")]: {
			fontSize: theme.typography.body1.fontSize,
		}
	},
	drawerName: {
		color: "#FFF",
		marginLeft: 10,
		fontSize: theme.typography.h6.fontSize,
		[theme.breakpoints.down("sm")]: {
			fontSize: theme.typography.body1.fontSize,
		}
	},
});

class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			username: "",
			password: "",
			isAuthenticated: false,
			AccessToken: "",
			TokenExpiration: "",
			RefreshToken: "",
			isMenuOpen: false,
			isLoginLoading: false,
			// user details
			firstName: "",
			totalBalance: 0,
			totalHolds: 0,
			roleId: 0,
			// change password
			isOpenChangePassword: false,
			changePasswordError: false,
			changePasswordMessage: "",
			oldPassword: "",
			newPassword: "",
			confirmtNewPassword: "",
			isChangePasswordLoading: false,
		};
	}

	componentDidMount() {
		if (this.props.AccessToken) {
			this.setState({
				AccessToken: this.props.AccessToken,
				TokenExpiration: this.props.TokenExpiration,
				RefreshToken: this.props.RefreshToken,
			});
			this.getUserDetails();
		} else {
			this.setState({
				isAuthenticated: false,
				AccessToken: "",
				TokenExpiration: "",
				RefreshToken: "",
			});
		}
	}

	toggleDrawer = (isOpen) => () => {
		this.setState({
			isMenuOpen: isOpen,
		});
	};

	onNavItemClick = (isOpen) => {
		this.setState({
			isMenuOpen: isOpen,
		});
	};

	handleTokenRefresh = () => {
		const payload = {
			client_id: CLIENT_ID,
			grant_type: REFRESH_TOKEN,
			client_secret: CLIENT_SECRET,
			refresh_token: this.state.RefreshToken,
		}

		this.props.refreshToken(payload).then(() => {
			if (!this.props.isError && this.props.AccessToken) {
				this.setState({
					AccessToken: this.props.AccessToken,
					TokenExpiration: this.props.TokenExpiration,
					RefreshToken: this.props.RefreshToken,
				});
			} else {
				if (this.state.isAuthenticated) {
					this.setState({
						isAuthenticated: false,
						AccessToken: "",
						TokenExpiration: "",
						RefreshToken: "",
					}, () => {
						setTimeout(() => {
							this.handleLogout();
						}, 500);
					});
				} else {
					this.setState({
						isAuthenticated: false,
						AccessToken: "",
						TokenExpiration: "",
						RefreshToken: "",
					});
				}
			}
		});
	}

	handleLogin = (e) => {
		e.preventDefault();

		const payload = {
			username: this.state.username,
			password: this.state.password,
			client_id: CLIENT_ID,
			grant_type: GRANT_TYPE,
			client_secret: CLIENT_SECRET,
		};

		if (payload.username.trim() === "" || payload.password.trim() === "") {
			this.setState({
				isAuthenticated: false,
			});
		} else {
			this.setState({
				isLoginLoading: true,
			});
			this.props.login(payload).then(() => {
				if (this.props.AccessToken) {
					this.setState(
						{
							isAuthenticated: true,
							AccessToken: this.props.AccessToken,
							TokenExpiration: this.props.TokenExpiration,
							RefreshToken: this.props.RefreshToken,
							isLoginLoading: false,
						},
						() => {
							setTimeout(() => {
								this.props.history.push("/dashboard");
							}, 300);
						}
					);
					this.getUserDetails();
				} else {
					this.setState({
						isAuthenticated: false,
						AccessToken: "",
						TokenExpiration: "",
						RefreshToken: "",
						isLoginLoading: false,
					});
				}
			});
		}
	};

	handleChangePassword = () => {
		this.setState({
			isOpenChangePassword: true,
		});
	};

	toggleChangePasswordDialog = (isOpen) => () => {
		this.setState({
			isOpenChangePassword: isOpen,
		});
	};

	setNewPassword = (e) => {
		e.preventDefault();

		if (
			this.state.newPassword.trim() === "" ||
			this.state.confirmNewPassword.trim() === "" ||
			this.state.oldPassword.trim() === ""
		) {
			this.setState({
				changePasswordError: true,
				changePasswordMessage: "Please fill up all the fields.",
			});
		} else if (this.state.newPassword !== this.state.confirmNewPassword) {
			this.setState({
				changePasswordError: true,
				changePasswordMessage: "New password mismatched.",
			});
		} else {
			this.setState({
				isChangePasswordLoading: true,
			});
			const payload = {
				existingPassword: this.state.oldPassword,
				newPassword: this.state.newPassword,
			};
			this.props.changePassword(payload, this.props.userId).then(() => {
				if (!this.props.isUserError) {
					this.setState(
						{
							changePasswordError: false,
							changePasswordMessage:
								"Password changed successfully.",
						},
						() => {
							setTimeout(() => {
								this.setState({
									isOpenChangePassword: false,
									changePasswordMessage: "",
									isChangePasswordLoading: false,
								});
								this.handleLogout();
							}, 500);
						}
					);
				} else {
					this.setState({
						changePasswordError: true,
						changePasswordMessage: this.props.userError
							? this.props.userError
							: "Server error. Please try again.",
						isChangePasswordLoading: false,
					});
				}
			});
		}
	};

	handleLogout = () => {
		this.props.logout().then(() => {
			this.setState({
				isAuthenticated: false,
				AccessToken: "",
				TokenExpiration: "",
				RefreshToken: "",
				isMenuOpen: false,
				// },
				// () => {
				// 	setTimeout(() => {
				// 		this.props.history.push("/login");
				// 	}, 5000);
			});
		});
	};

	handleChange = (event) => {
		const { name, value } = event.target;
		this.setState({
			[name]: value,
		});
	};

	getUserDetails = () => {
		this.props.getUserDetails().then(() => {
			if (!this.props.isUserError) {
				if (this.props.userId) {
					this.setState({
						firstName: this.props.firstName,
						totalBalance: this.props.totalBalance,
						totalHolds: this.props.totalHolds,
						roleId: this.props.roleId,
						isAuthenticated: true,
					});
				} else {
					this.handleLogout();
				}
			} else {
				this.handleLogout();
			}
		});
	};

	getBalance = () => {
		if (this.props.playerId) {
			this.props.getBalanceByPlayerId(this.props.playerId).then(() => {
				if (!this.props.isPlayerError && this.props.balanceSummary) {
					this.setState({
						totalBalance: this.props.balanceSummary.totalBalance,
						totalHolds: this.props.balanceSummary.totalHolds,
					});
				}
			});
		}
	};

	setTotalHolds = (totalHolds) => {
		this.setState({
			totalHolds: totalHolds
		});
	};

	render() {
		if (!this.state.isAuthenticated) {
			return (
				<LoginPage
					onLogin={this.handleLogin}
					handleChange={this.handleChange}
					error={this.props.error}
					isLoading={this.state.isLoginLoading}
				/>
			);
		} else {
			const { classes } = this.props;
			let availableBalance;
			let dashboardComponent = null;

			if (this.props.roleId === role.OPERATOR) {
				dashboardComponent = (
					<OperatorDashboardPage handleLogout={this.handleLogout} handleTokenRefresh={this.handleTokenRefresh} />
				);
			} else if (
				this.props.roleId === role.ADMIN ||
				this.props.roleId === role.SUPER_AGENT ||
				this.props.roleId === role.SUPER_SUB_AGENT ||
				this.props.roleId === role.AGENT ||
				this.props.roleId === role.SUB_AGENT ||
				this.props.roleId === role.PLAYER ||
				this.props.roleId === role.TECHNICAL_SUPPORT ||
				this.props.roleId === role.EQUALIZER
			) {
				dashboardComponent = (
					<DashboardPage
						refreshUser={this.getUserDetails}
						handleLogout={this.handleLogout}
						getBalance={this.getBalance}
						setTotalHolds={this.setTotalHolds}
						totalBalance={this.state.totalBalance}
					/>
				);
			}

			if (
				this.props.roleId !== role.ADMIN &&
				this.props.roleId !== role.OPERATOR &&
				this.props.roleId !== role.TECHNICAL_SUPPORT
			) {
				availableBalance = (
					<Typography
						noWrap
						className={classes.availableBalance}
					>
						Balance Disponible: $ {" "}
						<NumberFormat
							value={
								this.state.totalBalance - this.state.totalHolds
							}
							displayType={"text"}
							thousandSeparator={true}
							decimalScale={2}
							fixedDecimalScale={true}
						/>
					</Typography>
				);
			}
			return (
				<div className={classes.root}>
					<ChangePasswordDialog
						isOpen={this.state.isOpenChangePassword}
						error={this.state.changePasswordError}
						message={this.state.changePasswordMessage}
						onSubmit={this.setNewPassword}
						onClose={this.toggleChangePasswordDialog}
						handleChange={this.handleChange}
						isLoading={this.state.isChangePasswordLoading}
					/>
					<CssBaseline />
					<AppBar position="fixed" className={classes.appBar}>
						<Toolbar>
							<IconButton
								color="inherit"
								aria-label="open drawer"
								onClick={this.toggleDrawer(true)}
								edge="start"
							>
								<MenuIcon />
							</IconButton>
							<Hidden smDown={true}>
								<span style={{ fontSize: "1rem" }}>
									Welcome {this.state.firstName}
								</span>
							</Hidden>
							{availableBalance}
						</Toolbar>
					</AppBar>
					<Drawer
						variant="temporary"
						classes={{ paper: classes.drawer }}
						open={this.state.isMenuOpen}
					>
						<div className={classes.toolbar}>
							<IconButton
								onClick={this.toggleDrawer(false)}
								style={{ color: grey[50] }}
							>
								<CloseIcon />
							</IconButton>
						</div>
						<Typography className={classes.drawerName}>
							Welcome {this.state.firstName}
						</Typography>
						<br />
						<Divider />
						<List
							style={{
								paddingRight: "25px",
							}}
						>
							{NavLinks &&
								NavLinks.map((navLink, index) =>
									(navLink.title === "My Players" &&
										(this.props.roleId === role.OPERATOR ||
											this.props.roleId === role.PLAYER ||
											this.props.roleId === role.TECHNICAL_SUPPORT || 
											this.props.roleId === role.EQUALIZER )) ||
									(navLink.title === "Transactions" &&
										(this.props.roleId ===
											role.OPERATOR || this.props.roleId === role.TECHNICAL_SUPPORT)) ||
									(navLink.title === "Reports" &&
										this.props.roleId !== role.ADMIN) ||
									(navLink.title === "System Profit" &&
										this.props.roleId !== role.ADMIN) ||
									(navLink.title === "View Users" &&
										(this.props.roleId === role.OPERATOR 
											|| this.props.roleId === role.PLAYER 
											|| this.props.roleId === role.EQUALIZER )) ||
									(navLink.title === "Support Tools" &&
										(this.props.roleId !== role.TECHNICAL_SUPPORT)) ? null : (
										<NavItemLink
											menu={navLink}
											key={index}
											// selected={selectedNavIndex}
											drawerOpen={this.state.isMenuOpen}
											handleChangePassword={
												this.handleChangePassword
											}
											handleLogout={this.handleLogout}
											onNavItemClick={this.onNavItemClick}
											className={`${classes.nav}`}
										/>
									)
								)}
						</List>
						<Divider />
						<img
							src={LogoImg}
							alt="LogoImg"
							className={`mt-25 ${classes.logoImage}`}
						/>
						{/* <img
							src={SubLoginImg}
							alt="SubLoginImg"
							className={`mt-25 ${classes.menuImage}`}
						/> */}
					</Drawer>
					<main
						className={`${classes.content}`}
						style={{ backgroundImage: `url(${BgImg})` }}
					>
						<Switch>
							<Redirect exact from="/login" to="/dashboard" />
							<Route
								exact
								path="/"
								render={() => dashboardComponent}
							/>
							<Route
								path="/dashboard"
								render={() => dashboardComponent}
							/>
							<Route
								exact
								path="/my-players"
								component={() => (
									<MyPlayersPage
										handleLogout={this.handleLogout}
										getBalance={this.getBalance}
									/>
								)}
							/>
							<Route
								exact
								path="/view-users"
								component={() => (
									<ViewUsersPage
										handleLogout={this.handleLogout}
									/>
								)}
							/>
							<Route
								exact
								path="/history"
								component={() => (
									<EventsHistoryPage
										handleLogout={this.handleLogout}
									/>
								)}
							/>
							<Route
								path="/transactions"
								component={() => (
									<TransactionsPage
										handleLogout={this.handleLogout}
									/>
								)}
							/>
							<Route
								path="/reports"
								component={() => (
									<ReportsPage
										handleLogout={this.handleLogout}
									/>
								)}
							/>
							<Route
								path="/system-profit"
								component={() => (
									<SystemProfitPage
										handleLogout={this.handleLogout}
									/>
								)}
							/>
							<Route
								path="/my-players/details"
								component={() => (
									<UserDetailsPage
										handleLogout={this.handleLogout}
									/>
								)}
							/>
							<Route
								path="/view-users/transactions"
								component={() => (
									<UserTransactionsPage
										handleLogout={this.handleLogout}
									/>
								)}
							/>
							<Route
								path="/history/details"
								component={() => (
									<EventDetailsPage
										handleLogout={this.handleLogout}
									/>
								)}
							/>
							<Route
								path="/history/results"
								component={() => (
									<ResultsPage
										handleLogout={this.handleLogout}
									/>
								)}
							/>
							<Route
								path="/support-tools"
								component={() => (
									<SupportToolsPage
										handleLogout={this.handleLogout}
									/>
								)}
							/>
						</Switch>
					</main>
				</div>
			);
		}
	}
}

const mapStatetoProps = ({ auth, user, player }) => {
	const { AccessToken, TokenExpiration, RefreshToken, error, isError } = auth;
	const {
		userId,
		firstName,
		lastName,
		totalBalance,
		totalHolds,
		roleId,
		userError,
		isUserError,
		playerId,
	} = user;
	const { playerError, isPlayerError, balanceSummary } = player;

	return {
		//auth
		AccessToken,
		TokenExpiration,
		RefreshToken,
		error,
		isError,
		//user
		userId,
		firstName,
		lastName,
		totalBalance,
		totalHolds,
		roleId,
		userError,
		isUserError,
		playerId,
		// player
		playerError,
		isPlayerError,
		balanceSummary,
	};
};

export default withRouter(
	compose(
		withStyles(useStyles),
		connect(mapStatetoProps, {
			login,
			logout,
			getUserDetails,
			changePassword,
			getBalanceByPlayerId,
			refreshToken,
		})
	)(App)
);
