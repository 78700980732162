/**
 * System Lock Component
 *
 * @author EverardOnggon
 */

import React from "react";
import { Switch, Grid, Paper, FormControlLabel, FormGroup } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    paperBackground: {
		// backgroundColor: "#000000",
		// color: "#ffffff",
        padding: "20px",
        borderRadius: 0,
    },
}));

const SystemLock = ({systemLockEnabled, handleChange}) => {
    const styles = useStyles();

	return (
		<div>
            <Paper className={styles.paperBackground}>
				<Grid
					container
					spacing={2}
					className="d-flex justify-content-center align-items-center"
				>
					<Grid item xs={12}>
						<h4>System Lock/Unlock</h4>
                        <p>When enabled, the system will be locked from any transactions like betting, deposit, and withdraw.</p>
					</Grid>
					<Grid item xs={12}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch checked={systemLockEnabled} onChange={handleChange} name="systemLockEnable" />
                                }
                                label="System Lock"
                            />
                        </FormGroup>
					</Grid>
				</Grid>
            </Paper>
		</div>
	);
};

export { SystemLock };
