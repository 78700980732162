/**
 * Video Player Component
 *
 * @author EverardOnggon
 */

import React, { Component } from "react";
import { RTCSubscriber } from 'red5pro-html-sdk'

export default class VideoPlayer extends Component {
	constructor (props) {
		super(props)
		var config = {
		  protocol: 'wss',
		  host: 'dashboard.laspeleasdegallos.com',
		  port: 443,
		  app: 'live',
		  streamName: 'HcW4Y2H4f9Iw',
		  signalingSocketOnly: true,
    	  mediaElementId: 'red5pro-subscriber',
		  rtcConfiguration: {
			iceServers: [{urls: 'stun:stun2.l.google.com:19302'}],
			iceCandidatePoolSize: 2,
			bundlePolicy: 'max-bundle'
		  }
		}
		this.state = {
		  configuration: config
		}
	  }

	  async componentDidMount () {
		
		const sleep = (milliseconds) => {
			return new Promise(resolve => setTimeout(resolve, milliseconds))
		}

		let error = true;
		while (error) {
		const {
		  configuration
		} = this.state
			try {
			const subscriber = await new RTCSubscriber()
			await subscriber.init(configuration)
			await subscriber.subscribe()
			} catch (e) {
				await sleep(500);
				continue;
			}
			error = false;
		}
	  }
	
	  render () {
		return (
		  <div>
			<video id="red5pro-subscriber"
			  width="100%" 
			  autoPlay playsInline controls />
		  </div>
		)
	  }
	
}
